define("sirvo-web/components/confirmation-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Component para confirmar ciertas acciones.
  
    ### Usage
  
    ```hbs
    <ConfirmationButton
      @type="danger"
      @descriptionText="Se eliminaran todas las tareas asociadas a este proyecto. ¿Desea eliminar el proyecto?"
      @onConfirm={{action "submit"}}
      @onCancel={{action (noop)}}
    >
      Eliminar proyecto
    </ConfirmationButton>
    ```
  
    Este componente abre un BsModal solicitando confirmación para realizar una acción.
    En el caso que sea confirmada la acción ejecuta @onConfirmation en el caso contrario @onCancel .
  
    @class ConfirmationButton
    @namespace Components
    @extends Ember.Component
    @public
  */
  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      (true && !(Ember.typeOf(this.onConfirm) === 'function') && Ember.assert('@onConfirm must be a action', Ember.typeOf(this.onConfirm) === 'function'));
    }

  });

  _exports.default = _default;
});