define("sirvo-web/templates/inventario/tablas/tipos-movimientos/tipo-documento/cuentas-contables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "osSO9uKh",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"type\",\"size\",\"classNames\",\"onClick\"],[\"success\",\"sm\",\"pull-right\",[26,\"action\",[[21,0,[]],\"addRecord\"],null]]],{\"statements\":[[0,\"\\n      Agregar\\n    \"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"pageSize\",\"load\",\"tableClassNames\"],[[21,0,[\"columns\"]],\"kar-doc-cuenta\",[21,0,[\"modelClass\"]],[21,0,[\"modelFilters\"]],10,[21,0,[\"reload\"]],\"ember-table thead-gray\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/tipos-movimientos/tipo-documento/cuentas-contables.hbs"
    }
  });

  _exports.default = _default;
});