define("sirvo-web/routes/inventario/procesos/cierre-costos", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "moment"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    empParameters: Ember.computed.readOnly('currentCompany.empParameters'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)(true)
      };
    }),

    model() {
      return {};
    },

    setupController(controller
    /*, model*/
    ) {
      let fecha = (0, _moment.default)().endOf('month');

      if (this.empParameters.get('usaCierrePorFecha')) {
        fecha = (0, _moment.default)();

        if (this.fechaCierre && this.fechaCierre >= fecha.toDate()) {
          fecha = (0, _moment.default)(this.fechaCierre).add(1, 'days');
        }
      }

      const cierreCostoModel = new Ember.Object({
        empresa: this.company,
        lastCierreFecha: this.fechaCierre || null,
        fecha,
        esCierre: true,
        esApertura: false,
        motivo: null,
        actualizarCostos: true
      });
      controller.set('changeset', new _emberChangeset.default(cierreCostoModel, (0, _emberChangesetValidations.default)(this.validations), this.validations));
    }

  });

  _exports.default = _default;
});