define("sirvo-web/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBA2l/aP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"container d-flex h-100vh\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row w-100 justify-content-center align-self-center\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 text-center d-flex\"],[8],[0,\"\\n      \"],[6,\"img\"],[10,\"id\",\"image-error\"],[10,\"src\",\"/images/undraw_warning_cyit.svg\"],[10,\"alt\",\"Crash monitor\"],[10,\"class\",\"ml-auto\"],[8],[9],[0,\"\\n      \"],[6,\"span\"],[10,\"class\",\"my-auto text-left mr-auto pl-5\"],[8],[0,\"\\n        Parece que algo no anda bien.\\n        \"],[6,\"br\"],[8],[9],[0,\"\\n        Se ha reportado el incidente.\\n        \"],[6,\"br\"],[8],[9],[0,\"\\n        \"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"\\n          \"],[6,\"strong\"],[8],[0,\"\\n            Volver a inicio\\n          \"],[9],[0,\"\\n        \"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/error.hbs"
    }
  });

  _exports.default = _default;
});