define("sirvo-web/controllers/inventario/tablas/tipos-articulos/record/cuentas-contables/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.tablas.tipos-articulos.record.cuentas-contables'),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
    },

    updateCuentasContables() {
      let periodoId = this.changeset.get('periodo.id');

      if (!periodoId) {
        this.setProperties({
          cuentasContables: Ember.A([]),
          centrosAutorizacion: Ember.A([]),
          centrosCosto: Ember.A([]),
          centrosIntegracion: Ember.A([])
        });
        return;
      }

      this.loader.setLoading(true);
      Ember.RSVP.hash({
        cuentasContables: this.store.query('emp-nomenclatura', {
          filter: {
            empresa: this.company.id,
            periodo: periodoId,
            ultimoNivel: 1
          }
        }),
        centrosAutorizacion: this.store.query('emp-ca', {
          filter: {
            empresa: this.company.id,
            periodo: periodoId,
            ultimoNivel: 1
          }
        }),
        centrosCosto: this.store.query('emp-cc', {
          filter: {
            empresa: this.company.id,
            periodo: periodoId,
            ultimoNivel: 1
          }
        }),
        centrosIntegracion: this.store.query('emp-ci', {
          filter: {
            empresa: this.company.id,
            periodo: periodoId,
            ultimoNivel: 1
          }
        })
      }).then(data => this.setProperties(data)).finally(() => this.loader.setLoading(false));
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      onPeriodoUpdated() {
        if (this.changeset.get('cuenta.id')) {
          this.changeset.set('cuenta', null);
        }

        if (this.changeset.get('centroAutorizacion.id')) {
          this.changeset.set('centroAutorizacion', null);
        }

        if (this.changeset.get('centroCosto.id')) {
          this.changeset.set('centroCosto', null);
        }

        if (this.changeset.get('centroIntegracion.id')) {
          this.changeset.set('centroIntegracion', null);
        }

        this.updateCuentasContables();
      }

    }
  });

  _exports.default = _default;
});