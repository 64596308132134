define("sirvo-web/templates/components/table-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2DoQKETY",
    "block": "{\"symbols\":[\"@columnMeta\",\"@column\"],\"statements\":[[6,\"div\"],[10,\"class\",\"d-flex w-100\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"w-75\"],[8],[0,\"\\n    \"],[1,[21,2,[\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"w-25 h-100 mt-auto d-flex\"],[8],[0,\"\\n\"],[4,\"if\",[[21,1,[\"isSorted\"]]],null,{\"statements\":[[4,\"if\",[[21,1,[\"isSortedAsc\"]]],null,{\"statements\":[[0,\"        \"],[1,[26,\"svg-jar\",[\"arrow-down-short\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[26,\"svg-jar\",[\"arrow-up-short\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[26,\"svg-jar\",[\"arrow-up-down\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[26,\"eq\",[[21,1,[\"isFixed\"]],\"left\"],null]],null,{\"statements\":[[0,\"      \"],[1,[26,\"svg-jar\",[\"lock-fill\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/table-head.hbs"
    }
  });

  _exports.default = _default;
});