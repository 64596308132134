define("sirvo-web/controllers/inventario/tablas/articulos/record/unidades-medida", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ember-changeset", "ember-changeset-validations", "sirvo-web/validations/kar-tabla-medida"], function (_exports, _abstractModuleIndex, _emberChangeset, _emberChangesetValidations, _karTablaMedida) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    errorHandler: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    unidadesMedida: null,
    otrasUnidadesMedida: null,
    unidadMedidaChangeset: null,
    unidadMedidaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-refresh',
        action: this.actions.reloadOtrasUnidadesMedida.bind(this)
      }];
    }),
    isUnidadBase: Ember.computed('changeset.unidadMedidaBase', 'unidadMedidaChangeset.unidadMedida', function () {
      if (!this.changeset.get('unidadMedidaBase.id') || !this.unidadMedidaChangeset.get('unidadMedida.id')) {
        return false;
      }

      return this.changeset.get('unidadMedidaBase.id') === this.unidadMedidaChangeset.get('unidadMedida.id');
    }),
    unidadesMedidaColumns: Ember.computed(function () {
      return [{
        label: 'Unidad de medida',
        valuePath: 'unidadMedida.nombre',
        width: '150px',
        sortable: false
      }, {
        label: 'Compra',
        valuePath: 'compra',
        width: '80px',
        align: 'center',
        sortable: false,
        format: value => Boolean(value),
        cellComponent: 'tables/cell/checkbox-field'
      }, {
        label: 'Venta',
        valuePath: 'venta',
        width: '80px',
        align: 'center',
        sortable: false,
        format: value => Boolean(value),
        cellComponent: 'tables/cell/checkbox-field'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          preDelete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.preDelete.bind(this)
          }
        }
      }];
    }),

    destroyCurrentRecord() {
      if (!this.unidadMedidaChangeset) {
        return;
      }

      this.unidadMedidaChangeset.rollback();
      let articuloUMedida = this.unidadMedidaChangeset.get('_content');

      if (articuloUMedida.get('isNew')) {
        articuloUMedida.destroyRecord();
      }
    },

    createNewChangeset(articuloUMedida = null) {
      // Si se edita una unidad de medida y el actual aún no se ha validado, eliminarlo.
      if (articuloUMedida && !this.unidadMedidaChangeset.get('isValidated')) {
        this.destroyCurrentRecord();
      }

      if (!articuloUMedida) {
        articuloUMedida = this.store.createRecord('kar-tabla-medida', {
          empresa: this.company,
          articulo: this.record,
          isValidated: false
        });
      }

      this.set('unidadMedidaChangeset', new _emberChangeset.default(articuloUMedida, (0, _emberChangesetValidations.default)(_karTablaMedida.default), _karTablaMedida.default));
    },

    actions: {
      searchUnidadesMedida(text) {
        if (!text || text.length < 2) {
          return;
        }

        if (this.unidadesMedidaQuery) {
          Ember.run.cancel(this.unidadesMedidaQuery);
        }

        this.unidadesMedidaQuery = Ember.run.later(() => {
          this.set('unidadesMedida', this.store.query('kar-unidad-medida', {
            filter: {
              empresa: this.company.id,
              codigo: {
                OR: text
              },
              nombre: {
                OR: text
              },
              unidadMedidaBase: {
                exists: false
              }
            }
          }));
        }, 500);
      },

      reloadOtrasUnidadesMedida() {
        return this.set('otrasUnidadesMedida', this.store.query('kar-unidad-medida', {
          filter: {
            empresa: this.company.id,
            unidadMedidaBase: this.changeset.get('unidadMedidaBase.id')
          }
        }));
      },

      unidadMedidaBaseUpdated() {
        if (this.unidadMedidaChangeset.get('unidadMedida.id')) {
          this.unidadMedidaChangeset.set('unidadMedida', null);
        } // Eliminar los registros actuales porque pueden ya no ser equivalencias
        // de la nueva unidad de medida base.
        //
        // TODO: Complicarse la vida aplicando solo un deleteRecord() para no mandar
        // a eliminar al backend de un solo.


        this.record.get('unidadesMedida').filter(uMedida => uMedida.get('id') || uMedida.get('isValidated')).forEach(uMedida => {
          uMedida.destroyRecord().catch(() => {
            this.toast.error('Ocurrió un error al eliminar las unidades de medida anteriores.');
            uMedida.rollbackAttributes();
          });
        });

        if (!this.changeset.get('unidadMedidaBase.id')) {
          this.set('otrasUnidadesMedida', []);
          return;
        } // Crear un registro para la unidad de medida base.


        this.store.createRecord('kar-tabla-medida', {
          empresa: this.company,
          articulo: this.record,
          unidadMedida: this.changeset.get('unidadMedidaBase'),
          isValidated: true
        });
        return this.actions.reloadOtrasUnidadesMedida.apply(this);
      },

      isValid(articuloUMedida) {
        return !articuloUMedida.get('isNew') || articuloUMedida.get('isValidated');
      },

      saveUnidadMedida() {
        this.unidadMedidaChangeset.set('isValidated', false);
        return this.unidadMedidaChangeset.validate().then(() => {
          if (!this.unidadMedidaChangeset.get('isValid')) {
            this.toast.error('El formulario tiene errores.');
            return;
          }

          this.unidadMedidaChangeset.set('isValidated', true);

          if (this.changeset.get('id')) {
            return this.unidadMedidaChangeset.save().then(() => {
              this.toast.success('Unidad de medida guardada correctamente.');
              this.createNewChangeset();
            }).catch(error => this.errorHandler.handleSaveErrors(error, this.unidadMedidaChangeset));
          }

          this.unidadMedidaChangeset.execute();
          this.createNewChangeset();
        });
      },

      edit(articuloUMedida) {
        if (articuloUMedida === this.unidadMedidaChangeset.get('_content')) {
          return;
        }

        if (this.changeset.get('unidadMedidaBase.id') === articuloUMedida.get('unidadMedida.id')) {
          this.toast.error('No puede modificar el registro para la unidad de medida base.');
          return;
        }

        this.createNewChangeset(articuloUMedida);
      },

      preDelete(articuloUMedida) {
        if (articuloUMedida === this.unidadMedidaChangeset.get('_content')) {
          return;
        }

        if (this.changeset.get('unidadMedidaBase.id') === articuloUMedida.get('unidadMedida.id')) {
          this.toast.error('No puede eliminar el registro para la unidad de medida base.');
          return;
        }

        this.actions.delete.call(this, ...arguments);
      }

    }
  });

  _exports.default = _default;
});