define("sirvo-web/controllers/inventario/tablas/bodegas/record/cuentas-contables", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-bodega-cuenta"], function (_exports, _abstractModuleIndex, _karBodegaCuenta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    editRoute: 'inventario.tablas.bodegas.record.cuentas-contables.record',
    modelClass: _karBodegaCuenta.default,
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'bodega'
        },
        value: this.record.get('id')
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Período',
        valuePath: 'periodo.codperiodo',
        width: '110px',
        sortable: false
      }, {
        label: 'Cuenta',
        valuePath: 'cuenta.codcuenta',
        width: '110px',
        sortable: false
      }, {
        label: 'Cuenta',
        valuePath: 'cuenta.nomcta',
        width: '250px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),

    reloadTable() {
      this.set('reload', true);
    },

    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, 'new');
      }

    }
  });

  _exports.default = _default;
});