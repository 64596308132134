define("sirvo-web/controllers/inventario/tablas/unidades-medida/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.tablas.unidades-medida'),
    indexRoute: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    otrasUnidadesDeMedida: Ember.computed('unidadesMedida', 'record', function () {
      return this.unidadesMedida.without(this.record);
    }),

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});