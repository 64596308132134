define("sirvo-web/models/banco", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    codctachq: (0, _model.attr)('string'),
    codtipocta: (0, _model.attr)('string'),
    tipoCuenta: (0, _model.belongsTo)('tipo-cuenta'),
    codmoneda: (0, _model.attr)('string'),
    moneda: (0, _model.belongsTo)('com-moneda'),
    nomctachq: (0, _model.attr)('string'),
    noctachq: (0, _model.attr)('string'),
    // dirctachq: attr('string'),
    // esdefault: attr('number', { defaultValue: 0 }),
    // nonegociable: attr('number', { defaultValue: 0 }),
    // obsctachq: attr('string'),
    // feciniopera: attr('string'),
    // saldoiniconta: attr('number', { defaultValue: 0 }),
    // saldoinibanco: attr('number', { defaultValue: 0 }),
    // feccierreconsi: attr('date'),
    fecinactivo: (0, _model.attr)('date'),
    // fechaBaja: attr('date'),
    // tasac: attr('number', { defaultValue: 0 }),
    // esmultimoneda: attr('number', { defaultValue: 0 }),
    // usaimptodebitob: attr('number', { defaultValue: 0 }),
    // porcimptodebitob: attr('number', { defaultValue: 0 }),
    // valorimptodebitob: attr('number', { defaultValue: 0 }),
    // usanonegociable: attr('number', { defaultValue: 0 }),
    // usachqautorizados: attr('number', { defaultValue: 0 }),
    // usachqentregados: attr('number', { defaultValue: 0 }),
    // usaconci: attr('number', { defaultValue: 0 }),
    // usaconciAutomatica: attr('number', { defaultValue: 0 }),
    // usaconciliacionSat: attr('number', { defaultValue: 0 }),
    // usaliqcaja: attr('number', { defaultValue: 0 }),
    // usasobregiro: attr('number', { defaultValue: 0 }),
    // usaflujodecaja: attr('number', { defaultValue: 0 }),
    // usaControlDeUsuarios: attr('number', { defaultValue: 0 }),
    // usaFlujoFondos: attr('number', { defaultValue: 0 }),
    // usaActivacionChq: attr('number', { defaultValue: 0 }),
    // limiteSobregiro: attr('number', { defaultValue: 0 }),
    // dmRevisadopor: attr('string'),
    // dmRevisadoporPuesto: attr('string'),
    // dmAutorizadopor: attr('string'),
    // dmAutorizadoporPuesto: attr('string'),
    // dmAutorizadopor2: attr('string'),
    // dmAutorizadoporPuesto2: attr('string'),
    // dmVobo1: attr('string'),
    // dmVobo1Puesto: attr('string'),
    // dmVobo2: attr('string'),
    // dmVobo2Puesto: attr('string'),
    // dmVobo3: attr('string'),
    // dmVobo3Puesto: attr('string'),
    // siCiFila: attr('number', { defaultValue: 0 }),
    // siCiColumna: attr('number', { defaultValue: 0 }),
    // ordenarpor: attr('number', { defaultValue: 0 }),
    // usaConciCuadratica: attr('number', { defaultValue: 0 }),
    // usaPe: attr('number', { defaultValue: 0 }),
    // codtipoPe: attr('string'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    codigoNombre: Ember.computed('codtipocta', 'nomctachq', function () {
      return `${this.codtipocta || ''} - ${this.nomctachq || ''}`;
    })
  });

  _exports.default = _default;
});