define("sirvo-web/models/documento-propiedad", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codigoEmpresa: (0, _model.attr)('string'),
    codigoPropiedad: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    archivo: (0, _model.attr)('string'),
    archivoBase64: (0, _model.attr)('string'),
    mimeType: (0, _model.attr)('string'),
    isImage: Ember.computed('mimeType', function () {
      return this.get('mimeType').split('/')[0] === 'image';
    })
  });

  _exports.default = _default;
});