define("sirvo-web/models/emp-moneda", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    codmoneda: (0, _model.attr)('string'),
    esDolar: (0, _model.attr)('number', {
      defaultValue: 0
    }) // tipoOperacion: attr('number', { defaultValue: 1 }),
    // fechaBaja: attr('date'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});