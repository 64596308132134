define("sirvo-web/templates/popups/bs-simple-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "satiLSn4",
    "block": "{\"symbols\":[\"Modal\",\"@title\"],\"statements\":[[4,\"bs-modal\",null,[[\"onSubmit\",\"onHidden\",\"open\"],[[21,0,[\"onSubmit\"]],[21,0,[\"onCancel\"]],true]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],[[\"__ANGLE_ATTRS__\"],[[26,\"hash\",null,[[\"data-test-description\"],[\"\"]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[21,2,[]]],null,{\"statements\":[[0,\"      \"],[1,[21,2,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Estas seguro que desea continuar con la siguiente acción.\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"size\",\"outline\",\"type\",\"__ANGLE_ATTRS__\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"sm\",true,\"secondary\",[26,\"hash\",null,[[\"data-test-button-cancel\"],[\"\"]]]]],{\"statements\":[[0,\"\\n      Cancelar\\n    \"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"size\",\"type\",\"__ANGLE_ATTRS__\"],[[26,\"action\",[[21,0,[]],[21,1,[\"submit\"]]],null],\"sm\",[21,0,[\"type\"]],[26,\"hash\",null,[[\"data-test-button-confirmation\"],[\"\"]]]]],{\"statements\":[[0,\"\\n      Aceptar\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/popups/bs-simple-confirm.hbs"
    }
  });

  _exports.default = _default;
});