define("sirvo-web/models/recibo", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codempresa: (0, _model.attr)('string'),
    codpais: (0, _model.attr)('string'),
    codsistema: (0, _model.attr)('string'),
    codgrupo: (0, _model.attr)('string'),
    // keytra: attr('string'),
    fecmov: (0, _model.attr)('custom-date'),
    yearmes: (0, _model.attr)('string'),
    fecha: (0, _model.attr)('custom-date'),
    codtipo: (0, _model.attr)('string'),
    // codtipodoctos: attr('string'),
    nit: (0, _model.attr)('string'),
    direccion: (0, _model.attr)('string'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    coddirectorio: (0, _model.attr)('string'),
    codclasifica: (0, _model.attr)('string'),
    codmoneda: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    tasac: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    valordocml: (0, _model.attr)('number'),
    valorme: (0, _model.attr)('number'),
    // valor: attr('number', { defaultValue: 0 }),
    // codctachq: attr('string'),
    cuentaRef: (0, _model.attr)('string'),
    // cuentaAux: attr('string'),
    descontable: (0, _model.attr)('string'),
    // docref: attr('string'),
    anulado: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    sucursal: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    actualizadocnt: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    firmaElectronica: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    uuid: (0, _model.attr)('string'),
    faceGenerado: (0, _model.attr)('string'),
    // deha: attr('string'),
    // usactacorriente: attr('number', { defaultValue: 0 }),
    // filectacorriente: attr('string'),
    envioCorreos: (0, _model.attr)('string'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('custom-date'),
    codusr: (0, _model.attr)('string'),
    codConcepto: (0, _model.attr)('string'),
    documentoSerie: (0, _model.attr)('string'),
    documentoNumero: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // Relationship only frontend
    directorio: (0, _model.belongsTo)('gru-directorio'),
    clasificacionDirectorio: (0, _model.belongsTo)('gru-directorio-clasificacion'),
    conceptoFacturable: (0, _model.belongsTo)('emp-concepto'),
    banco: (0, _model.belongsTo)('banco'),
    moneda: (0, _model.belongsTo)('com-moneda'),
    ivaSucursal: (0, _model.belongsTo)('iva-sucursal'),
    documento: (0, _model.belongsTo)('emp-docto'),
    fechaDeposito: (0, _model.attr)('custom-date'),
    archivoBase64: (0, _model.attr)('string'),

    /* Computed properties */
    fechaFormatted: Ember.computed('fecha', function () {
      const momentDate = (0, _moment.default)(this.fecha);

      if (Ember.isNone(this.fecha) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    fecmovFormatted: Ember.computed('fecmov', function () {
      const momentDate = (0, _moment.default)(this.fecmov);

      if (Ember.isNone(this.fecmov) || !momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    })
  });

  _exports.default = _default;
});