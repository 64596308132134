define("sirvo-web/controllers/dashboard/arrendamiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),
    loader: Ember.inject.service(),
    currentYear: null,
    arrendamientosChartData: Ember.computed('model.arrendamientosTotals', function () {
      let totalsFacturado = this.model.arrendamientosTotals.map(totals => totals.totalFacturado || 0);
      let totalsCobrado = this.model.arrendamientosTotals.map(totals => totals.totalCobrado || 0);
      let totalsPendiente = this.model.arrendamientosTotals.map(totals => totals.totalPendiente || 0);
      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [{
          label: 'Facturado',
          backgroundColor: '#c2723c',
          data: totalsFacturado.slice(0, 12)
        }, {
          label: 'Cobrado',
          backgroundColor: '#eabc23',
          data: totalsCobrado.slice(0, 12)
        }, {
          label: 'Pendiente',
          backgroundColor: '#81b55e',
          data: totalsPendiente.slice(0, 12)
        }]
      };
    }),
    arrendamientosChartOptions: Ember.computed(function () {
      return {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Arrendamientos cobrados y pendientes de cobro',
          fontSize: 16
        }
      };
    }),
    localesChartData: Ember.computed('model.localesTotals', function () {
      let totalsLocales = this.model.localesTotals.map(totals => totals.totalLocales || 0);
      let totalsOcupados = this.model.localesTotals.map(totals => totals.totalOcupados || 0);
      let totalsDisponibles = this.model.localesTotals.map(totals => totals.totalDisponibles || 0);
      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [{
          label: 'Locales',
          backgroundColor: '#89bf65',
          data: totalsLocales.slice(0, 12)
        }, {
          label: 'Ocupados',
          backgroundColor: '#76aee1',
          data: totalsOcupados.slice(0, 12)
        }, {
          label: 'Disponibles',
          backgroundColor: '#f9cc25',
          data: totalsDisponibles.slice(0, 12)
        }]
      };
    }),
    localesChartOptions: Ember.computed(function () {
      return {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Disponibilidad de locales',
          fontSize: 16
        }
      };
    }),
    metrosChartData: Ember.computed('model.metrosTotals', function () {
      let totalsMetros = this.model.metrosTotals.map(totals => totals.totalMetros || 0);
      let totalsOcupados = this.model.metrosTotals.map(totals => totals.totalOcupados || 0);
      let totalsDisponibles = this.model.metrosTotals.map(totals => totals.totalDisponibles || 0);
      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [{
          label: 'Metros',
          backgroundColor: '#89bf65',
          data: totalsMetros.slice(0, 12)
        }, {
          label: 'Ocupados',
          backgroundColor: '#76aee1',
          data: totalsOcupados.slice(0, 12)
        }, {
          label: 'Disponibles',
          backgroundColor: '#f9cc25',
          data: totalsDisponibles.slice(0, 12)
        }]
      };
    }),
    metrosChartOptions: Ember.computed(function () {
      return {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Disponibilidad de Metros',
          fontSize: 16
        }
      };
    }),

    changeYear(selectedYear) {
      if (selectedYear === this.currentYear) {
        return;
      }

      this.set('currentYear', selectedYear);
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        arrendamientosData: this.request.get('dashboard/arrendamiento/empresas', {
          anio: this.currentYear
        })
      }).then(({
        arrendamientosData
      }) => {
        this.set('model', this.processor.prepareArrendamientosData(arrendamientosData));
      }).finally(() => this.loader.setNotLoading());
    },

    actions: {
      changeYear() {
        this.send('openModal', 'popups/select-year', {
          title: 'Cambiar año',
          message: null,
          currentYear: this.currentYear,
          onSubmit: this.changeYear.bind(this)
        });
      }

    }
  });

  _exports.default = _default;
});