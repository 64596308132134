define("sirvo-web/routes/dashboard/arrendamiento-comparacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    request: Ember.inject.service(),
    loader: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),

    model() {
      this.loader.setIsLoading();
      let anioActual = new Date().getFullYear();
      return Ember.RSVP.hash({
        arrendamientosDataActual: this.request.get('dashboard/arrendamiento/empresas', {
          anio: anioActual
        }),
        arrendamientosDataAnterior: this.request.get('dashboard/arrendamiento/empresas', {
          anio: anioActual - 1
        })
      }).then(({
        arrendamientosDataActual,
        arrendamientosDataAnterior
      }) => {
        return Ember.RSVP.hash({
          arrendamientosDataActual: this.processor.prepareArrendamientosData(arrendamientosDataActual),
          arrendamientosDataAnterior: this.processor.prepareArrendamientosData(arrendamientosDataAnterior)
        });
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      console.log(model);
      controller.set('currentYear', new Date().getFullYear());
    }

  });

  _exports.default = _default;
});