define("sirvo-web/models/post-oca-orden-despacho-linea", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ordenDespacho: (0, _model.belongsTo)('post-oca-orden-despacho'),
    articulo: (0, _model.belongsTo)('kar-articulo'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    bodegaDestino: (0, _model.belongsTo)('kar-bodega'),
    unidadMedida: (0, _model.belongsTo)('kar-unidad-medida'),
    cantidad: (0, _model.attr)('number'),
    cantidadOrden: (0, _model.attr)('number')
  });

  _exports.default = _default;
});