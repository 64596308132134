define("sirvo-web/controllers/dashboard/locales", ["exports", "sirvo-web/utils/format"], function (_exports, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),
    loader: Ember.inject.service(),
    currentYear: null,
    localesChartData: Ember.computed('model.localesTotals', function () {
      let totalsLocales = this.model.localesTotals.map(totals => totals.totalLocales || 0);
      let totalsOcupados = this.model.localesTotals.map(totals => totals.totalOcupados || 0);
      let totalsDisponibles = this.model.localesTotals.map(totals => totals.totalDisponibles || 0);
      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [{
          label: 'Locales',
          backgroundColor: '#89bf65',
          data: totalsLocales.slice(0, 12)
        }, {
          label: 'Ocupados',
          backgroundColor: '#76aee1',
          data: totalsOcupados.slice(0, 12)
        }, {
          label: 'Disponibles',
          backgroundColor: '#f9cc25',
          data: totalsDisponibles.slice(0, 12)
        }]
      };
    }),
    localesChartOptions: Ember.computed(function () {
      return {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Disponibilidad de locales',
          fontSize: 16
        }
      };
    }),
    pieChartData: Ember.computed('model', function () {
      return {
        datasets: [{
          data: [this.model.localesTotalDisponibles, this.model.localesTotalOcupados],
          backgroundColor: ['#FFC000', '#0070C0']
        }],
        labels: ['Disponibles', 'Ocupados']
      };
    }),
    pieChartOptions: Ember.computed(function () {
      return {
        title: {
          display: true,
          text: 'Locales Disponibles vs Ocupados',
          fontSize: 17,
          fontColor: 'black',
          padding: 14
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, chart) => {
              let data = chart.datasets[0].data;
              let total = data.reduce((total, val) => total + val, 0);
              let percentage = (0, _format.round)(data[tooltipItem.index] / total * 100);
              return `${percentage} %`;
            }
          }
        }
      };
    }),

    changeYear(selectedYear) {
      if (selectedYear === this.currentYear) {
        return;
      }

      this.set('currentYear', selectedYear);
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        localesData: this.request.get('dashboard/arrendamiento/locales', {
          anio: this.currentYear
        })
      }).then(({
        localesData
      }) => {
        this.set('model', this.processor.prepareLocalesData(localesData));
      }).finally(() => this.loader.setNotLoading());
    },

    actions: {
      changeYear() {
        this.send('openModal', 'popups/select-year', {
          title: 'Cambiar año',
          message: null,
          currentYear: this.currentYear,
          onSubmit: this.changeYear.bind(this)
        });
      }

    }
  });

  _exports.default = _default;
});