define("sirvo-web/templates/components/kar/mov-det-ubicacion-horizontal-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K/koPNww",
    "block": "{\"symbols\":[\"ubicacionChangesetInner\",\"f\",\"@ubicacionesChangesets\",\"@ubicacionChangeset\"],\"statements\":[[6,\"div\"],[11,\"class\",[21,0,[\"containerClassNames\"]],null],[8],[0,\"\\n\"],[4,\"extended-form-for\",[[22,[\"ubicacionChangeset\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"component\",[[21,2,[\"ace-text-field\"]],\"articuloUbicacion\"],[[\"label\",\"placeholder\",\"disabled\",\"serializeValue\"],[[22,[\"ubicacionChangeset\",\"tipoUbicacion\",\"nombre\"]],[22,[\"ubicacionChangeset\",\"tipoUbicacion\",\"nombre\"]],true,[26,\"action\",[[21,0,[]],\"serializeArticuloUbicacion\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[9],[0,\"\\n\\n\"],[4,\"if\",[[21,4,[\"tipoUbicacion\",\"tipoUbicacionInferior\"]]],null,{\"statements\":[[4,\"each\",[[21,3,[]]],null,{\"statements\":[[4,\"if\",[[26,\"eq\",[[21,1,[\"tipoUbicacion\",\"id\"]],[21,4,[\"tipoUbicacion\",\"tipoUbicacionInferior\",\"id\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[26,\"kar/mov-det-ubicacion-horizontal-view\",null,[[\"ubicacionChangeset\",\"ubicacionesChangesets\",\"containerClassNames\"],[[21,1,[]],[21,3,[]],[21,0,[\"containerClassNames\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/kar/mov-det-ubicacion-horizontal-view.hbs"
    }
  });

  _exports.default = _default;
});