define("sirvo-web/router", ["exports", "sirvo-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('404', {
      path: '**'
    });
    this.route('login');
    this.route('logout');
    this.route('inventario', function () {
      this.route('movimientos', function () {
        this.route('ingresos', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('poliza');
            this.route('ingreso', function () {
              this.route('proveedor');
              this.route('articulo');
            });
            this.route('detalles', function () {
              this.route('record', {
                path: '/:kar_movdet_id'
              });
            });
            this.route('carga-detalles');
          });
          this.route('ajuste', {
            path: '/ajuste/:id'
          }, function () {
            this.route('movimiento', function () {
              this.route('tipo-doc');
              this.route('proveedor');
              this.route('autorizacion');
            });
            this.route('registro', {
              path: '/registro/:detalle_id'
            }, function () {
              this.route('articulo');
            });
            this.route('detalles');
            this.route('poliza');
          });
          this.route('existencia-inicial', {
            path: '/existencia-inicial/:id'
          }, function () {
            this.route('movimiento', function () {
              this.route('tipo-doc');
              this.route('proveedor');
              this.route('autorizacion');
            });
            this.route('detalles');
            this.route('registro', {
              path: '/registro/:detalle_id'
            }, function () {
              this.route('articulo');
              this.route('bodega');
              this.route('unidad-medida');
            });
          });
          this.route('registro', {
            path: '/registro/:id'
          }, function () {
            this.route('movimiento');
            this.route('detalles');
            this.route('ingreso', {
              path: '/ingreso/:kar_movdet_id'
            });
            this.route('poliza');
          });
        });
        this.route('salidas', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('tipo-doc');
            this.route('proveedor');
            this.route('autorizacion');
          });
          this.route('detalles', {
            path: '/:id/detalles'
          });
          this.route('registro-detalle', {
            path: '/:id/detalles/:detalle_id'
          }, function () {
            this.route('articulo');
            this.route('bodega');
            this.route('unidad-medida');
            this.route('lote');
            this.route('cuentas');
          });
          this.route('traslado', {
            path: '/traslado/:id'
          }, function () {
            this.route('movimiento', function () {
              this.route('tipo-doc');
            });
            this.route('registro', {
              path: '/registro/:detalle_id'
            }, function () {
              this.route('articulo');
              this.route('bodega');
              this.route('unidad-medida');
              this.route('lote');
              this.route('bodega-destino');
            });
            this.route('detalles');
            this.route('poliza');
          });
          this.route('ajuste', {
            path: '/ajuste/:id'
          }, function () {
            this.route('movimiento', function () {
              this.route('tipo-doc');
              this.route('proveedor');
              this.route('autorizacion');
            });
            this.route('detalles');
            this.route('registro', {
              path: '/registro/:detalle_id'
            }, function () {
              this.route('articulo');
              this.route('bodega');
              this.route('lote');
              this.route('unidad-medida');
              this.route('cuentas');
            });
            this.route('poliza');
          });
        });
      });
      this.route('tablas', function () {
        this.route('articulos', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('datos-generales', function () {
              this.route('ubicaciones');
            });
            this.route('configuracion');
            this.route('unidades-medida');
          });
        });
        this.route('tipos-articulos', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('datos-generales');
            this.route('cuentas-contables', function () {
              this.route('record', {
                path: '/:cuenta_id'
              });
            });
          });
        });
        this.route('bodegas', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('datos-generales');
            this.route('configuraciones');
            this.route('dimensiones');
            this.route('cuentas-contables', function () {
              this.route('record', {
                path: '/:cuenta_id'
              });
            });
          });
        });
        this.route('parametros', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('generales');
            this.route('caracteristicas');
            this.route('importaciones');
          });
        });
        this.route('tipos-movimientos', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('generales');
            this.route('tipo-documentos');
          });
          this.route('tipo-documento', {
            path: '/:id/tipo-documento/:id_documento'
          }, function () {
            this.route('datos-generales');
            this.route('cuentas-contables', function () {
              this.route('record', {
                path: '/:cuenta_id'
              });
            });
          });
        });
        this.route('articulos-ubicaciones', function () {
          this.route('record', {
            path: '/:id'
          });
          this.route('registros', {
            path: '/:id/registros'
          }, function () {
            this.route('record', {
              path: '/:registro_id'
            }, function () {
              this.route('bodega');
              this.route('ubicacion');
            });
          });
        });
        this.route('unidades-medida', function () {
          this.route('record', {
            path: '/:id'
          });
        });
        this.route('bodegas-ubicaciones', function () {
          this.route('record', {
            path: '/:id'
          });
        });
      });
      this.route('procesos', function () {
        this.route('cierre-costos');
        this.route('inventario-fisico', function () {
          this.route('articulos', {
            path: '/:id/articulos'
          }, function () {
            this.route('record', {
              path: '/:articulo_id'
            }, function () {
              this.route('articulo');
              this.route('bodega');
            });
            this.route('carga-inventario');
            this.route('carga-ajustes');
          });
          this.route('index', function () {
            this.route('record', {
              path: '/:id'
            });
            this.route('reporte', {
              path: ':id/reporte'
            });
          });
        });
        this.route('generar-polizas-contables');
      });
      this.route('reportes', function () {
        this.route('kardex');
        this.route('existencia');
        this.route('existencia-bodega');
        this.route('movimientos');
      });
    });
    this.route('dashboard', function () {
      this.route('contratos');
      this.route('arrendamiento');
      this.route('parqueos');
      this.route('resultados');
      this.route('locales');
      this.route('metros');
      this.route('propiedades');
    });
    this.route('admon', function () {
      this.route('grupos', function () {
        this.route('contactos');
        this.route('directorios', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('generales');
            this.route('clasificaciones-contactos');
            this.route('informacion-fiscal');
            this.route('es-cliente');
            this.route('es-proveedor');
            this.route('datos-regionales');
          });
        });
        this.route('clasificaciones');
        this.route('directorios-clasificaciones');
      });
      this.route('directorio', function () {
        this.route('contactos');
      });
      this.route('maestros', function () {
        this.route('grupos');
        this.route('empresas');
      });
    });
    this.route('facturacion', function () {
      this.route('movimientos', function () {
        this.route('emision-recibo', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('directorio');
            this.route('concepto-facturable');
            this.route('sucursal');
            this.route('certificacion-impresion');
            this.route('banco');
            this.route('documento');
          });
        });
      });
    });
    this.route('oca', function () {
      this.route('ordenes-compra', function () {
        this.route('procesar', {
          path: '/:id'
        });
      });
      this.route('ordenes-despacho', function () {
        this.route('procesar', {
          path: '/:id'
        });
      });
      this.route('ordenes-traslado', function () {
        this.route('procesar', {
          path: '/:id'
        });
      });
      this.route('ordenes-envio', function () {
        this.route('procesar', {
          path: '/:id'
        });
      });
    });
    this.route('alquileres-renta', function () {
      this.route('maestros', function () {
        this.route('propiedades', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('datos-generales');
            this.route('documentos', function () {
              this.route('agregar-documento');
            });
          });
        });
      });
    });
    this.route('carga-facturas');
    this.route('cambiar-empresa');
  });
  var _default = Router;
  _exports.default = _default;
});