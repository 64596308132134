define("sirvo-web/routes/inventario/tablas/parametros/record/importaciones", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let parentData = this.modelFor(this.parentRoute);
      return this.store.query('emp-periodo', {
        filter: {
          empresa: this.company.id
        }
      }).then(periodos => Ember.merge(parentData, {
        periodos
      }));
    }

  });

  _exports.default = _default;
});