define("sirvo-web/models/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    DEBE: 'D',
    HABER: 'H',
    INGRESOS_TIPOS_MOVIMIENTOS: [{
      key: 'INGRESOS',
      name: 'Ingresos'
    }, {
      key: 'AJUSTE',
      name: 'Ajustes'
    }, {
      key: 'EXISTENCIA_INICIAL',
      name: 'Existencia inicial'
    }],
    SALIDAS_TIPOS_MOVIMIENTOS: [{
      key: 'SALIDAS',
      name: 'Salidas'
    }, {
      key: 'TRASLADOS',
      name: 'Traslados'
    }, {
      key: 'AJUSTE',
      name: 'Ajustes'
    }]
  };
  _exports.default = _default;
});