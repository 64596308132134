define("sirvo-web/helpers/form-for/humanize", ["exports", "ember-form-for/helpers/form-for/humanize"], function (_exports, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _humanize.default;
    }
  });
  Object.defineProperty(_exports, "formForHumanize", {
    enumerable: true,
    get: function () {
      return _humanize.formForHumanize;
    }
  });
});