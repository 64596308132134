define("sirvo-web/models/com-codigo-postal-depto", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codpais: attr('string'),
    coddepto: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    // coddivision: attr('string'),
    // nomdivision: attr('string'),
    // observaciones: attr('string'),
    // fechaBaja: attr('date'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    pais: (0, _model.belongsTo)('com-pais')
  });

  _exports.default = _default;
});