define("sirvo-web/routes/inventario/tablas/articulos-ubicaciones/registros", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    queryParams: {
      ubicacionSuperior: {
        refreshModel: true
      }
    },

    model(params
    /*, transition*/
    ) {
      return Ember.RSVP.hash({
        tipoUbicacion: this.store.findRecord('kar-articulo-tipo-ubicacion', params.id)
      }).then(modelData => modelData.tipoUbicacion.get('tipoUbicacionSuperior').then(() => modelData));
    },

    setupController() {
      this._super(...arguments);

      this.controller.setProperties({
        reload: true,
        pageNumber: 1
      });
    }

  });

  _exports.default = _default;
});