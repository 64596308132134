define("sirvo-web/controllers/inventario/tablas/tipos-movimientos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-tipomov"], function (_exports, _abstractModuleIndex, _karTipomov) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    editRoute: 'inventario.tablas.tipos-movimientos.record',
    modelClass: _karTipomov.default,
    reload: true,
    columns: Ember.computed(function () {
      return [{
        label: 'Tipo',
        valuePath: 'tipomov',
        width: '100px',
        sortable: false
      }, {
        label: 'Descripción',
        valuePath: 'descripcion',
        width: '200px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      }];
    }),

    reloadTable() {
      this.set('reload', true);
    }

  });

  _exports.default = _default;
});