define("sirvo-web/routes/alquileres-renta/maestros/propiedades/record/documentos", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let modelData = this._super(...arguments);

      return Ember.RSVP.hash({
        documentos: this.store.query('documento-propiedad', {
          filter: {
            codigoEmpresa: this.company.get('codempresa'),
            codigoPropiedad: modelData.record.codigoPropiedad
          }
        })
      }).then(routeData => {
        Ember.set(modelData, 'documentos', routeData.documentos);
        return modelData;
      });
    }

  });

  _exports.default = _default;
});