define("sirvo-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w2UH+iai",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[21,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"ember-cli-spinner\",null,[[\"isShow\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[21,0,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"wrapper\"],[8],[0,\"\\n    \"],[1,[20,\"app-sidebar\"],false],[0,\"\\n\\n    \"],[6,\"main\"],[10,\"id\",\"content\"],[11,\"class\",[27,[[26,\"if\",[[21,0,[\"isNavbarCollapsed\"]],\"collapsed\"],null]]]],[8],[0,\"\\n      \"],[1,[20,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[26,\"outlet\",[\"modal\"],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/application.hbs"
    }
  });

  _exports.default = _default;
});