define("sirvo-web/components/tables/cell/color-mark", ["exports", "sirvo-web/templates/components/tables/cell/color-mark"], function (_exports, _colorMark) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _colorMark.default,
    row: null,
    table: null,
    color: null,

    init() {
      this._super(...arguments);

      this.set('color', this.get('column.markColor') || 'gray');
    }

  });

  _exports.default = _default;
});