define("sirvo-web/models/post-oca-orden-envio-linea", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ordenEnvio: (0, _model.belongsTo)('post-oca-orden-envio'),
    ordenCompraId: (0, _model.attr)('number'),
    fecha: (0, _model.attr)('date'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number'),
    proveedor: (0, _model.attr)('string'),
    proveedorNombre: (0, _model.attr)('string'),
    justificacion: (0, _model.attr)('string'),
    cuentaContable: (0, _model.attr)('string'),
    centroCosto: (0, _model.attr)('string'),
    centroIntegracion: (0, _model.attr)('string'),
    centroActividad: (0, _model.attr)('string'),
    articulo: (0, _model.belongsTo)('kar-articulo'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    unidadMedida: (0, _model.belongsTo)('kar-unidad-medida'),
    cantidad: (0, _model.attr)('number'),
    subtotal: (0, _model.attr)('number')
  });

  _exports.default = _default;
});