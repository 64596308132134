define("sirvo-web/models/kar-tipo-articulo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    codbodega: (0, _model.attr)('string'),
    codtipoart: (0, _model.attr)('string'),
    codtipoartPadre: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    cuentaCosto: (0, _model.attr)('string'),
    codca: (0, _model.attr)('string'),
    codcc: (0, _model.attr)('string'),
    codci: (0, _model.attr)('string'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    stausr: (0, _model.attr)('string'),
    creaEstacion: (0, _model.attr)('string'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa'),
    codigoDescripcion: Ember.computed('codtipoart', 'descripcion', function () {
      return `${this.codtipoart} - ${this.descripcion}`;
    })
  });

  _exports.default = _default;
});