define("sirvo-web/components/app-sidebar", ["exports", "sirvo-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    tagName: '',
    isNavbarCollapsed: false,
    sidebarMenus: Ember.computed(function () {
      let menus = Ember.A();

      if (_environment.default.APP.MODULES.GENERAL) {
        menus.pushObject({
          title: 'ADMON. GENERAL',
          children: [{
            title: 'Maestros',
            children: [{
              title: 'Grupos',
              route: 'admon.maestros.grupos'
            }, {
              title: 'Empresas',
              route: 'admon.maestros.empresas'
            }]
          }, {
            title: 'Grupos',
            children: [{
              title: 'Directorios',
              route: 'admon.grupos.directorios'
            }, {
              title: 'Clasificaciones',
              route: 'admon.grupos.clasificaciones'
            }, {
              title: 'Directorios por clasificación',
              route: 'admon.grupos.directorios-clasificaciones'
            }, {
              title: 'Contactos',
              route: 'admon.grupos.contactos'
            }, {
              title: 'Contactos de Directorio',
              route: 'admon.directorio.contactos'
            }]
          }]
        });
      }

      if (_environment.default.APP.MODULES.INVENTARIO) {
        menus.pushObject({
          title: 'INVENTARIO',
          children: [{
            title: 'Movimientos',
            children: [{
              title: 'Ingresos',
              route: 'inventario.movimientos.ingresos'
            }, {
              title: 'Salidas',
              route: 'inventario.movimientos.salidas'
            }]
          }, {
            title: 'Tablas',
            children: [{
              title: 'Configuraciones',
              route: 'inventario.tablas.parametros'
            }, {
              title: 'Tipos de movimientos',
              route: 'inventario.tablas.tipos-movimientos'
            }, {
              title: 'Bodegas ubicaciones',
              route: 'inventario.tablas.bodegas-ubicaciones'
            }, {
              title: 'Bodegas',
              route: 'inventario.tablas.bodegas'
            }, {
              title: 'Artículos',
              route: 'inventario.tablas.articulos'
            }, {
              title: 'Unidades de medida',
              route: 'inventario.tablas.unidades-medida'
            }, {
              title: 'Tipos de articulos',
              route: 'inventario.tablas.tipos-articulos'
            }, {
              title: 'Artículos ubicaciones',
              route: 'inventario.tablas.articulos-ubicaciones'
            }]
          }, {
            title: 'Reportes',
            children: [{
              title: 'Kardex',
              route: 'inventario.reportes.kardex'
            }, {
              title: 'Existencias',
              route: 'inventario.reportes.existencia'
            }, {
              title: 'Existencia por bodega',
              route: 'inventario.reportes.existencia-bodega'
            }, {
              title: 'Movimientos',
              route: 'inventario.reportes.movimientos'
            }]
          }, {
            title: 'Procesos',
            children: [{
              title: 'Cierre de costos',
              route: 'inventario.procesos.cierre-costos'
            }, {
              title: 'Generar pólizas de movimientos',
              route: 'inventario.procesos.generar-polizas-contables'
            }, {
              title: 'Inventario físico',
              route: 'inventario.procesos.inventario-fisico'
            }]
          }]
        });
      }

      if (_environment.default.APP.MODULES.DASHBOARD) {
        menus.pushObject({
          title: 'DASHBOARD',
          route: 'dashboard'
        });
      }

      if (_environment.default.APP.MODULES.ALQUILERES_RENTAS) {
        menus.pushObject({
          title: 'ALQUILERES Y RENTAS',
          children: [{
            title: 'Maestros',
            children: [{
              title: 'Propiedades',
              route: 'alquileres-renta.maestros.propiedades'
            }]
          }]
        });
      }

      if (_environment.default.APP.MODULES.FACTURACION) {
        menus.pushObject({
          title: 'FACTURACION',
          children: [{
            title: 'Movimientos',
            children: [{
              title: 'Emisión recibos',
              route: 'facturacion.movimientos.emision-recibo'
            }]
          }]
        });
      }

      if (_environment.default.APP.MODULES.OCA) {
        menus.pushObject({
          title: 'OCA',
          children: [{
            title: 'Movimientos',
            children: [{
              title: 'Ordenes de compra',
              route: 'oca.ordenes-compra'
            }, {
              title: 'Ordenes de despacho',
              route: 'oca.ordenes-despacho'
            }, {
              title: 'Ordenes de envio',
              route: 'oca.ordenes-envio'
            }, {
              title: 'Ordenes de traslado',
              route: 'oca.ordenes-traslado'
            }]
          }]
        });
      }

      if (_environment.default.APP.MODULES.CARGA_FACTURAS) {
        menus.pushObject({
          title: 'CARGA FACTURAS',
          route: 'carga-facturas'
        });
      }

      return menus;
    }),
    actions: {
      toggleNavbar() {
        this.set('isNavbarCollapsed', !this.isNavbarCollapsed);
      }

    }
  });

  _exports.default = _default;
});