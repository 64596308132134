define("sirvo-web/models/kar-movdet-det", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    movimientoDetalle: (0, _model.belongsTo)('kar-movdet'),
    cantidadBase: (0, _model.attr)('number'),
    // cantidadMedida1: attr('number'),
    // cantidadMedida2: attr('number'),
    // cantidadMedida3: attr('number'),
    // keyfac: attr('string'),
    keyref: (0, _model.attr)('string'),
    // keytraOrden: attr('string'),
    // lineaOrden: attr('number', { defaultValue: 0 }),
    fecha: (0, _model.attr)('date'),
    subtotal: (0, _model.attr)('number'),
    descuento: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    total: (0, _model.attr)('number') // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});