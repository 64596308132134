define("sirvo-web/routes/admon/directorio/contactos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {// return this.store.findAll('gru-directorio-contacto');
    }

  });

  _exports.default = _default;
});