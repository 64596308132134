define("sirvo-web/models/kar-doc-cuenta", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    tipoDocumento: (0, _model.belongsTo)('kar-doc'),
    periodo: (0, _model.belongsTo)('emp-periodo'),
    cuenta: (0, _model.belongsTo)('emp-nomenclatura'),
    centroCosto: (0, _model.belongsTo)('emp-cc'),
    centroAutorizacion: (0, _model.belongsTo)('emp-ca'),
    centroIntegracion: (0, _model.belongsTo)('emp-ci') // codCuentaCredito: attr('string'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    // stausr: attr('string'),

  });

  _exports.default = _default;
});