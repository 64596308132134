define("sirvo-web/utils/date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.daysOfMonth = _exports.monthIndexesFilter = _exports.monthIndexes = _exports.lastYears = _exports.monthName = _exports.monthObjects = _exports.monthNamesByIndex = void 0;
  const monthNamesByIndex = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
  };
  _exports.monthNamesByIndex = monthNamesByIndex;
  const monthObjects = [{
    key: '01',
    name: 'Enero'
  }, {
    key: '02',
    name: 'Febrero'
  }, {
    key: '03',
    name: 'Marzo'
  }, {
    key: '04',
    name: 'Abril'
  }, {
    key: '05',
    name: 'Mayo'
  }, {
    key: '06',
    name: 'Junio'
  }, {
    key: '07',
    name: 'Julio'
  }, {
    key: '08',
    name: 'Agosto'
  }, {
    key: '09',
    name: 'Septiembre'
  }, {
    key: '10',
    name: 'Octubre'
  }, {
    key: '11',
    name: 'Noviembre'
  }, {
    key: '12',
    name: 'Diciembre'
  }];
  _exports.monthObjects = monthObjects;

  const monthName = monthIndex => {
    if (monthIndex < 0 || monthIndex > 11) {
      return '';
    }

    return monthNamesByIndex[monthIndex];
  };

  _exports.monthName = monthName;

  const lastYears = (count = 10) => {
    let nowYear = new Date().getFullYear();
    let years = [nowYear];
    let cont = 0;

    while (cont++ < count) {
      years.push(nowYear - cont);
    }

    return years;
  };

  _exports.lastYears = lastYears;
  const monthIndexes = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  _exports.monthIndexes = monthIndexes;
  const monthIndexesFilter = ['00', ...monthIndexes];
  _exports.monthIndexesFilter = monthIndexesFilter;

  const daysOfMonth = (month, year = null) => {
    if (month < 0 || month > 11) {
      return [];
    }

    let date = (0, _moment.default)({
      year: year || new Date().getFullYear(),
      month,
      day: 1
    }).endOf('month'); // WARNING: Se devuelven como strings para que funcione la búsqueda en el
    // componente "ace-power-select".

    let days = [...Array(date.date())].map((x, i) => String(i + 1));
    return days;
  };

  _exports.daysOfMonth = daysOfMonth;
});