define("sirvo-web/services/kardex", ["exports", "ember-changeset", "ember-changeset/utils/is-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "sirvo-web/utils/format", "sirvo-web/utils/common", "sirvo-web/models/kar-movdet", "sirvo-web/validations/kar-mov-det-ubicacion", "sirvo-web/validations/kar-mov-det-ubicacion-destino"], function (_exports, _emberChangeset, _isChangeset, _emberChangesetValidations, _validators, _format, _common, _karMovdet, _karMovDetUbicacion, _karMovDetUbicacionDestino) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    request: Ember.inject.service(),
    loader: Ember.inject.service(),
    constants: Ember.inject.service(),

    onChangesetArticuloUpdated(changeset, defaultValues = {}) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));

      if (changeset.get('_content') instanceof _karMovdet.default) {
        this.resetMovDetCalculo(changeset);
      }

      Ember.setProperties(changeset, {
        unidadMedida: null,
        lote: null
      });

      if (!changeset.get('articulo.id')) {
        return Ember.RSVP.resolve({
          unidadesMedida: Ember.A([]),
          uMedidaBaseEquivalencias: Ember.A([]),
          localizaciones: Ember.A([])
        });
      }

      let articulo = (0, _common.getPromiseContent)(changeset, 'articulo');
      let uMedidaFilters = {
        articulo: articulo.get('id')
      };

      if (changeset.get('movimiento.deha') === 'D') {
        uMedidaFilters.compra = 1;
      } else if (changeset.get('movimiento.deha') === 'H') {
        uMedidaFilters.venta = 1;
      }

      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        articuloUMedidas: this.store.query('kar-tabla-medida', {
          filter: uMedidaFilters,
          include: 'unidadMedida'
        }),
        uMedidaBaseEquivalencias: this.store.query('kar-unidad-medida', {
          filter: {
            unidadMedidaBase: articulo.belongsTo('unidadMedidaBase').id() || -1
          }
        }),
        localizaciones: this.queryArticuloLocalizaciones(articulo)
      }).then(data => {
        let unidadesMedidaAsignadas = data.articuloUMedidas.mapBy('unidadMedida');
        let uMedidaBaseEquivalencias = data.uMedidaBaseEquivalencias.toArray();
        let unidadMedida = null;

        if (articulo.get('unidadMedidaBase.id')) {
          uMedidaBaseEquivalencias.unshiftObject(articulo.get('unidadMedidaBase'));
        } // Intentar setear una unidad de medida a partir de un código (OCA).


        if (defaultValues.codigoUnidadMedida) {
          // Mergear las unidades asignadas al artículo y las equivalencias de la unidad base.
          let allUnidadesMedida = [...unidadesMedidaAsignadas.toArray(), ...uMedidaBaseEquivalencias];
          unidadMedida = allUnidadesMedida.findBy('codigo', defaultValues.codigoUnidadMedida);
        } // Si no se encontró la unidad de medida en el paso anterior, intentar obtener
        // la unidad base del listado de unidades asignadas al artículo.


        if (!unidadMedida) {
          let uMedidaBaseId = articulo.get('unidadMedidaBase.id');

          if (uMedidaBaseId) {
            unidadMedida = unidadesMedidaAsignadas.findBy('id', uMedidaBaseId);
          }
        } // Si la unidad base no está asignada al artículo (no debería pasar), asignar
        // directamente la unidad base del artículo.


        if (!unidadMedida) {
          unidadMedida = articulo.get('unidadMedidaBase');
        }

        changeset.set('unidadMedida', unidadMedida);
        return {
          unidadesMedida: unidadesMedidaAsignadas,
          uMedidaBaseEquivalencias,
          localizaciones: data.localizaciones
        };
      }).finally(() => this.loader.setLoading(false));
    },

    resetMovDetCalculo(changeset) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      Ember.setProperties(changeset, {
        precioUnitario: 0,
        subtotal: 0,
        valorNeto: 0,
        total: 0,
        valorNetoMe: 0,
        cantidadBase: 0
      });
    },

    updateMovDetCalculo(changeset, articuloSaldo, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      let uMedidaFactor = changeset.get('unidadMedida.factor') || 1;
      let precioUnitario = articuloSaldo['valor_promedio']; // En los movimientos de AJUSTE, el usuario es quien ingresa el precio unitario
      // o se calcula a partir del total ingresado.

      if (changeset.get('movimiento').get('tipoMovimiento').get('tipomov') === 'AJUSTE') {
        precioUnitario = changeset.get('precioUnitario');
      }

      let totalNeto = (0, _format.round)(changeset.get('cantidad') * precioUnitario || 0);
      let tasaCambio = parseFloat(changeset.get('tasac')) || 0;
      let cantidadBaseFactor = changeset.get('movimiento.deha') === 'D' ? 1 : -1;
      Ember.setProperties(changeset, {
        precioUnitario,
        subtotal: totalNeto,
        valorNeto: totalNeto,
        total: totalNeto,
        valorNetoMe: tasaCambio ? (0, _format.round)(totalNeto / tasaCambio) : 0,
        // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
        cantidadBase: changeset.get('cantidad') * uMedidaFactor * cantidadBaseFactor
      });

      if (indexCtrl) {
        indexCtrl.setProperties({
          existenciaBase: articuloSaldo['existencia'],
          existencia: articuloSaldo['existencia'] / uMedidaFactor
        });
      }

      return Ember.RSVP.resolve(true);
    },

    onChangesetBodegaUpdated(changeset, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset))); // Solo los movimientos del HABER requieren validar la existencia.

      if (changeset.get('movimiento.deha') !== this.constants.HABER) {
        return Ember.RSVP.resolve(true);
      }

      this.resetMovDetCalculo(changeset);

      if (!changeset.get('articulo.id') || !changeset.get('bodega.id')) {
        return Ember.RSVP.resolve(true);
      }

      this.loader.setLoading(true);
      return this.request.get(`kar-articulos/${changeset.get('articulo.id')}/saldo`, {
        bodegaId: changeset.get('bodega.id')
      }).then(articuloSaldo => this.updateMovDetCalculo(changeset, articuloSaldo, indexCtrl)).finally(() => this.loader.setLoading(false));
    },

    onArticuloUbicacionUpdated(changeset, ubicacionChangeset, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      (true && !((0, _isChangeset.default)(ubicacionChangeset)) && Ember.assert('Argument 2 must be an instance of Changeset', (0, _isChangeset.default)(ubicacionChangeset))); // Solo los movimientos del HABER requieren validar la existencia.

      if (changeset.get('movimiento.deha') !== this.constants.HABER) {
        return Ember.RSVP.resolve(true);
      }

      this.resetMovDetCalculo(changeset);

      if (!changeset.get('articulo.id') || !ubicacionChangeset.get('articuloUbicacion.id')) {
        return Ember.RSVP.resolve(true);
      }

      this.loader.setLoading(true);
      return this.request.get(`kar-articulos/${changeset.get('articulo.id')}/saldo`, {
        ubicacionId: ubicacionChangeset.get('articuloUbicacion.id')
      }).then(articuloSaldo => this.updateMovDetCalculo(changeset, articuloSaldo, indexCtrl)).finally(() => this.loader.setLoading(false));
    },

    onChangesetUnidadMedidaUpdated(changeset, indexCtrl) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      let uMedidaFactor = changeset.get('unidadMedida.factor') || 1;
      let cantidadBaseFactor = changeset.get('movimiento.deha') === 'D' ? 1 : -1; // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.

      changeset.set('cantidadBase', changeset.get('cantidad') * uMedidaFactor * cantidadBaseFactor);

      if (indexCtrl) {
        indexCtrl.set('existencia', indexCtrl.existenciaBase / uMedidaFactor);
      }
    },

    onChangesetCantidadUpdated(changeset) {
      (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Argument 1 must be an instance of Changeset', (0, _isChangeset.default)(changeset)));
      let totalNeto = (0, _format.round)(changeset.get('cantidad') * changeset.get('precioUnitario') || 0);
      let tasaCambio = parseFloat(changeset.get('tasac')) || 0;
      let uMedidaFactor = changeset.get('unidadMedida.factor') || 1;
      let cantidadBaseFactor = changeset.get('movimiento.deha') === 'D' ? 1 : -1;

      if (changeset.get('movimiento').get('tipoMovimiento').get('tipomov') === 'AJUSTE') {
        if (!changeset.get('cantidad')) {
          totalNeto = changeset.get('precioUnitario');
        }
      }

      Ember.setProperties(changeset, {
        subtotal: totalNeto,
        valorNeto: totalNeto,
        total: totalNeto,
        valorNetoMe: tasaCambio ? (0, _format.round)(totalNeto / tasaCambio) : 0,
        // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
        cantidadBase: changeset.get('cantidad') * uMedidaFactor * cantidadBaseFactor
      });
    },

    createMovDetUbicacionChangeset(movDetUbicacion) {
      return new _emberChangeset.default(movDetUbicacion, (0, _emberChangesetValidations.default)(_karMovDetUbicacion.default), _karMovDetUbicacion.default);
    },

    createMovDetUbicacionDestinoChangeset(movDetUbicacion) {
      return new _emberChangeset.default(movDetUbicacion, (0, _emberChangesetValidations.default)(_karMovDetUbicacionDestino.default), _karMovDetUbicacionDestino.default);
    },

    createMovDetUbicacionesChangesets(tiposUbicaciones, movDetalle) {
      return tiposUbicaciones.map(tipoUbicacion => {
        let movDetUbicacion = movDetalle.get('ubicaciones').findBy('tipoUbicacion.id', tipoUbicacion.id);

        if (!movDetUbicacion) {
          movDetUbicacion = this.store.createRecord('kar-mov-det-ubicacion', {
            movimientoDetalle: movDetalle,
            tipoUbicacion
          });
        }

        return this.createMovDetUbicacionChangeset(movDetUbicacion);
      });
    },

    createInventarioFisicoUbicacionChangeset(inventarioFisicoUbicacion) {
      let validations = {
        inventarioFisico: (0, _validators.validatePresence)(true),
        tipoUbicacion: (0, _validators.validatePresence)(true)
      };
      return new _emberChangeset.default(inventarioFisicoUbicacion, (0, _emberChangesetValidations.default)(validations), validations);
    },

    createInventarioFisicoUbicacionesChangesets(tiposUbicaciones, inventarioFisico) {
      return tiposUbicaciones.map(tipoUbicacion => {
        let inventarioFisicoUbicacion = inventarioFisico.get('ubicaciones').findBy('tipoUbicacion.id', tipoUbicacion.id);

        if (!inventarioFisicoUbicacion) {
          inventarioFisicoUbicacion = this.store.createRecord('kar-inventario-fisico-ubicacion', {
            inventarioFisico,
            tipoUbicacion
          });
        }

        return this.createInventarioFisicoUbicacionChangeset(inventarioFisicoUbicacion);
      });
    },

    updateBodegaUbicaciones(bodega, ubicacionesMeta) {
      // Resetear las opciones de cada tipo de ubicación.
      Object.values(ubicacionesMeta).forEach(ubicacionMeta => {
        Ember.set(ubicacionMeta, 'ubicaciones', Ember.A([]));
      });

      if (!bodega || !bodega.get('id')) {
        return Ember.RSVP.resolve(true);
      }

      return Ember.RSVP.hash({
        bodegaUbicaciones: this.store.query('kar-articulo-ubicacion', {
          filter: {
            bodega: bodega.get('id')
          }
        })
      }).then(({
        bodegaUbicaciones
      }) => {
        bodegaUbicaciones.forEach(bodegaUbicacion => {
          ubicacionesMeta[bodegaUbicacion.get('tipoUbicacion.id')].ubicaciones.pushObject(bodegaUbicacion);
        });
        return Ember.RSVP.resolve({
          bodegaUbicaciones
        });
      });
    },

    updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, ubicacionesMeta) {
      // Resetear las opciones de los tipos de ubicación que dependen del tipo
      // actualizado (sub clasificaciones, en principio debería ser solo uno).
      Object.values(ubicacionesMeta).forEach(ubicacionMeta => {
        if (ubicacionMeta.tipoUbicacion.get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
          Ember.set(ubicacionMeta, 'ubicaciones', Ember.A([]));
        }
      });

      if (!tipoUbicacion.get('tipoUbicacionInferior.id') || !articuloUbicacion || !articuloUbicacion.get('id')) {
        return Ember.RSVP.resolve(true);
      }

      return Ember.RSVP.hash({
        ubicacionSubdivisiones: this.store.query('kar-articulo-ubicacion', {
          filter: {
            ubicacionSuperior: articuloUbicacion.get('id')
          }
        })
      }).then(({
        ubicacionSubdivisiones
      }) => {
        ubicacionSubdivisiones.forEach(articuloUbicacion => {
          ubicacionesMeta[articuloUbicacion.get('tipoUbicacion.id')].ubicaciones.pushObject(articuloUbicacion);
        });
        return Ember.RSVP.resolve({
          ubicacionSubdivisiones
        });
      });
    },

    _findUbicacionLastChildFromNode(ubicaciones, ubicacionInicio) {
      let ubicacionHija = ubicaciones.findBy('tipoUbicacion.id', ubicacionInicio.get('tipoUbicacion').get('tipoUbicacionInferior.id'));

      if (!ubicacionHija) {
        return ubicacionInicio;
      }

      return this._findUbicacionLastChildFromNode(ubicaciones, ubicacionHija);
    },

    findUbicacionUltimoNivel(ubicaciones) {
      let ubicacionPrincipal = ubicaciones.findBy('tipoUbicacion.esPrincipal', true);

      if (!ubicacionPrincipal) {
        return null;
      }

      return this._findUbicacionLastChildFromNode(ubicaciones, ubicacionPrincipal);
    },

    _destroyUbicacionesParentsRecursively(ubicaciones, ubicacionInicio) {
      let ubicacionPapa = ubicaciones.findBy('tipoUbicacion.id', ubicacionInicio.get('tipoUbicacion').get('tipoUbicacionSuperior.id'));
      ubicacionInicio.rollbackAttributes();

      if (ubicacionPapa) {
        this._destroyUbicacionesParentsRecursively(ubicaciones, ubicacionPapa);
      }
    },

    /**
     * Cuando se cancela la creación de un registro que tiene ubicaciones (kar-movdet),
     * es necesario eliminar los registros de ubicaciones dinámicas. Si se recorre el
     * array y se aplica el rollbackAttributes() sobre cada registro, por alguna razón
     * el forEach obtiene elementos indefinidos. La solución a este problema es eliminar
     * los registros desde los hijos hasta la ubicación principal.
     */
    destroyUnsavedUbicaciones(ubicaciones) {
      let ubicacionUltimoNivel = this.findUbicacionUltimoNivel(ubicaciones);

      if (ubicacionUltimoNivel) {
        this._destroyUbicacionesParentsRecursively(ubicaciones, ubicacionUltimoNivel);
      }
    },

    findLastTipoUbicacion(tiposUbicaciones) {
      let tipoUbicacionRoot = tiposUbicaciones.findBy('esPrincipal');

      if (!tipoUbicacionRoot) {
        return null;
      }

      let lastTipoUbicacion = tipoUbicacionRoot;

      while (lastTipoUbicacion.get('tipoUbicacionInferior.id')) {
        lastTipoUbicacion = lastTipoUbicacion.get('tipoUbicacionInferior');
      }

      return lastTipoUbicacion;
    },

    queryMovDetUbicaciones(movDetalle) {
      if (!movDetalle || !movDetalle.id) {
        return Ember.RSVP.resolve(Ember.A([]));
      }

      return this.store.query('kar-mov-det-ubicacion', {
        filter: {
          movimientoDetalle: movDetalle.id
        },
        include: 'articuloUbicacion'
      });
    },

    queryArticuloLocalizaciones(articulo) {
      if (!articulo || !articulo.get('id')) {
        return Ember.RSVP.resolve(Ember.A([]));
      }

      return this.store.query('kar-articulo-localizacion', {
        filter: {
          articulo: articulo.get('id')
        },
        sort: 'id',
        include: 'articuloUbicacion'
      });
    },

    groupArticuloUbicacionesByBodega(localizaciones) {
      let ubicacionesByBodega = localizaciones.filterBy('bodega.id').map(articuloLocalizacion => ({
        ubicacionBodega: articuloLocalizacion,
        ubicaciones: Ember.A([])
      }));
      let articuloUbicaciones = localizaciones.filterBy('articuloUbicacion.id');
      ubicacionesByBodega.forEach(articuloLocalizacion => {
        let nextLocalizacion = articuloUbicaciones.findBy('articuloUbicacion.bodega.id', articuloLocalizacion.ubicacionBodega.get('bodega.id'));

        while (nextLocalizacion) {
          articuloLocalizacion.ubicaciones.pushObject(nextLocalizacion);
          nextLocalizacion = articuloUbicaciones.findBy('articuloUbicacion.ubicacionSuperior.id', nextLocalizacion.get('articuloUbicacion.id'));
        }
      });
      return ubicacionesByBodega;
    }

  });

  _exports.default = _default;
});