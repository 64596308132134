define("sirvo-web/routes/facturacion/movimientos/emision-recibo/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "servir-ember-utilities/validators/dependent-presence"], function (_exports, _abstractModuleRecord, _validators, _dependentPresence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'recibo',
    indexRoute: 'facturacion.movimientos.emision-recibo',
    validations: Ember.computed(function () {
      return {
        fecha: (0, _validators.validatePresence)(true),
        codtipo: (0, _validators.validatePresence)(true),
        coddirectorio: (0, _validators.validatePresence)(true),
        codclasifica: (0, _validators.validatePresence)(true),
        sucursal: (0, _validators.validateNumber)({
          gt: 0
        }),
        nombre: (0, _validators.validatePresence)(true),
        nit: (0, _validators.validatePresence)(true),
        direccion: (0, _validators.validatePresence)(true),
        descontable: (0, _validators.validatePresence)(true),
        codConcepto: (0, _validators.validatePresence)(true),
        conceptoFacturable: (0, _validators.validatePresence)(true),
        tasac: (0, _validators.validateNumber)({
          gt: 0
        })
      };
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        if (!modelData.record.isNew) {
          let promises = {
            codConcepto: this.store.query('emp-concepto', {
              filter: {
                codconcepto: modelData.record.codConcepto
              }
            }),
            directorio: this.store.query('gru-directorio', {
              filter: {
                coddirectorio: modelData.record.coddirectorio
              }
            }),
            clasificaciones: this.store.query('gru-directorio-clasificacion', {
              filter: {
                coddirectorio: modelData.record.coddirectorio,
                grupo: this.company.get('grupo.id')
              }
            })
          };
          return Ember.RSVP.hash(promises).then(promises => {
            return Ember.merge(modelData, {
              codConcepto: promises.codConcepto.get('firstObject'),
              directorio: promises.directorio.get('firstObject'),
              clasificaciones: promises.clasificaciones
            });
          });
        }

        return modelData;
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('firmadoElectronicamente', false);
      this.validations['valordocml'] = (0, _dependentPresence.default)({
        changeset: controller.changeset,
        dependsOn: 'valorme'
      });
      this.validations['valorme'] = (0, _dependentPresence.default)({
        changeset: controller.changeset,
        dependsOn: 'valordocml'
      });

      if (controller.get('isNew')) {
        this.validations['banco'] = (0, _validators.validatePresence)(true);
        this.validations['documento'] = (0, _validators.validatePresence)(true);
        this.validations['documentoNumero'] = (0, _validators.validateNumber)({
          gt: 0
        });
      } else {
        delete this.validations['banco'];
        delete this.validations['documento'];
        delete this.validations['documentoNumero'];
      }

      if (!model.record.isNew) {
        Ember.setProperties(model.record, {
          conceptoFacturable: model.codConcepto,
          directorio: model.directorio,
          clasificaciones: model.clasificaciones
        });

        if (model.record.firmaElectronica === 1) {
          controller.set('firmadoElectronicamente', true);
        }
      }
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        codempresa: this.company.get('codempresa'),
        fecha: new Date(),
        fechaDeposito: new Date(),
        codtipo: 'RDON',
        codsistema: 'FAC',
        codmoneda: 'GTQ'
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});