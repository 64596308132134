define("sirvo-web/templates/inventario/movimientos/ingresos/ajuste/movimiento/tipo-doc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YpuRHADZ",
    "block": "{\"symbols\":[\"modal\",\"f\",\"tipoDocumento\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHide\"],[[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[4,\"component\",[[21,2,[\"ace-power-select\"]],\"tipoDocumento\",\"Tipo de documento\"],[[\"selected\",\"options\",\"renderInPlace\",\"placeholder\",\"searchField\",\"onchange\"],[[21,0,[\"changeset\",\"tipoDocumento\"]],[21,0,[\"documentosAjuste\"]],true,\"Seleccionar...\",\"codtipo\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"tipoDocumento\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,3,[\"codtipo\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"Cerrar\"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/ingresos/ajuste/movimiento/tipo-doc.hbs"
    }
  });

  _exports.default = _default;
});