define("sirvo-web/templates/dashboard/arrendamiento-comparacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nEaoWOyC",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 py-4\"],[8],[0,\"\\n      \"],[6,\"h3\"],[8],[0,\"\\n        \"],[4,\"link-to\",[\"dashboard\"],[[\"class\"],[\"btn\"]],{\"statements\":[[0,\"\\n          \"],[1,[26,\"fa-icon\",null,[[\"icon\"],[\"arrow-left\"]]],false],[0,\"\\n        \"]],\"parameters\":[]},null],[0,\"\\n\\n        \"],[6,\"span\"],[10,\"class\",\"title\"],[8],[0,\"Arrendamiento Comparación\"],[9],[0,\"\\n\\n        \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n          \"],[6,\"span\"],[10,\"class\",\"current-year\"],[8],[1,[21,0,[\"currentYear\"]],false],[9],[0,\"\\n\\n          \"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"light\",[26,\"action\",[[21,0,[]],\"changeYear\"],null]]],{\"statements\":[[0,\"\\n            \"],[1,[26,\"fa-icon\",null,[[\"icon\"],[\"cog\"]]],false],[0,\"\\n          \"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container-fluid px-4 py-4 table-container\"],[8],[0,\"\\n  \"],[6,\"h5\"],[10,\"class\",\"header blue\"],[8],[0,\"Arrendamiento Comparación:\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/dashboard/arrendamiento-comparacion.hbs"
    }
  });

  _exports.default = _default;
});