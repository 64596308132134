define("sirvo-web/mixins/uploader", ["exports", "ember-uploader/uploaders/uploader", "sirvo-web/config/environment"], function (_exports, _uploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    uploadUrl: null,

    _getNewUploader() {
      if (!this.uploadUrl) {
        throw new Error('Set uploadUrl for file upload.');
      }

      let {
        host,
        namespace
      } = _environment.default.API;
      let {
        token
      } = this.get('session.data.authenticated');

      let uploader = _uploader.default.create({
        url: `${host}/${namespace}/${this.uploadUrl}`,
        paramName: 'archivo',
        ajaxSettings: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });

      return uploader;
    }

  });

  _exports.default = _default;
});