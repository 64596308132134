define("sirvo-web/controllers/inventario/movimientos/salidas/detalles", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/mixins/uploader", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "sirvo-web/models/kar-movdet"], function (_exports, _abstractModuleIndex, _uploader, _emberChangeset, _emberChangesetValidations, _validators, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_uploader.default, {
    request: Ember.inject.service(),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelClass: _karMovdet.default,
    editRoute: Ember.computed('indexRoute', function () {
      return `${this.indexRoute}.registro-detalle`;
    }),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'movimiento'
        },
        value: this.record.get('id')
      }];
    }),
    includedRelationships: Ember.computed(function () {
      return ['articulo', 'unidadMedida', 'bodega'];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'No.',
        valuePath: 'linea',
        width: '80px'
      }, {
        label: 'Artículo',
        valuePath: 'articulo.codarticulo',
        width: '110px'
      }, {
        label: 'Nombre',
        valuePath: 'articulo.descripcion',
        width: '200px'
      }, {
        label: 'U/Medida',
        valuePath: 'unidadMedida.codigo',
        width: '100px',
        sortable: false
      }, {
        label: 'Bodega',
        valuePath: 'bodega.codbodega',
        width: '100px',
        sortable: false
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        width: '90px',
        sortable: false
      }, {
        label: 'Costo Unitario',
        valuePath: 'precioUnitario',
        width: '100px',
        sortable: false
      }, {
        label: 'Total',
        valuePath: 'total',
        width: '100px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Consultar',
            buttonClass: 'btn-primary',
            icon: 'eye',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    polizaColumns: Ember.computed(function () {
      return [{
        label: 'Cuenta',
        valuePath: 'cuenta',
        width: '110px',
        sortable: false
      }, {
        label: 'Nombre',
        valuePath: 'cuentaNombre',
        width: '200px',
        sortable: false
      }, {
        label: 'Concepto contable',
        valuePath: 'descripcionContable',
        width: '300px',
        sortable: false
      }, {
        label: 'Cargo',
        valuePath: 'cargo',
        width: '100px',
        sortable: false,
        totalValuePath: 'cargo',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Abono',
        valuePath: 'abono',
        width: '100px',
        sortable: false,
        totalValuePath: 'cargo',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Centro costo',
        valuePath: 'centroCosto',
        width: '110px',
        sortable: false
      }, {
        label: 'Centro integración',
        valuePath: 'centroIntegracion',
        width: '150px',
        sortable: false
      }];
    }),
    detalles: Ember.computed(function () {
      return Ember.A([]);
    }),
    data: null,
    fileChangeset: null,
    fileValidations: Ember.computed(function () {
      return {
        archivo: (0, _validators.validatePresence)({
          presence: true,
          message: 'Ningún archivo seleccionado'
        }),
        type: (0, _validators.validateInclusion)({
          list: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
          message: 'Archivo inválido'
        })
      };
    }),

    newFileChangeset() {
      let changeset = new _emberChangeset.default(new Ember.Object({
        archivo: null
      }), (0, _emberChangesetValidations.default)(this.get('fileValidations')), this.get('fileValidations'));
      this.set('fileChangeset', changeset);
    },

    refreshPoliza() {
      if (!this.record.id) {
        return [];
      }

      this.loader.setLoading(true);
      return this.request.get(`kar-movs/${this.record.id}/poliza`).then(poliza => {
        this.set('poliza', poliza);
      }).finally(() => this.loader.setLoading(false));
    },

    actions: {
      onClose() {
        this.transitionToRoute(this.indexRoute);
      },

      addDetail() {
        this.transitionToRoute(this.editRoute, this.record.id, 'new');
      },

      edit(detalle) {
        this.transitionToRoute(this.editRoute, this.record.id, detalle.id);
      },

      delete(item, row, lightTable) {
        const superRef = this._super.bind(this);

        if (this.fechaCierre >= this.record.get('fecha')) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        return superRef(item, row, lightTable);
      },

      refreshPoliza() {
        return this.refreshPoliza();
      },

      filesUpdated(file) {
        // File input cleared?
        if (!file) {
          this.set('data', []);
        }
      },

      descargarPlantilla() {
        if (this.record.get('isNew')) {
          return;
        }

        this.loader.setLoading(true);
        return this.request.get(`kar-movs/${this.record.id}/carga-detalles?getTemplate=1`).finally(() => this.loader.setLoading(false));
      },

      cargarArchivo(save = false) {
        if (this.registroCerrado) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        this.set('uploadUrl', `kar-movs/${this.record.id}/carga-detalles`);
        return this.fileChangeset.validate().then(() => {
          if (!this.fileChangeset.get('isValid')) {
            this.toast.error('Debe adjuntar un archivo válido.');
            return;
          }

          let uploader = this._getNewUploader();

          this.loader.setLoading(true);
          return uploader.upload(this.fileChangeset.get('archivo'), {
            save: save ? 1 : 0
          }).then(data => {
            this.set('data', data);

            if (data.processed) {
              this.toast.success('Datos guardados correctamente');
              this.newFileChangeset();
              this.set('data', []);
              this.set('reload', true);
            } else {
              this.toast.success('Archivo validado correctamente');
            }
          }).catch(error => {
            this.toast.error('Ocurrió un error durante la carga. Revise los errores.');

            if (error.responseJSON && error.responseJSON.data) {
              this.set('data', error.responseJSON);
            }

            throw error;
          }).finally(() => {
            // Because in each upload a new one is instantiated.
            uploader.destroy();
            this.loader.setLoading(false);
          });
        });
      }

    }
  });

  _exports.default = _default;
});