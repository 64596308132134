define("sirvo-web/routes/inventario/movimientos/salidas/registro-detalle/cuentas", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),

    model() {
      let superData = this._super(...arguments);

      let periodo = superData.periodo;
      let tipoCuenta = superData.tipoCuenta;

      if (this.fechaCierre >= superData.movimiento.get('fecha')) {
        this.toast.error('Registro solo de lectura por cierre de inventario.');
        return this.transitionTo(this.parentRoute);
      }

      return Ember.RSVP.hash({
        periodo,
        tipoCuenta,
        cuentasContables: this.store.query('emp-nomenclatura', {
          filter: {
            empresa: this.company.id,
            periodo: periodo.id,
            ultimoNivel: 1
          },
          sort: 'codcuenta'
        }),
        centrosCosto: this.store.query('emp-cc', {
          filter: {
            empresa: this.company.id,
            periodo: periodo.id,
            ultimoNivel: 1
          }
        }),
        centrosIntegracion: this.store.query('emp-ci', {
          filter: {
            empresa: this.company.id,
            periodo: periodo.id,
            ultimoNivel: 1
          }
        })
      });
    }

  });

  _exports.default = _default;
});