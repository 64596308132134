define("sirvo-web/routes/dashboard/metros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    request: Ember.inject.service(),
    loader: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),

    model() {
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        metrosData: this.request.get('dashboard/arrendamiento/metros')
      }).then(({
        metrosData
      }) => {
        return Ember.RSVP.resolve(this.processor.prepareMetrosData(metrosData));
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentYear', new Date().getFullYear());
    }

  });

  _exports.default = _default;
});