define("sirvo-web/models/com-pais", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codpais: (0, _model.attr)('string'),
    codIso: (0, _model.attr)('string'),
    nompais: (0, _model.attr)('string'),
    // codmoneda: attr('string'),
    // usaconsumidorfinal: attr('number'),
    // usacodpostal: attr('number'),
    // usaretencionisr: attr('number'),
    // usaretencioniva: attr('number'),
    // usaexencioniva: attr('number'),
    // usatipocontrib: attr('number'),
    // tipocontribLista: attr('string'),
    // tipocontribDefa: attr('string'),
    // usacatecontrib: attr('number'),
    // catecontribLista: attr('string'),
    // catecontribDefa: attr('string'),
    // usaregistroiva: attr('number'),
    // usagiroempresa: attr('number'),
    // usaimptimbreprensa: attr('number'),
    // porcentajeTimbreDePrensa: attr('string'),
    // usaVerificaNit: attr('string'),
    // rutinaVerificaNit: attr('string'),
    // usancf: attr('string'),
    // noaplicaImp60dias: attr('string'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    moneda: (0, _model.belongsTo)('com-moneda')
  });

  _exports.default = _default;
});