define("sirvo-web/models/com-tipo-docto", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codsistema: attr('string'),
    sistema: (0, _model.belongsTo)('string'),
    codtipodoctos: (0, _model.attr)('own-sistema'),
    // codclavedoc: attr('string'),
    claveDocumento: (0, _model.attr)('string'),
    nomtipodoc: (0, _model.attr)('string'),
    deha: (0, _model.attr)('string'),
    usatasac: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    espropio: (0, _model.attr)('number', {
      defaultValue: 0
    }) // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});