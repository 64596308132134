define("sirvo-web/controllers/inventario/procesos/inventario-fisico/articulos/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.procesos.inventario-fisico.articulos'),
    request: Ember.inject.service(),
    indexRoute: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      generarNuevoConteo() {
        if (!this.record.get('id')) {
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: 'Generar nuevo conteo',
          message: '¿Está seguro que quiere habilitar un nuevo conteo?',
          onSubmit: () => {
            this.loader.setLoading(true);
            this.request.post(`kar-inventario-fisico-articulos/${this.record.get('id')}/generar-conteo`).then(nuevoInventarioArticulo => {
              this.toast.success('Conteo generado correctamente.');
              this.transitionToRoute(this.routeName, nuevoInventarioArticulo.data.id);
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});