define("sirvo-web/mixins/reports-fechas-filters", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentMonthIndex: Ember.computed(function () {
      let now = new Date();
      return now.getMonth();
    }),
    previousMonthIndex: Ember.computed(function () {
      let now = new Date();
      now.setMonth(now.getMonth() - 1);
      return now.getMonth();
    }),
    actions: {
      setDateByMonthIndex(monthIndex, mes) {
        if (monthIndex < 0 || monthIndex > 11) {
          throw Error('Invalid month index provided.');
        }

        let fechaInicio = (0, _moment.default)();
        let fechaFin = (0, _moment.default)();

        if ((0, _moment.default)(fechaInicio).month() === 0 && mes === 'anterior') {
          fechaInicio.set('year', (0, _moment.default)(fechaInicio).year() - 1);
          fechaFin.set('year', (0, _moment.default)(fechaFin).year() - 1);
        }

        fechaInicio.month(monthIndex).startOf('month');
        fechaFin.month(monthIndex).endOf('month');
        this.changeset.set('fechaInicio', fechaInicio.toDate());
        this.changeset.set('fechaFin', fechaFin.toDate());
      },

      setDateByYear() {
        let fechaInicio = (0, _moment.default)();
        let fechaFin = (0, _moment.default)();
        fechaInicio.startOf('year');
        fechaFin.endOf('year');
        this.changeset.set('fechaInicio', fechaInicio.toDate());
        this.changeset.set('fechaFin', fechaFin.toDate());
      }

    }
  });

  _exports.default = _default;
});