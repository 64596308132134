define("sirvo-web/templates/inventario/tablas/tipos-movimientos/record/generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6DIu4NQj",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"tipomov\",\"Código\"],[[\"placeholder\",\"disabled\"],[\"Código\",true]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"descripcion\",\"Descripción\"],[[\"placeholder\"],[\"Descripción\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"habilitado\",\"Habilitado\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"procedimientosPropios\",\"Movimiento con procedimientos propios\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/tipos-movimientos/record/generales.hbs"
    }
  });

  _exports.default = _default;
});