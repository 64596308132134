define("sirvo-web/controllers/facturacion/movimientos/emision-recibo/record/concepto-facturable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      selectedConceptoFacturable(conceptoFacturable) {
        Ember.set(this.changeset, 'codConcepto', conceptoFacturable.get('codconcepto'));
        Ember.set(this.changeset, 'descontable', conceptoFacturable.get('descripcion'));
      }

    }
  });

  _exports.default = _default;
});