define("sirvo-web/controllers/oca/ordenes-traslado", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ocaHelper: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    columns: Ember.computed(function () {
      return [{
        label: 'Código',
        valuePath: 'orden_despacho_codigo',
        width: '200px',
        sortable: false
      }, {
        label: 'Fecha',
        valuePath: 'orden_despacho_fecha',
        width: '150px',
        sortable: false
      }, {
        label: 'Serie',
        valuePath: 'serie',
        width: '100px',
        sortable: false
      }, {
        label: 'Número',
        valuePath: 'numero',
        width: '150px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          procesar: {
            title: 'Procesar orden',
            buttonClass: 'btn-info',
            icon: 'check-square-o',
            action: this.actions.procesarOrden.bind(this)
          }
        }
      }];
    }),

    reloadTable() {
      return this.ocaHelper.getOrdenesDespacho(this.company, 'BODEGA_TRASLADO').then(ordenesTraslado => Ember.set(this.model, 'ordenesTraslado', ordenesTraslado));
    },

    actions: {
      procesarOrden(orden) {
        this.transitionToRoute(`${this.routeName}.procesar`, orden);
      },

      reloadTable() {
        return this.reloadTable();
      }

    }
  });

  _exports.default = _default;
});