define("sirvo-web/controllers/carga-facturas", ["exports", "sirvo-web/config/environment", "ember-uploader/uploaders/uploader"], function (_exports, _environment, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    data: null,
    saveResult: null,
    saveResultPopupOpen: null,
    uploadUrl: null,
    save: 0,

    getSerializedData() {
      let data = {
        save: this.get('save'),
        codigoEmpresa: this.documentoChangeset.get('codigoEmpresa')
      };
      return data;
    },

    _getNewUploader() {
      let {
        token
      } = this.get('session.data.authenticated');

      let uploader = _uploader.default.create({
        url: this.uploadUrl,
        paramName: 'archivo',
        ajaxSettings: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      });

      return uploader;
    },

    actions: {
      descargarPlantilla() {
        this.get('loader').setIsLoading();
        return this.request.get('carga-facturas', {
          getTemplate: true
        }).then(() => {
          this.toast.success('Planilla generada correctamente');
        }).finally(() => this.loader.setLoading(false));
      },

      cargarArchivo(guardarArchivo) {
        let {
          host,
          namespace
        } = _environment.default.API;
        let url = `${host}/${namespace}/carga-facturas`;
        this.set('save', guardarArchivo);
        this.set('uploadUrl', url);
        this.loader.setIsLoading();
        return this.documentoChangeset.validate().then(() => {
          if (!this.documentoChangeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Hubo un problema al cargar el documento.');
            return;
          }

          const data = this.getSerializedData();

          let uploader = this._getNewUploader();

          this.loader.setLoading(true);
          return uploader.upload(this.documentoChangeset.get('archivo'), data).then(data => {
            if (guardarArchivo === 0) {
              this.toast.success('Documento validado correctamente');
              this.set('data', data);
            }

            if (guardarArchivo === 1) {
              this.toast.success('Datos guardados correctamente');
              this.set('saveResult', data);
              this.set('saveResultPopupOpen', true);
            }
          }).catch(error => {
            this.toast.error('No se pudo cargar correctamente el documento.');
            throw error;
          }).finally(() => {
            this.loader.setLoading(false);
          });
        });
      }

    }
  });

  _exports.default = _default;
});