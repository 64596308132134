define("sirvo-web/controllers/alquileres-renta/maestros/propiedades/record/documentos", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexCtrl: Ember.inject.controller('alquileres-renta.maestros.propiedades'),

    reloadModel() {
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        documentos: this.store.query('documento-propiedad', {
          filter: {
            codigoEmpresa: this.company.get('codempresa'),
            codigoPropiedad: this.model.record.codigoPropiedad
          }
        })
      }).then(({
        documentos
      }) => {
        Ember.set(this.model, 'documentos', documentos);
      }).finally(() => this.loader.setLoading(false));
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      agregarDocumento() {
        if (this.record.get('isNew')) {
          this.toast.error('Guarde la propiedad para poder agregar documentos.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.agregar-documento`);
      },

      descargarDocumento(id) {
        this.loader.setIsLoading();
        return this.request.get(`documento-propiedads/${id}`).then(() => {
          this.toast.success('Documento descargado correctamente');
        }).finally(() => this.loader.setLoading(false));
      },

      eliminarDocumento(documento) {
        this.send('openModal', 'popups/simple-confirm', {
          title: '¿Está seguro de eliminar el registro?',
          message: '',
          onSubmit: () => {
            this.loader.setIsLoading();
            documento.destroyRecord().then(() => {
              this.toast.success('Documento eliminado correctamente');
            }).catch(serverResponse => {
              documento.rollbackAttributes();

              if (Array.isArray(serverResponse.errors)) {
                Ember.A(serverResponse.errors).forEach(error => {
                  this.toast.error(error.message);
                });
              } else {
                this.toast.error('No se pudo eliminar el registro.');
              }

              throw serverResponse;
            }).finally(() => this.loader.setLoading(false));
          }
        });
      }

    }
  });

  _exports.default = _default;
});