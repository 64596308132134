define("sirvo-web/controllers/inventario/tablas/articulos-ubicaciones/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexRoute: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    otrosTiposUbicaciones: Ember.computed('tiposUbicaciones', 'record', function () {
      return this.tiposUbicaciones.without(this.record);
    }),

    submit() {
      // Validar que el registro tenga un tipo de ubicación superior si no es la
      // principal y viceversa.
      if (this.changeset.get('esPrincipal') && this.changeset.get('tipoUbicacionSuperior.id')) {
        this.changeset.addError('tipoUbicacionSuperior', ['La ubicación principal no puede tener un nivel superior.']);
        return;
      } else if (!this.changeset.get('esPrincipal') && !this.changeset.get('tipoUbicacionSuperior.id')) {
        this.changeset.addError('tipoUbicacionSuperior', ['Si no es la ubicación principal, debe especificar la ubicación superior.']);
        return;
      }

      this.changeset._deleteKey('_errors', 'tipoUbicacionSuperior');

      return this._super();
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});