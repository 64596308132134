define("sirvo-web/controllers/inventario/reportes/movimientos", ["exports", "sirvo-web/mixins/reports-fechas-filters", "moment"], function (_exports, _reportsFechasFilters, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportsFechasFilters.default, {
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),

    getSerializedFilters() {
      let fechaInicio = this.changeset.get('fechaInicio');
      let fechaFin = this.changeset.get('fechaFin');
      let filters = {
        tipoMovimiento: this.changeset.get('tipoMovimiento.id'),
        bodega: this.changeset.get('bodega.id'),
        tiposArticulos: this.changeset.get('tiposArticulos').map(p => p.id),
        centrosCosto: this.changeset.get('centrosCosto').map(p => p.id),
        cuentasContables: this.changeset.get('cuentasContables').map(p => p.id),
        articulo: this.changeset.get('articulo.id'),
        fechaInicio: fechaInicio ? (0, _moment.default)(fechaInicio).format() : null,
        fechaFin: fechaFin ? (0, _moment.default)(fechaFin).format() : null
      };
      return filters;
    },

    actions: {
      searchArticulo(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.articleQuery) {
          Ember.run.cancel(this.articleQuery);
        }

        this.articleQuery = Ember.run.later(() => {
          this.set('availableArticulos', this.store.query('kar-articulo', {
            filter: {
              empresa: this.company.id,
              descripcion: {
                OR: text
              },
              codarticulo: {
                OR: text
              }
            }
          }));
        }, 500);
      },

      searchCentroCosto(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (!this.model.periodo || !this.model.periodo.get('id')) {
          this.toast.error('Período actual no disponible.');
          return;
        }

        if (this.centrosCostoQuery) {
          Ember.run.cancel(this.centrosCostoQuery);
        }

        this.centrosCostoQuery = Ember.run.later(() => {
          this.set('availableCentrosCosto', this.store.query('emp-cc', {
            filter: {
              empresa: this.company.id,
              periodo: this.model.periodo.get('id'),
              codcc: {
                OR: text
              },
              descripcion: {
                OR: text
              }
            }
          }));
        }, 500);
      },

      searchCuentaContable(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (!this.model.periodo || !this.model.periodo.get('id')) {
          this.toast.error('Período actual no disponible.');
          return;
        }

        if (this.cuentasContablesQuery) {
          Ember.run.cancel(this.cuentasContablesQuery);
        }

        this.cuentasContablesQuery = Ember.run.later(() => {
          this.set('availableCuentasContables', this.store.query('emp-nomenclatura', {
            filter: {
              empresa: this.company.id,
              periodo: this.model.periodo.get('id'),
              codcuenta: {
                OR: text
              },
              nomcta: {
                OR: text
              }
            }
          }));
        }, 500);
      },

      generarReporte() {
        this.loader.setIsLoading();
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Seleccione un valor para los campos obligatorios.');
            return;
          }

          const filters = this.getSerializedFilters();
          this.loader.setLoading(true);
          this.toast.info('Generando reporte...');
          return this.request.get('kar/reporte-movimientos', filters).then(() => {
            this.toast.success('Reporte generado exitosamente');
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});