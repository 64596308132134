define("sirvo-web/templates/admon/grupos/directorios/record/datos-regionales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Gc6Inld",
    "block": "{\"symbols\":[\"f\",\"zona\",\"muni\",\"depto\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"component\",[[21,1,[\"ace-power-select\"]],\"depto\",\"Cod. Departamento\"],[[\"selected\",\"options\",\"placeholder\",\"searchField\",\"onchange\"],[[21,0,[\"changeset\",\"depto\"]],[21,0,[\"departamentos\"]],\"Seleccionar...\",\"nombre\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"depto\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,4,[\"coddepto\"]],false],[0,\" - \"],[1,[21,4,[\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"ace-power-select\"]],\"muni\",\"Cod. Municipio\"],[[\"selected\",\"options\",\"placeholder\",\"searchField\",\"onchange\"],[[21,0,[\"changeset\",\"muni\"]],[21,0,[\"municipios\"]],\"Seleccionar...\",\"nombre\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"muni\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,3,[\"codmuni\"]],false],[0,\" - \"],[1,[21,3,[\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"ace-power-select\"]],\"zona\",\"Cod. Municipio\"],[[\"selected\",\"options\",\"placeholder\",\"searchField\",\"onchange\"],[[21,0,[\"changeset\",\"zona\"]],[21,0,[\"zonas\"]],\"Seleccionar...\",\"nombre\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"zona\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,2,[\"codzona\"]],false],[0,\" - \"],[1,[21,2,[\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/admon/grupos/directorios/record/datos-regionales.hbs"
    }
  });

  _exports.default = _default;
});