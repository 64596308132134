define("sirvo-web/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null) {
        this._super(...arguments);
      }
    },

    modelNameFromPayloadKey(key) {
      return Ember.String.dasherize(key);
    }

  });

  _exports.default = _default;
});