define("sirvo-web/routes/inventario/movimientos/ingresos/record/ingreso/articulo", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),

    model() {
      let superData = this._super(...arguments);

      if (this.fechaCierre >= superData.record.get('fecha')) {
        this.toast.error('Registro solo de lectura por cierre de inventario.');
        return this.transitionTo(this.parentRoute);
      }

      let lineaChangeset = this.controllerFor(this.parentRoute).lineaChangeset;
      let queries = {
        articulos: Ember.A([]),
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        }),
        unidadesMedida: Ember.A([])
      };

      if (lineaChangeset && lineaChangeset.get('articulo.id')) {
        queries.unidadesMedida = this.store.query('kar-tabla-medida', {
          filter: {
            articulo: lineaChangeset.get('articulo.id'),
            compra: 1
          },
          include: 'unidadMedida'
        }).then(articuloUMedidas => articuloUMedidas.mapBy('unidadMedida'));
      } // WARNING: Using the Ember merge() function gives error.


      return Ember.RSVP.hash(queries).then(routeData => ({ ...superData,
        ...routeData
      }));
    },

    setupController(controller, model) {
      this._super(controller, model);

      let parenController = this.controllerFor(this.parentRoute);
      controller.setProperties({
        lineaChangeset: parenController.get('lineaChangeset'),
        lineaModel: parenController.get('lineaModel'),
        ubicacionesChangesets: parenController.get('ubicacionesChangesets'),
        ubicacionesMeta: {}
      });
      model.tiposUbicaciones.forEach(tipoUbicacion => {
        controller.ubicacionesMeta[tipoUbicacion.id] = {
          tipoUbicacion,
          ubicaciones: Ember.A([])
        };
      });

      if (controller.lineaChangeset.get('bodega.id')) {
        this.kardex.updateBodegaUbicaciones(controller.lineaChangeset.get('bodega'), controller.ubicacionesMeta);
        controller.ubicacionesChangesets.forEach(ubicacionChangeset => {
          this.kardex.updateUbicacionSubDivisiones(ubicacionChangeset.get('tipoUbicacion'), ubicacionChangeset.get('articuloUbicacion'), controller.ubicacionesMeta);
        });
      }
    }

  });

  _exports.default = _default;
});