define("sirvo-web/controllers/inventario/movimientos/salidas/traslado/registro", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    bodegas: null,
    unidadesMedida: null,
    existenciaBase: 0,
    existencia: 0,
    existenciaLote: 0,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    registroCerrado: Ember.computed('record.movimiento.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.record.get('movimiento').get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.record.get('movimiento').get('fecha');
    }),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    loteActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editLote.bind(this)
      }];
    }),
    medidaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaDestinoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editBodegaDestino.bind(this)
      }];
    }),
    propertyNameActionMap: Ember.computed(function () {
      return [{
        propertyNames: ['articulo', 'bodega', 'unidadMedida'],
        action: this.actions.editArticulo.bind(this)
      }, {
        propertyName: 'lote',
        action: this.actions.editLote.bind(this)
      }, {
        propertyName: 'bodegaDestino',
        action: this.actions.editBodegaDestino.bind(this)
      }];
    }),

    resetProperties() {
      this.setProperties({
        existenciaBase: 0,
        existencia: 0,
        existenciaLote: 0
      });
    },

    processSave(changeset) {
      if (this.existenciaBase - Math.abs(changeset.get('cantidadBase')) < 0) {
        this.loader.setLoading(false);
        this.toast.error('No hay suficiente existencia del artículo seleccionado.');
        return Ember.RSVP.resolve(false);
      }

      if (this.changeset.get('articulo.usaLote')) {
        if (this.existenciaLote - Math.abs(changeset.get('cantidadBase')) < 0) {
          this.loader.setLoading(false);
          this.toast.error('No hay suficiente existencia del artículo en el lote especificado.');
          return Ember.RSVP.resolve(false);
        }
      }

      return this._super(...arguments);
    },

    submit() {
      let superRef = this._super.bind(this);

      return Ember.RSVP.all([this.changeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate()), ...this.ubicacionesDestinoChangesets.map(changeset => changeset.validate())]).then(() => {
        if (this.changeset.get('isInvalid')) {
          this.toast.error('Hay un problema de validación con el formulario.');
          return;
        }

        let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesChangesetsValid) {
          this.toast.error('Hay un problema con las ubicaciones del artículo.');
          return;
        }

        let ubicacionesDestinoChangesetsValid = this.ubicacionesDestinoChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesDestinoChangesetsValid) {
          this.toast.error('Hay un problema con las ubicaciones de destino.');
          return;
        }

        let sameBodega = this.changeset.get('bodega.id') === this.changeset.get('bodegaDestino.id');
        let sameUbicaciones = this.tiposUbicaciones.reduce((prevIsTheSame, tipoUbicacion) => {
          let ubicacionOrigen = this.ubicacionesChangesets.findBy('tipoUbicacion.id', tipoUbicacion.id);
          let ubicacionDestino = this.ubicacionesDestinoChangesets.findBy('tipoUbicacion.id', tipoUbicacion.id);
          let sameUbicacion = ubicacionOrigen.get('articuloUbicacion.id') === ubicacionDestino.get('articuloUbicacion.id');
          return prevIsTheSame && sameUbicacion;
        }, true);

        if (sameBodega && this.tiposUbicaciones.length && sameUbicaciones) {
          this.toast.error('Debe seleccionar una ubicación de destino diferente al origen.');
          return;
        }

        this.ubicacionesChangesets.forEach(changeset => changeset.execute());
        this.ubicacionesDestinoChangesets.forEach(changeset => changeset.execute());
        let ubicacionesDestino = this.ubicacionesDestinoChangesets.map(changeset => ({
          tipoUbicacion: changeset.get('tipoUbicacion.id'),
          articuloUbicacion: changeset.get('articuloUbicacion.id')
        }));
        this.changeset.set('ubicacionesDestino', JSON.stringify(ubicacionesDestino));
        return superRef();
      });
    },

    discardChanges() {
      this.changeset.rollback();
      this.ubicacionesChangesets.forEach(changeset => changeset.rollback());
      this.ubicacionesDestinoChangesets.forEach(changeset => changeset.rollback()); // Eliminar los registros de ubicación.

      this.kardex.destroyUnsavedUbicaciones(this.record.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

      this.record.rollbackAttributes(); // Eliminar los registros de ubicación de destino. Por alguna razón, aquí no
      // da error y se eliminan todos correctamente.

      this.ubicacionesDestinoChangesets.mapBy('_content').forEach(ubicacion => ubicacion.rollbackAttributes());
    },

    actions: {
      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeUnidadMedida(unidadMedida) {
        return unidadMedida ? unidadMedida.get('codigo') : null;
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      },

      editLote() {
        this.transitionToRoute(`${this.routeName}.lote`);
      },

      editBodegaDestino() {
        this.transitionToRoute(`${this.routeName}.bodega-destino`);
      },

      updateCantidad(changeset, properyName, value) {
        changeset.set(properyName, value || 0);
        this.kardex.onChangesetCantidadUpdated(changeset);
      },

      cancelEdit() {
        // The route will destroy the record if it's new.
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});