define("sirvo-web/services/directorio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentCompany: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),

    searchKarMovProveedores(text) {
      (true && !(typeof text === 'string') && Ember.assert('Argument 1 expected to be a string', typeof text === 'string'));
      return this.store.query('gru-directorio', {
        filter: {
          grupo: this.company.get('grupo.id'),
          clasificacion: ['EMPLEADOS', 'PROVEEDORES', 'CLIENTES'],
          fechabaja: 'NULL',
          coddirectorio: {
            OR: text
          },
          nombredirectorio: {
            OR: text
          },
          razonsocial: {
            OR: text
          }
        }
      });
    },

    searchEmpleados(text) {
      (true && !(typeof text === 'string') && Ember.assert('Argument 1 expected to be a string', typeof text === 'string'));
      return this.store.query('gru-directorio', {
        filter: {
          grupo: this.company.get('grupo.id'),
          clasificacion: 'EMPLEADOS',
          fechabaja: 'NULL',
          coddirectorio: {
            OR: text
          },
          nombredirectorio: {
            OR: text
          },
          razonsocial: {
            OR: text
          }
        }
      });
    }

  });

  _exports.default = _default;
});