define("sirvo-web/controllers/popups/select-year-month", ["exports", "sirvo-web/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    title: null,
    message: null,
    currentYear: null,
    currentMonth: null,
    years: Ember.computed(function () {
      let nowYear = new Date().getFullYear();
      let years = [nowYear];
      let cont = 0;

      while (cont++ < 11) {
        years.push(nowYear - cont);
      }

      return years;
    }),
    months: _date.monthObjects,

    onSubmit() {},

    doOnSubmit() {
      this.onCancel();
      this.onSubmit(this.currentYear, this.currentMonth);
    },

    onCancel() {}

  });

  _exports.default = _default;
});