define("sirvo-web/controllers/admon/grupos/directorios/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    postSaveTransitionRoute: 'admon.grupos.directorios.record.generales',

    postSave(record) {
      if (this.get('isNew')) {
        // TODO: Research about the best way to update the URL with record id.
        return this.transitionToRoute(this.get('routeName'), record.id);
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});