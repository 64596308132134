define("sirvo-web/controllers/inventario/tablas/articulos/record/datos-generales/asignar-bodega", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    recordCtrl: Ember.inject.controller('inventario.tablas.articulos.record'),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'inventario.tablas.articulos.record.datos-generales',
    bodegasProxy: null,
    actions: {
      updateBodegas(currentValues) {
        this.bodegasProxy.set('bodegas', currentValues);
      },

      onSubmit() {
        let currentIds = this.record.get('bodegas').rejectBy('isDeleted').mapBy('bodega.id');
        let selectedIds = this.bodegasProxy.get('bodegas').mapBy('id');
        this.record.get('bodegas').forEach(articuloBodega => {
          if (!selectedIds.includes(articuloBodega.get('bodega.id'))) {
            articuloBodega.deleteRecord();
          }
        });
        this.bodegasProxy.get('bodegas').forEach(bodega => {
          if (currentIds.includes(bodega.id)) {
            return;
          }

          this.store.createRecord('kar-bodega-det', {
            empresa: this.company,
            articulo: this.record,
            bodega: bodega
          });
        });
        this.recordCtrl.submit();
      },

      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});