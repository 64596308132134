define("sirvo-web/routes/oca/ordenes-despacho", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    ocaHelper: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),

    // beforeModel() {
    //   // Clean the store.
    //   return this.store.unloadAll('oca-orden-despacho');
    // },
    model() {
      return Ember.RSVP.hash({
        ordenesDespacho: this.ocaHelper.getOrdenesDespacho(this.company, 'BODEGA')
      });
    }

  });

  _exports.default = _default;
});