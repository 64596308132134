define("sirvo-web/models/own-sistema", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    orden: (0, _model.attr)('string'),
    codsistema: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    // observa: attr('string'),
    // dirpic: attr('string'),
    habilitado: (0, _model.attr)('number', {
      defaultValue: 0
    }) // noUsaDocumentos: attr('number', { defaultValue: 0 }),
    // esCxp: attr('number', { defaultValue: 0 }),
    // esCxc: attr('number', { defaultValue: 0 }),
    // esActfijos: attr('number', { defaultValue: 0 }),
    // esCxl: attr('number', { defaultValue: 0 }),
    // esAuxiliar: attr('number', { defaultValue: 0 }),
    // esCxe: attr('number', { defaultValue: 0 }),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});