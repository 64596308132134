define("sirvo-web/routes/inventario/reportes/existencia-bodega", ["exports", "servir-ember-utilities/routes/abstract-module-index", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _abstractModuleIndex, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hash
  } = Ember.RSVP;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {
        fechaFin: (0, _validators.validatePresence)({
          presence: true
        }),
        bodega: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      let promises = {
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        })
      };
      return hash(promises).then(data => {
        let filterModel = this.store.createRecord('reporte-existencias', {
          fechaFin: new Date(),
          bodega: data.bodegas.get('firstObject')
        });
        return Ember.merge(data, {
          filterModel
        });
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('changeset', changeset);
    }

  });

  _exports.default = _default;
});