define("sirvo-web/controllers/oca/ordenes-envio/procesar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexCtrl: Ember.inject.controller('oca.ordenes-envio'),
    request: Ember.inject.service(),
    kardex: Ember.inject.service(),
    indexRoute: 'oca.ordenes-envio',
    bodegas: null,
    unidadesMedida: null,

    discardChanges() {
      [].concat(...this.ordenesData.mapBy('ordenLineasData')).mapBy('linea').forEach(orden => orden.rollbackAttributes());
      this.ordenesData.mapBy('ordenCompra').forEach(orden => orden.rollbackAttributes());
      this.record.rollbackAttributes();
    },

    validate() {
      let ordenesChangesets = this.ordenesData.map(ordenData => ordenData.ordenChangeset);
      let lineasChangesets = [].concat(...this.ordenesData.map(ordenData => ordenData.ordenLineasData.mapBy('lineaChangeset')));
      let ubicacionesChangesets = this.ordenesData.reduce((changesets, ordenData) => changesets.concat(...ordenData.ordenLineasData.mapBy('ubicacionesChangesets')), []);
      return Ember.RSVP.all([this.changeset.validate(), ...ordenesChangesets.map(changeset => changeset.validate()), ...lineasChangesets.map(changeset => changeset.validate()), ...ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        if (this.changeset.get('isInvalid')) {
          this.toast.error('Hay errores con el formulario del envío.');
          return Ember.RSVP.resolve(false);
        }

        let ordenesChangesetsValid = ordenesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ordenesChangesetsValid) {
          this.toast.error('Hay un error con las órdenes de compra.');
          return Ember.RSVP.resolve(false);
        }

        let lineasChangesetsValid = lineasChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!lineasChangesetsValid) {
          this.toast.error('Hay un error con las líneas.');
          return Ember.RSVP.resolve(false);
        }

        let ubicacionesChangesetsValid = ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesChangesetsValid) {
          this.toast.error('Hay un error con las ubicaciones.');
          return Ember.RSVP.resolve(false);
        }

        return Ember.RSVP.resolve(true);
      });
    },

    actions: {
      onClose() {
        this.discardChanges();
        this.transitionToRoute(this.indexRoute);
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeUnidadMedida(unidadMedida) {
        return unidadMedida ? unidadMedida.get('codigo') : null;
      },

      bodegaUpdated(lineaData) {
        // Resetear el valor seleccionado de cada ubicación.
        lineaData.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('articuloUbicacion.id')) {
            ubicacionChangeset.set('articuloUbicacion', null);
          }
        });
        this.kardex.updateBodegaUbicaciones(lineaData.lineaChangeset.get('bodega'), lineaData.ubicacionesMeta);
      },

      ubicacionUpdated(lineaData, ubicacionChangeset, articuloUbicacion) {
        let tipoUbicacion = ubicacionChangeset.get('tipoUbicacion'); // Resetear el valor seleccionado de las ubicaciones dependientes.

        lineaData.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            if (ubicacionChangeset.get('articuloUbicacion.id')) {
              ubicacionChangeset.set('articuloUbicacion', null);
            }
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, lineaData.ubicacionesMeta);
      },

      submit() {
        return this.validate().then(isValid => {
          if (!isValid) {
            return;
          }

          this.changeset.execute();
          this.ordenesData.forEach(ordenData => ordenData.ordenChangeset.execute());
          this.ordenesData.forEach(ordenData => ordenData.ordenLineasData.forEach(lineaData => lineaData.lineaChangeset.execute()));
          let lastTipoUbicacion = this.kardex.findLastTipoUbicacion(this.tiposUbicaciones);
          let ordenesDataSerialized = this.ordenesData.map(ordenData => {
            let {
              ordenCompra
            } = ordenData;
            let fecha = (0, _moment.default)(ordenCompra.get('fecha'));
            let lineas = ordenData.ordenLineasData.map(lineaData => {
              let {
                linea
              } = lineaData;
              let ubicacion = null;

              if (lastTipoUbicacion) {
                ubicacion = lineaData.ubicacionesChangesets.findBy('tipoUbicacion.id', lastTipoUbicacion.get('id'));
              }

              return {
                articulo: linea.get('articulo.id'),
                bodega: linea.get('bodega.id'),
                ubicacion: ubicacion ? ubicacion.get('articuloUbicacion.id') : null,
                unidadMedida: linea.get('unidadMedida.id'),
                cantidad: linea.get('cantidad'),
                subtotal: linea.get('subtotal'),
                iva: linea.get('iva'),
                total: linea.get('total')
              };
            });
            return {
              empresa: ordenCompra.get('empresa.id'),
              codigo: ordenCompra.get('codigo'),
              ordenCompraId: ordenCompra.get('ordenCompraId'),
              fecha: fecha.isValid() ? fecha.format('YYYY-MM-DD HH:mm:ss') : null,
              serie: ordenCompra.get('serie'),
              numero: ordenCompra.get('numero'),
              proveedor: ordenCompra.get('proveedor'),
              justificacion: ordenCompra.get('justificacion'),
              cuentaContable: ordenCompra.get('cuentaContable'),
              centroCosto: ordenCompra.get('centroCosto'),
              centroIntegracion: ordenCompra.get('centroIntegracion'),
              centroActividad: ordenCompra.get('centroActividad'),
              lineas
            };
          });
          let fecha = (0, _moment.default)(this.record.get('fecha'));
          let data = {
            codigo: this.record.get('codigo'),
            ordenEnvioId: this.record.get('ordenEnvioId'),
            empresa: this.record.get('empresa.id'),
            fecha: fecha.isValid() ? fecha.format('YYYY-MM-DD HH:mm:ss') : null,
            ordenesCompra: ordenesDataSerialized
          };
          this.loader.setLoading(true);
          return this.request.post('kar/procesar-orden-envio', data).then(res => {
            this.toast.success(res.message || 'Proceso realizado correctamente.');
            this.indexCtrl.reloadTable();
            this.transitionToRoute(this.indexRoute);
          }).catch(error => {
            this.toast.error('Ocurrió un error al procesar la orden de envío.');
            throw error;
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});