define("sirvo-web/routes/inventario/tablas/articulos-ubicaciones/registros/record/ubicacion", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    model() {
      let superData = this.modelFor(this.parentRoute);
      let record = superData.record;

      if (!record.get('tipoUbicacion.id') || !record.get('tipoUbicacion').get('tipoUbicacionSuperior.id')) {
        this.toast.error('Este registro no requiere la asignación de un nivel superior.');
        return this.transitionTo(this.parentRoute);
      }

      return Ember.RSVP.hash({
        ubicaciones: this.store.query('kar-articulo-ubicacion', {
          filter: {
            tipoUbicacion: record.get('tipoUbicacion').get('tipoUbicacionSuperior.id')
          }
        })
      }).then(routeData => ({ ...superData,
        ...routeData
      }));
    }

  });

  _exports.default = _default;
});