define("sirvo-web/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _applicationRouteMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    /**
     * @private
     */
    renderModal(template) {
      this.render(template, {
        outlet: 'modal',
        into: 'application'
      });
    },

    /**
     * @private
     */
    closeModal() {
      this.disconnectOutlet({
        outlet: 'modal',
        parentView: 'application'
      });
    },

    beforeModel() {
      this._super(...arguments);

      this.intl.setLocale('es-GT');

      if (this.session.isAuthenticated) {
        return this._loadCurrentUser();
      }
    },

    /**
     * @private
     */
    _loadCurrentUser() {
      return this.get('currentUser').load().catch(e => {
        this.session.invalidate();
        throw e;
      });
    },

    /**
     * @inherit
     *
     * After session gets authenticated, load the current user.
     */
    sessionAuthenticated() {
      const callSuper = this._super.bind(this);

      this._loadCurrentUser().then(callSuper);

      this.transitionTo('index');
    },

    actions: {
      openModal(modalTemplatePath, controllerProperties) {
        if (!controllerProperties) {
          controllerProperties = {};
        }

        let onCancelFn = controllerProperties.onCancel;

        if (typeof onCancelFn === 'function') {
          controllerProperties.onCancel = () => {
            this.closeModal();
            onCancelFn();
          };
        } else {
          controllerProperties.onCancel = this.closeModal.bind(this);
        }

        Ember.setProperties(this.controllerFor(modalTemplatePath), controllerProperties);
        this.renderModal(modalTemplatePath);
      }

    }
  });

  _exports.default = _default;
});