define("sirvo-web/controllers/oca/ordenes-compra", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ocaHelper: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    columns: Ember.computed(function () {
      return [{
        label: 'Código',
        valuePath: 'orden_compra_codigo',
        width: '120px',
        sortable: false
      }, {
        label: 'Proveedor código',
        valuePath: 'proveedor_codigo',
        width: '120px',
        sortable: false
      }, {
        label: 'Proveedor nombre',
        valuePath: 'proveedor_nombre',
        width: '200px',
        sortable: false
      }, {
        label: 'Total',
        valuePath: 'total_orden_compra',
        width: '100px',
        sortable: false,
        emberIntlCurrency: 'GTQ',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          procesar: {
            title: 'Procesar orden',
            buttonClass: 'btn-info',
            icon: 'check-square-o',
            action: this.actions.procesarOrden.bind(this)
          }
        }
      }];
    }),

    reloadTable() {
      // this.store.unloadAll('oca-orden-compra');
      return this.ocaHelper.getOrdenesCompra(this.company).then(ordenesCompra => Ember.set(this.model, 'ordenesCompra', ordenesCompra));
    },

    actions: {
      procesarOrden(orden) {
        this.transitionToRoute(`${this.routeName}.procesar`, orden);
      },

      reloadTable() {
        return this.reloadTable();
      }

    }
  });

  _exports.default = _default;
});