define("sirvo-web/controllers/dashboard/arrendamiento-comparacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),
    loader: Ember.inject.service(),
    currentYear: null,

    changeYear(selectedYear) {
      if (selectedYear === this.currentYear) {
        return;
      }

      this.set('currentYear', selectedYear);
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        arrendamientosDataActual: this.request.get('dashboard/arrendamiento/empresas', {
          anio: this.currentYear
        }),
        arrendamientosDataAnterior: this.request.get('dashboard/arrendamiento/empresas', {
          anio: this.currentYear - 1
        })
      }).then(({
        arrendamientosDataActual,
        arrendamientosDataAnterior
      }) => {
        return Ember.RSVP.hash({
          arrendamientosDataActual: this.processor.prepareArrendamientosData(arrendamientosDataActual),
          arrendamientosDataAnterior: this.processor.prepareArrendamientosData(arrendamientosDataAnterior)
        }).then(data => {
          this.set('model', data);
        });
      }).finally(() => this.loader.setNotLoading());
    },

    actions: {
      changeYear() {
        this.send('openModal', 'popups/select-year', {
          title: 'Cambiar año',
          message: null,
          currentYear: this.currentYear,
          onSubmit: this.changeYear.bind(this)
        });
      }

    }
  });

  _exports.default = _default;
});