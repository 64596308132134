define("sirvo-web/routes/inventario/movimientos/ingresos/ajuste/registro", ["exports", "servir-ember-utilities/routes/abstract-module-record", "sirvo-web/validations/kar-movdet"], function (_exports, _abstractModuleRecord, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-movdet',
    parentRoute: 'inventario.movimientos.ingresos.ajuste',
    indexRoute: 'inventario.movimientos.ingresos.ajuste.detalles',
    routeRecordIdentifierDynamicSegment: 'detalle_id',
    validations: Ember.computed(function () {
      return { ..._karMovdet.ajusteValidations
      };
    }),
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let modelsToQuery = [{
        name: 'tiposUbicaciones',
        modelName: 'kar-articulo-tipo-ubicacion',
        queryParams: {
          filter: {
            empresa: this.company.id
          }
        }
      }];

      if (!this.isNew) {
        modelsToQuery.push({
          name: 'ubicaciones',
          modelName: 'kar-mov-det-ubicacion',
          queryParams: {
            filter: {
              movimientoDetalle: this.recordId
            },
            include: 'articuloUbicacion'
          }
        });
      }

      return modelsToQuery;
    }),

    model(params) {
      let parentData = this.modelFor(this.parentRoute);

      if (!parentData.record.get('id')) {
        this.toast.error('Guarde el movimiento para poder registrar un detalle.');
        return this.transitionTo(`${this.parentRoute}.detalles`);
      }

      if (params.detalle_id === 'new' && this.fechaCierre >= parentData.record.get('fecha')) {
        this.toast.error('Registro solo de lectura por cierre de inventario.');
        return this.transitionTo(`${this.parentRoute}.detalles`);
      }

      return this._super(...arguments).then(modelData => {
        let relationships = Ember.A([modelData.record.get('articulo'), modelData.record.get('bodega'), modelData.record.get('unidadMedida'), modelData.record.get('cuenta'), modelData.record.get('centroCosto'), modelData.record.get('centroIntegracion')]);
        return Ember.RSVP.all(relationships).then(() => modelData);
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      (0, _karMovdet.applyValidationsOnChangesetIngreso)(controller.changeset, this.validations);
      let parentData = this.modelFor(this.parentRoute);
      let movDet = model.record;

      if (movDet.get('isNew')) {
        movDet.set('movimiento', parentData.record);
      }

      controller.set('movimiento', parentData.record);
      controller.set('ubicacionesChangesets', this.kardex.createMovDetUbicacionesChangesets(model.tiposUbicaciones, movDet));
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName.startsWith(this.routeName)) {
          return false;
        }

        if (this.controller.get('changeset').get('isDirty')) {
          transition.abort();
          this.send('openModal', 'popups/simple-confirm', {
            title: 'La forma se ha modificado. ¿Está seguro de descartar sus cambios?',
            message: '',
            onSubmit: () => {
              this.controller.discardChanges();
              transition.retry();
            }
          });
          return false;
        }

        this.controller.discardChanges();
      }

    }
  });

  _exports.default = _default;
});