define("sirvo-web/routes/inventario/movimientos/ingresos/record/ingreso", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    setupController() {
      this._super(...arguments);

      let parenController = this.controllerFor(this.parentRoute);
      this.controller.set('detalles', parenController.detalles);
      this.controller.createNewLinewChangeset();
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName.startsWith(this.routeName)) {
          return false;
        }

        if (this.controller.lineaChangeset.get('isDirty')) {
          transition.abort();
          this.send('openModal', 'popups/simple-confirm', {
            title: '¿Está seguro de descartar sus cambios?',
            message: '',
            onSubmit: () => {
              this.controller.cancelAddingItem();
              transition.retry();
            }
          });
          return false;
        }

        this.controller.cancelAddingItem();
      }

    }
  });

  _exports.default = _default;
});