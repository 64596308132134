define("sirvo-web/components/tables/cell/articulo-ubicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    table: null,
    row: null,
    column: null,

    init() {
      this._super(...arguments);

      Ember.RSVP.resolve(this.value).then(loadedValue => {
        this.set('value', this.getValue(loadedValue));
      });
    },

    getValue(current) {
      if (current.get('tipoUbicacion.id') === this.column.tipoUbicacion.get('id')) {
        return Ember.RSVP.resolve(current.get('codigo'));
      }

      return current.get('ubicacionSuperior').then(newUbicacion => this.getValue(newUbicacion));
    }

  });

  _exports.default = _default;
});