define("sirvo-web/models/emp-concepto", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codempresa: (0, _model.attr)('string'),
    codsistema: (0, _model.attr)('string'),
    codconcepto: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    // usadetallectas: attr('number', { defaultValue: 0 }),
    codcuenta: (0, _model.attr)('string'),
    codca: (0, _model.attr)('string'),
    codcc: (0, _model.attr)('string'),
    codci: (0, _model.attr)('string'),
    codgrupo: (0, _model.attr)('string'),
    codclasifica: (0, _model.attr)('string'),
    coddirectorio: (0, _model.attr)('string'),
    // esServicio: attr('number', { defaultValue: 0 }),
    // esBienes: attr('number', { defaultValue: 0 }),
    // esExportacion: attr('number', { defaultValue: 0 }),
    // esExento: attr('number', { defaultValue: 0 }),
    // esImpuesto: attr('number', { defaultValue: 0 }),
    // esNoSujeto: attr('number', { defaultValue: 0 }),
    // esCuentaAjena: attr('number', { defaultValue: 0 }),
    habilitar: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('string'),
    codusr: (0, _model.attr)('string'),
    codigoDescripcion: Ember.computed('codconcepto', 'descripcion', function () {
      return `${this.codconcepto || ''} - ${this.descripcion || ''}`;
    })
  });

  _exports.default = _default;
});