define("sirvo-web/models/iva-tipo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codpais: attr('string'),
    pais: (0, _model.belongsTo)('com-pais'),
    clasificacion: (0, _model.attr)('string'),
    tipo: (0, _model.attr)('string'),
    observa: (0, _model.attr)('string'),
    // usaTipodocimp: attr('string'),
    porcentaje: (0, _model.attr)('number') // esdefault: attr('string'),
    // fechaBaja: attr('string'),
    // creusr: attr('string'),
    // fecusr: attr('string'),
    // codusr: attr('string'),
    // stausr: attr('string'),

  });

  _exports.default = _default;
});