define("sirvo-web/routes/inventario/tablas/articulos-ubicaciones/registros/record/bodega", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      return Ember.RSVP.hash({
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        })
      });
    }

  });

  _exports.default = _default;
});