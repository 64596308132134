define("sirvo-web/routes/inventario/tablas/articulos/record/unidades-medida", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    setupController(controller, model) {
      this._super(controller, model);

      controller.createNewChangeset();
    },

    actions: {
      willTransition() {
        this.controller.destroyCurrentRecord();
        return true;
      }

    }
  });

  _exports.default = _default;
});