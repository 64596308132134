define("sirvo-web/controllers/inventario/movimientos/salidas/ajuste/movimiento/proveedor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    directorio: Ember.inject.service(),
    proveedoresQuery: null,
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      searchProveedor(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.proveedoresQuery) {
          Ember.run.cancel(this.proveedoresQuery);
        }

        this.proveedoresQuery = Ember.run.later(() => {
          this.set('proveedores', this.directorio.searchKarMovProveedores(text));
        }, 500);
      }

    }
  });

  _exports.default = _default;
});