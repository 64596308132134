define("sirvo-web/controllers/dashboard/parqueos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),
    currentYear: null,
    selectedEmpresa: null,
    parqueosData: null,
    parqueosTotales: null,
    parqueosTotalIngresos: 0,
    selectedRow: null,
    chartData: Ember.computed('selectedEmpresa', 'parqueosTotales', 'selectedRow', function () {
      let saldos = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (this.selectedEmpresa && this.parqueosTotales && this.parqueosTotales.length) {
        saldos = this.parqueosTotales.map(res => res.totalSaldo || 0);
      }

      if (this.selectedRow) {
        saldos = this.selectedRow.detalles.map(det => det.saldo || 0);
      }

      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [{
          label: 'Saldos',
          backgroundColor: '#628bd4',
          data: saldos.slice(0, 12)
        }]
      };
    }),
    chartOptions: Ember.computed('selectedEmpresa', 'parqueosTotales', 'selectedRow', function () {
      let title = 'Ninguna empresa seleccionada';

      if (this.selectedEmpresa) {
        title = this.selectedEmpresa.codigoNombre;
      }

      if (this.selectedRow) {
        title = this.selectedRow.nombreCuenta;
      }

      return {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: title,
          fontSize: 16
        }
      };
    }),

    changeYear(selectedYear) {
      if (selectedYear === this.currentYear) {
        return;
      }

      this.set('currentYear', selectedYear);
      this.empresaUpdated();
    },

    empresaUpdated() {
      this.setProperties({
        parqueosData: [],
        parqueosTotales: [],
        parqueosTotalIngresos: 0,
        selectedRow: null
      });

      if (!this.selectedEmpresa || !this.selectedEmpresa.get('id')) {
        return;
      }

      let empresaId = this.selectedEmpresa.get('id');
      this.request.get('dashboard/parqueos', {
        empresaId,
        anio: this.currentYear
      }).then(parqueosData => {
        let transformedData = this.processor.transformParqueosData(parqueosData);
        let parqueosTotales = this.processor.computeGrandTotalsByMonth(transformedData, ['saldo']);
        let parqueosTotalesData = Object.values(parqueosTotales);
        let parqueosTotalIngresos = parqueosTotalesData.reduce((sum, resumen) => sum + resumen.totalSaldo, 0);
        this.setProperties({
          parqueosData: transformedData,
          parqueosTotales: parqueosTotalesData,
          parqueosTotalIngresos
        });
      });
    },

    actions: {
      changeYear() {
        this.send('openModal', 'popups/select-year', {
          title: 'Cambiar año',
          message: null,
          currentYear: this.currentYear,
          onSubmit: this.changeYear.bind(this)
        });
      },

      empresaUpdated() {
        this.empresaUpdated();
      },

      onSelectRow(selectedRow) {
        this.set('selectedRow', selectedRow);
      }

    }
  });

  _exports.default = _default;
});