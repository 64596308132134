define("sirvo-web/controllers/inventario/movimientos/ingresos/ajuste/detalles", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-movdet"], function (_exports, _abstractModuleIndex, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _karMovdet.default,
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    editRoute: Ember.computed('parentRoute', function () {
      return `${this.parentRoute}.registro`;
    }),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'movimiento'
        },
        value: this.record.get('id') || -1
      }];
    }),
    includedRelationships: Ember.computed(function () {
      return ['articulo', 'unidadMedida', 'bodega'];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Artículo',
        valuePath: 'articulo.codarticulo',
        width: '110px'
      }, {
        label: 'Descripción',
        valuePath: 'articulo.descripcion',
        width: '200px'
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        sortable: false,
        width: '100px'
      }, {
        label: 'U. Medida',
        valuePath: 'unidadMedida.codigo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Precio U.',
        valuePath: 'precioUnitario',
        sortable: false,
        width: '100px'
      }, {
        label: 'Costo neto',
        valuePath: 'valorNeto',
        sortable: false,
        width: '100px',
        totalValuePath: 'valorNeto',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Costo total',
        valuePath: 'total',
        sortable: false,
        width: '100px',
        totalValuePath: 'total',
        cellComponent: 'tables/cell/accounting-amount'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Consultar',
            buttonClass: 'btn-primary',
            icon: 'eye',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    detalles: Ember.computed(function () {
      return Ember.A([]);
    }),
    actions: {
      edit(detalle) {
        if (!this.record.id || !detalle.id) {
          return;
        }

        this.transitionToRoute(this.editRoute, this.record.id, detalle.id);
      },

      delete(item, row, lightTable) {
        const superRef = this._super.bind(this);

        if (this.fechaCierre >= this.record.get('fecha')) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        return superRef(item, row, lightTable);
      }

    }
  });

  _exports.default = _default;
});