define("sirvo-web/models/ayr-propiedad", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codigoEmpresa: (0, _model.attr)('string'),
    codigoPropiedad: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    folio: (0, _model.attr)('string'),
    libro: (0, _model.attr)('string'),
    registro: (0, _model.attr)('string'),
    valorMantenimientoExterno: (0, _model.attr)('number'),
    cantidadM2: (0, _model.attr)('number'),
    valorM2: (0, _model.attr)('number'),
    observaciones: (0, _model.attr)('string'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string')
  });

  _exports.default = _default;
});