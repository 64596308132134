define("sirvo-web/controllers/facturacion/movimientos/emision-recibo/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('facturacion.movimientos.emision-recibo'),
    indexRoute: null,
    firmadoElectronicamente: false,
    isMonedaLocal: true,
    directorioActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editDirectorio.bind(this)
      }];
    }),
    conceptoFacturableActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editConceptoFacturable.bind(this)
      }];
    }),
    sucursalActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editSucursal.bind(this)
      }];
    }),
    bancoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editBanco.bind(this)
      }];
    }),
    documentoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editDocumento.bind(this)
      }];
    }),

    postSave()
    /*record*/
    {
      if (this.indexCtrl) {
        this.indexCtrl.set('reload', true);
      }

      return this._super(...arguments);
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      serializeBanco(banco) {
        return banco ? banco.get('codigoNombre') : null;
      },

      serializeDocumento(documento) {
        return documento ? documento.get('codigoNombre') : null;
      },

      serializeConceptoFacturable(conceptoFacturable) {
        return conceptoFacturable ? conceptoFacturable.get('codigoDescripcion') : null;
      },

      editDirectorio() {
        if (this.firmadoElectronicamente) {
          this.toast.warning('No se puede modificar el directorio.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.directorio`);
      },

      editConceptoFacturable() {
        if (this.firmadoElectronicamente) {
          this.toast.warning('No se puede modificar el concepto facturable.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.concepto-facturable`);
      },

      editSucursal() {
        if (this.firmadoElectronicamente) {
          this.toast.warning('No se puede modificar la sucursal.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.sucursal`);
      },

      editBanco() {
        this.transitionToRoute(`${this.routeName}.banco`);
      },

      editDocumento() {
        if (!this.changeset.get('banco.id')) {
          this.toast.warning('Debe seleccionar un banco primero.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.documento`);
      },

      certificarOImprimir() {
        this.transitionToRoute(`${this.routeName}.certificacion-impresion`);
      },

      reloadTable() {
        this.set('reload', true);
      }

    }
  });

  _exports.default = _default;
});