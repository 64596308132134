define("sirvo-web/services/request", ["exports", "fetch", "ember-cli-file-saver/mixins/file-saver", "ember-fetch/utils/serialize-query-params", "ember-fetch/errors", "sirvo-web/config/environment"], function (_exports, _fetch, _fileSaver, _serializeQueryParams, _errors, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_fileSaver.default, {
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    errorHandler: Ember.inject.service(),

    _saveAttachmentFile(content, headers) {
      let disposition = headers['content-disposition'];
      let contentType = headers['content-type'];

      if (!disposition) {
        throw new Error('Got no Content-Disposition header.');
      }

      if (!contentType) {
        throw new Error('Got no Content-Type header.');
      }

      let fileName = null;

      if (disposition.indexOf('filename') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      if (!fileName) {
        throw new Error('No filename got from Content-Disposition header.');
      }

      if (disposition.indexOf('inline') !== -1) {
        this._openDocumentInNewTab(content, headers);

        return;
      }

      this.saveFileAs(decodeURI(fileName), content, contentType);
    },

    _openDocumentInNewTab(content
    /*, headers*/
    ) {
      let url = window.URL.createObjectURL(content);
      window.open(url, '_blank');
    },

    _handleResponse(res) {
      if (res.ok) {
        let headers = res.headers && res.headers.map || res.headers;

        if (!headers || !headers['content-type']) {
          return res;
        }

        if (headers['content-type'].indexOf('application/json') === 0) {
          return res.json();
        }

        if (headers['content-type'].indexOf('application/vnd.ms-excel') === 0 || headers['content-type'].indexOf('application/pdf') === 0) {
          return res.blob().then(blobData => {
            this._saveAttachmentFile(blobData, headers);

            return res;
          });
        }

        return res;
      }

      if ((0, _errors.isBadRequestResponse)(res)) {
        this.toast.error('Solicitud incorrecta.');
      } else if ((0, _errors.isUnauthorizedResponse)(res)) {
        this.toast.error('Esta operación requiere de autenticación.');
      } else if ((0, _errors.isForbiddenResponse)(res)) {
        this.toast.error('No tiene permiso para realizar esta operación.');
      } else if (_errors.isNotFoundResponse) {
        this.toast.error('Registro no encontrado.');
      } else if ((0, _errors.isInvalidResponse)(res)) {
        this.toast.error('Hay un error de validación.');
      } else if ((0, _errors.isServerErrorResponse)(res)) {
        this.toast.error('En este momento no se puede procesar su solicitud.');
      }

      throw res;
    },

    async fetch(url, options = {}) {
      let {
        token
      } = this.session.get('data.authenticated');
      let {
        host,
        namespace
      } = _environment.default.API;
      let reqOptions = { ...options
      };

      if (!reqOptions.headers) {
        reqOptions.headers = {};
      }

      if (!reqOptions.anonymous) {
        reqOptions.headers = Ember.merge(reqOptions.headers, {
          Authorization: `Bearer ${token}`
        });
      }

      return (0, _fetch.default)(`${host}/${namespace}/${url}`, reqOptions).then(this._handleResponse.bind(this)).catch(error => {
        this.errorHandler.handleBackendErrors(error, 'Ocurrió un error con su solicitud.');
      });
    },

    async get(url, params = null, options = {}) {
      if (!params) {
        return this.fetch(url, options);
      }

      const paramsString = (0, _serializeQueryParams.default)(params);
      return this.fetch(`${url}?${paramsString}`, options);
    },

    async post(url, data = {}, options = {}) {
      let reqOptions = Ember.merge({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }, options);
      return this.fetch(url, reqOptions);
    }

  });

  _exports.default = _default;
});