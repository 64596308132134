define("sirvo-web/templates/inventario/tablas/bodegas/record/datos-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tqBo1xCo",
    "block": "{\"symbols\":[\"f\",\"ubicacion\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codbodega\",\"Código\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"descripcion\",\"Descripción\"],[[\"placeholder\"],[\"Descripción\"]]],false],[0,\"\\n\\n\"],[4,\"component\",[[21,1,[\"ace-power-select\"]],\"ubicacion\",\"Ubicación / Localización\"],[[\"selected\",\"options\",\"renderInPlace\",\"placeholder\",\"searchField\",\"onchange\"],[[21,0,[\"changeset\",\"ubicacion\"]],[21,0,[\"ubicaciones\"]],true,\"Seleccionar...\",\"descripcion\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"ubicacion\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[21,2,[\"descripcion\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"observaciones\",\"Observaciones\"],[[\"placeholder\"],[\"Observaciones\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/bodegas/record/datos-generales.hbs"
    }
  });

  _exports.default = _default;
});