define("sirvo-web/controllers/inventario/movimientos/ingresos/ajuste/registro", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "sirvo-web/utils/format"], function (_exports, _abstractModuleRecord, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    movimiento: null,
    bodegas: null,
    unidadesMedida: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    registroCerrado: Ember.computed('movimiento.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.movimiento.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.movimiento.get('fecha');
    }),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    medidaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    propertyNameActionMap: Ember.computed(function () {
      return [{
        propertyNames: ['articulo', 'bodega', 'unidadMedida'],
        action: this.actions.editArticulo.bind(this)
      }];
    }),

    submit() {
      if (!this.changeset.get('cantidad') && !this.changeset.get('total')) {
        this.toast.error('Ajuste incorrecto. Debe registrar ya sea una cantidad o un monto.');
        return;
      }

      let superRef = this._super.bind(this);

      return Ember.RSVP.all([this.changeset.validate(), ...this.ubicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        if (this.changeset.get('isInvalid')) {
          this.toast.error('Hay un problema de validación con el formulario.');
          return;
        }

        let ubicacionesChangesetsValid = this.ubicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!ubicacionesChangesetsValid) {
          this.toast.error('Hay un problema con las ubicaciones del artículo.');
          return;
        }

        this.ubicacionesChangesets.forEach(changeset => changeset.execute());
        return superRef();
      });
    },

    updateDependentValues() {
      let tasaCambio = parseFloat(this.changeset.get('tasac')) || 0;
      Ember.setProperties(this.changeset, {
        subtotal: this.changeset.get('total'),
        valorNeto: this.changeset.get('total'),
        valorNetoMe: tasaCambio ? (0, _format.round)(this.changeset.get('total') / tasaCambio) : 0
      });
    },

    discardChanges() {
      this.changeset.rollback();
      this.ubicacionesChangesets.forEach(changeset => changeset.rollback()); // Eliminar los registros de ubicación.

      this.kardex.destroyUnsavedUbicaciones(this.record.get('ubicaciones')); // Es necesario que esto ocurra después de eliminar las ubicaciones.

      this.record.rollbackAttributes();
    },

    actions: {
      cancelEdit() {
        // The route will destroy the record if it's new.
        this.transitionToRoute(this.indexRoute);
      },

      updateCantidad(changeset, properyName, value) {
        changeset.set(properyName, value || 0);
        this.kardex.onChangesetCantidadUpdated(changeset);
      },

      updateCosto(changeset, properyName, value) {
        changeset.set(properyName, value || 0);
        let cantidad = parseFloat(changeset.get('cantidad')) || 0;

        if (properyName === 'precioUnitario') {
          changeset.set('total', (0, _format.round)(cantidad * changeset.get('precioUnitario')));
        } else {
          changeset.set('precioUnitario', cantidad ? (0, _format.round)(changeset.get('total') / cantidad, 4) : 0);
        }

        this.updateDependentValues();
      },

      updateTasaCambio(changeset, properyName, value) {
        changeset.set(properyName, value || 0);
        this.updateDependentValues();
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeUnidadMedida(unidadMedida) {
        return unidadMedida ? unidadMedida.get('codigo') : null;
      },

      serializeCuenta(cuenta) {
        return cuenta ? cuenta.get('codcuenta') : null;
      },

      serializeCentroCosto(centroCosto) {
        return centroCosto ? centroCosto.get('codcc') : null;
      },

      serializeCentroIntegracion(centroIntegracion) {
        return centroIntegracion ? centroIntegracion.get('codci') : null;
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      }

    }
  });

  _exports.default = _default;
});