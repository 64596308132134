define("sirvo-web/templates/inventario/movimientos/ingresos/record/detalles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YH+SBW/m",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"tables/simple-table\",null,[[\"model\",\"columns\",\"displayTotalRow\",\"tableClassNames\",\"totalRowClass\"],[[21,0,[\"detalles\"]],[21,0,[\"columns\"]],true,\"ember-table thead-gray\",\"table-total-row\"]]],false],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/ingresos/record/detalles.hbs"
    }
  });

  _exports.default = _default;
});