define("sirvo-web/models/emp-periodo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codperiodo: (0, _model.attr)('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    nomenclatura: (0, _model.belongsTo)('com-def-nomenclatura'),
    fechaInicioPeriodo: (0, _model.attr)('date'),
    fechaFinPeriodo: (0, _model.attr)('date'),
    // periodoExtraordinario: attr('number', { defaultValue: 0 }),
    // periodoInactivo: attr('number', { defaultValue: 0 }),
    // observaciones: attr('string'),
    // ivaCredito: attr('string'),
    // ivaCreditoExterior: attr('string'),
    // ivaDebito: attr('string'),
    // ivaCombustible: attr('string'),
    // ivaRetencion: attr('string'),
    // ivaRetencionPorPagar: attr('string'),
    // ivaExencion: attr('string'),
    // isrCobro: attr('string'),
    // isrPago: attr('string'),
    // isrPagoNoDomiciliados: attr('string'),
    // isrPagoTerceros: attr('string'),
    // facturaEspecialIva: attr('string'),
    // facturaEspecialComprasIsr: attr('string'),
    // facturaEspecialServiciosIsr: attr('string'),
    // timbrePrensa: attr('string'),
    // impuestoTurismo: attr('string'),
    // gananciaAcumulada: attr('string'),
    // gananciaEjercicio: attr('string'),
    // gananciaCambiaria: attr('string'),
    // perdidaCambiaria: attr('string'),
    // gastosCuentaAjena: attr('string'),
    // mesesEstadoPerdidasGanancias: attr('number', { defaultValue: 12 }),
    usaCentroCostoEstadoPerdidasGanancias: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaCentroActividadBalanceGeneral: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaCentroIntegracionBalanceGeneral: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaCentroIntegracionEstadoPerdidasGanancias: (0, _model.attr)('number', {
      defaultValue: 0
    }) // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});