define("sirvo-web/templates/components/kar/mov-det-ubicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XP4pamJK",
    "block": "{\"symbols\":[\"ubicacionChangesetInner\",\"f\",\"ubicacion\",\"@formClassNames\",\"@classNames\",\"@disabled\",\"@ubicacionesMeta\",\"@ubicacionesChangesets\",\"@ubicacionChangeset\"],\"statements\":[[4,\"extended-form-for\",[[21,9,[]]],[[\"classNames\"],[[21,4,[]]]],{\"statements\":[[4,\"component\",[[21,2,[\"ace-power-select\"]],\"articuloUbicacion\"],[[\"label\",\"selected\",\"options\",\"renderInPlace\",\"placeholder\",\"searchField\",\"disabled\",\"onchange\"],[[21,9,[\"tipoUbicacion\",\"nombre\"]],[21,9,[\"articuloUbicacion\"]],[26,\"get\",[[26,\"get\",[[21,7,[]],[21,9,[\"tipoUbicacion\",\"id\"]]],null],\"ubicaciones\"],null],true,\"Seleccionar\",\"nombre\",[21,6,[]],[26,\"queue\",[[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,9,[\"articuloUbicacion\"]]],null]],null],[26,\"action\",[[21,0,[]],\"onUpdate\",[21,9,[]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[21,3,[\"nombre\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"if\",[[21,9,[\"tipoUbicacion\",\"tipoUbicacionInferior\"]]],null,{\"statements\":[[4,\"each\",[[21,8,[]]],null,{\"statements\":[[4,\"if\",[[26,\"eq\",[[21,1,[\"tipoUbicacion\",\"id\"]],[21,9,[\"tipoUbicacion\",\"tipoUbicacionInferior\",\"id\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[26,\"kar/mov-det-ubicacion\",null,[[\"ubicacionChangeset\",\"ubicacionesChangesets\",\"ubicacionesMeta\",\"disabled\",\"onUpdate\",\"classNames\",\"formClassNames\"],[[21,1,[]],[21,8,[]],[21,7,[]],[21,6,[]],[26,\"action\",[[21,0,[]],\"onUpdate\"],null],[21,5,[]],[21,4,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/kar/mov-det-ubicacion.hbs"
    }
  });

  _exports.default = _default;
});