define("sirvo-web/templates/admon/grupos/directorios/record/es-proveedor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AbsjjOtJ",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"dias_cr_cxp\",\"Días de crédito\"],[[\"placeholder\"],[\"Días de crédito\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"cxp_envio_correos_masivos\",\"Envia correos masivos\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"chq_nombre\",\"Nombre en bancos\"],[[\"placeholder\"],[\"Nombre en bancos\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/admon/grupos/directorios/record/es-proveedor.hbs"
    }
  });

  _exports.default = _default;
});