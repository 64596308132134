define("sirvo-web/models/kar-articulo-ubicacion", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codigo: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    tipoUbicacion: (0, _model.belongsTo)('kar-articulo-tipo-ubicacion'),
    ubicacionSuperior: (0, _model.belongsTo)('kar-articulo-ubicacion', {
      inverse: null
    }),
    bodega: (0, _model.belongsTo)('kar-bodega')
  });

  _exports.default = _default;
});