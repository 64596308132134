define("sirvo-web/models/com-moneda", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codmoneda: (0, _model.attr)('string'),
    nommoneda: (0, _model.attr)('string'),
    nommonedaIngles: (0, _model.attr)('string'),
    codIso: (0, _model.attr)('string'),
    simbolomoneda: (0, _model.attr)('string'),
    // esDolar: attr('number'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string')
  });

  _exports.default = _default;
});