define("sirvo-web/controllers/inventario/movimientos/ingresos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "moment", "sirvo-web/models/kar-mov", "sirvo-web/utils/date"], function (_exports, _abstractModuleIndex, _moment, _karMov, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    router: Ember.inject.service(),
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelClass: _karMov.default,
    reload: true,
    tipoMovimiento: null,
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    currentMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    years: (0, _date.lastYears)(),
    months: _date.monthIndexesFilter,
    routeName: Ember.computed(function () {
      return this.router.currentRouteName.split('.').slice(0, -1).join('.');
    }),
    supportedTipoMovs: Ember.computed(function () {
      return ['INGRESOS', 'EXISTENCIA_INICIAL', 'AJUSTE'];
    }),
    tipoMovIsSupported: Ember.computed('tipoMovimiento', function () {
      return this.supportedTipoMovs.includes(this.tipoMovimiento.get('tipomov'));
    }),
    modelFilters: Ember.computed('tipoMovimiento', 'currentYear', 'currentMonth', function () {
      let filters = [{
        selectedFilter: {
          filterName: 'empresa'
        },
        value: this.company.get('id')
      }, {
        selectedFilter: {
          filterName: 'deha'
        },
        value: {
          exact: 'D'
        }
      }, {
        selectedFilter: {
          filterName: 'tipoMovimiento'
        },
        value: this.tipoMovimiento.get('id')
      }];

      if (parseInt(this.currentMonth)) {
        filters.push({
          selectedFilter: {
            filterName: 'yearmes'
          },
          value: {
            exact: `${this.currentYear}${this.currentMonth}`
          }
        });
      } else {
        let startDate = (0, _moment.default)({
          year: this.currentYear,
          month: 0,
          day: 1
        });
        let endDate = (0, _moment.default)({
          year: this.currentYear,
          month: 11,
          day: 31
        });
        filters.push({
          selectedFilter: {
            filterName: 'fecha'
          },
          value: {
            after: startDate.format('YYYY-MM-DD'),
            before: endDate.format('YYYY-MM-DD')
          }
        });
      }

      return filters;
    }),
    modelSortingProperties: Ember.computed(function () {
      return [{
        propertyName: 'fecha'
      }, {
        propertyName: 'correlativo',
        ascending: true
      }];
    }),
    includedRelationships: Ember.computed(function () {
      return ['proveedor'];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        sortingPropertyName: 'fecha',
        width: '110px'
      }, {
        label: 'Proveedor',
        valuePath: 'proveedor.nombredirectorio',
        filtrable: true,
        filterName: 'proveedor.nombredirectorio',
        width: '200px'
      }, {
        label: 'Serie',
        valuePath: 'serie',
        filtrable: true,
        filterName: 'serie',
        sortable: false,
        width: '90px'
      }, {
        label: 'Número',
        valuePath: 'numero',
        filtrable: true,
        filterName: 'numero',
        width: '100px'
      }, {
        label: 'Valor',
        valuePath: 'valorNeto',
        width: '100px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editMov.bind(this)
          },
          viewDetails: {
            title: 'Detalles',
            buttonClass: 'btn-warning',
            icon: 'list',
            action: this.actions.viewDetails.bind(this)
          },
          viewReport: {
            title: 'Reporte',
            buttonClass: 'btn-info',
            icon: 'file-text-o',
            action: this.actions.viewReport.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    actions: {
      viewDetails(record) {
        let tipoMov = this.tipoMovimiento.get('tipomov');

        if (tipoMov === 'INGRESOS') {
          this.transitionToRoute(`${this.routeName}.record.detalles`, record.id);
        } else if (tipoMov === 'EXISTENCIA_INICIAL') {
          this.transitionToRoute(`${this.routeName}.existencia-inicial.detalles`, record.id);
        } else if (tipoMov === 'AJUSTE') {
          this.transitionToRoute(`${this.routeName}.ajuste.detalles`, record.id);
        } else {
          this.transitionToRoute(`${this.routeName}.registro.detalles`, record.id);
        }
      },

      addRecord() {
        let tipoMov = this.tipoMovimiento.get('tipomov');

        if (tipoMov === 'INGRESOS') {
          this.transitionToRoute(`${this.routeName}.record`, 'new');
        } else if (tipoMov === 'EXISTENCIA_INICIAL') {
          this.transitionToRoute(`${this.routeName}.existencia-inicial`, 'new');
        } else if (tipoMov === 'AJUSTE') {
          this.transitionToRoute(`${this.routeName}.ajuste`, 'new');
        } else {
          this.toast.error(`No se pueden registrar movimientos de tipo ${tipoMov}`);
        }
      },

      editMov(record) {
        if (!record.id) {
          return;
        }

        let tipoMov = this.tipoMovimiento.get('tipomov');

        if (tipoMov === 'INGRESOS') {
          this.transitionToRoute(`${this.routeName}.record`, record.id);
        } else if (tipoMov === 'EXISTENCIA_INICIAL') {
          this.transitionToRoute(`${this.routeName}.existencia-inicial`, record.id);
        } else if (tipoMov === 'AJUSTE') {
          this.transitionToRoute(`${this.routeName}.ajuste`, record.id);
        } else {
          this.transitionToRoute(`${this.routeName}.registro`, record.id);
        }
      },

      delete(item, row, lightTable) {
        if (this.fechaCierre >= item.get('fecha')) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        const superRef = this._super.bind(this);

        this.loader.setLoading(true);
        return this.store.query('kar-movdet', {
          filter: {
            movimiento: item.id
          }
        }).then(detalles => {
          if (!detalles.length) {
            return superRef(item, row, lightTable);
          }

          this.toast.error('El movimiento posee detalles.');
        }).finally(() => this.loader.setLoading(false));
      },

      reloadTable() {
        this.set('reload', true);
      },

      viewReport(record) {
        this.loader.setLoading(true);
        this.toast.info('Generando reporte...');
        return this.request.get(`kar-movs/${record.id}/reporte_pdf`).then(() => this.toast.success('Reporte generado exitosamente')).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});