define("sirvo-web/controllers/facturacion/movimientos/emision-recibo/record/banco", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      selectedBanco(banco) {
        let tasaCambio = 1;

        if (banco.get('codmoneda') !== this.company.get('codmoneda')) {
          tasaCambio = this.get('tasaCambio')['tasa_cambio'];
        }

        Ember.set(this.changeset, 'codmoneda', banco.get('codmoneda'));
        Ember.set(this.changeset, 'moneda', banco.get('moneda'));
        Ember.set(this.changeset, 'tasac', tasaCambio);
      }

    }
  });

  _exports.default = _default;
});