define("sirvo-web/services/ajax-service", ["exports", "ember-ajax/services/ajax", "sirvo-web/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    host: _environment.default.API.host,
    headers: Ember.computed('session.data.authenticated', {
      get() {
        let {
          token
        } = this.get('session.data.authenticated');
        return {
          Authorization: `Bearer ${token}`
        };
      }

    }),

    _handleRequestError(error) {
      if (error.payload && Array.isArray(error.payload.errors)) {
        error.payload.errors.forEach(e => this.toast.error(e.message));
      } else if (error.status === 403) {
        this.toast.error('Parece que no tiene permiso para realizar esta operación.');
      } else {
        this.toast.error('Ocurrió un error con su solicitud.');
      }

      throw error;
    },

    /**
     * Override to inject the `host` value.
     *
     * @param  {[type]} url     [description]
     * @param  {Object} options [description]
     * @return {[type]}         [description]
     */
    request(url, options = {}) {
      const newOptions = Ember.merge(options, {
        host: this.get('host')
      });
      return this._super(url, newOptions).catch(this._handleRequestError.bind(this));
    },

    /**
     * Override to inject the `host` value.
     */
    post(url, options = {}) {
      return this._super(url, Ember.merge(options, {
        host: this.get('host')
      }));
    }

  });

  _exports.default = _default;
});