define("sirvo-web/components/kar/mov-det-ubicacion-vertical-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ubicacionChangeset: null,
    ubicacionesChangesets: null,
    actions: {
      serializeArticuloUbicacion(articuloUbicacion) {
        return articuloUbicacion ? articuloUbicacion.get('nombre') : '';
      }

    }
  });

  _exports.default = _default;
});