define("sirvo-web/controllers/dashboard/metros", ["exports", "sirvo-web/utils/format"], function (_exports, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),
    loader: Ember.inject.service(),
    currentYear: null,
    metrosChartData: Ember.computed('model.metrosTotals', function () {
      let totalsMetros = this.model.metrosTotals.map(totals => totals.totalMetros || 0);
      let totalsOcupados = this.model.metrosTotals.map(totals => totals.totalOcupados || 0);
      let totalsDisponibles = this.model.metrosTotals.map(totals => totals.totalDisponibles || 0);
      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [{
          label: 'Metros',
          backgroundColor: '#89bf65',
          data: totalsMetros.slice(0, 12)
        }, {
          label: 'Ocupados',
          backgroundColor: '#76aee1',
          data: totalsOcupados.slice(0, 12)
        }, {
          label: 'Disponibles',
          backgroundColor: '#f9cc25',
          data: totalsDisponibles.slice(0, 12)
        }]
      };
    }),
    metrosChartOptions: Ember.computed(function () {
      return {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Disponibilidad de Metros',
          fontSize: 16
        }
      };
    }),
    pieChartData: Ember.computed('model', function () {
      return {
        datasets: [{
          data: [this.model.metrosTotalDisponibles, this.model.metrosTotalOcupados],
          backgroundColor: ['#FFC000', '#0070C0']
        }],
        labels: ['Disponibles', 'Ocupados']
      };
    }),
    pieChartOptions: Ember.computed(function () {
      return {
        title: {
          display: true,
          text: 'Metros Disponibles vs Ocupados',
          fontSize: 17,
          fontColor: 'black',
          padding: 14
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, chart) => {
              let data = chart.datasets[0].data;
              let total = data.reduce((total, val) => total + val, 0);
              let percentage = (0, _format.round)(data[tooltipItem.index] / total * 100);
              return `${percentage} %`;
            }
          }
        }
      };
    }),

    changeYear(selectedYear) {
      if (selectedYear === this.currentYear) {
        return;
      }

      this.set('currentYear', selectedYear);
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        metrosData: this.request.get('dashboard/arrendamiento/metros', {
          anio: this.currentYear
        })
      }).then(({
        metrosData
      }) => {
        this.set('model', this.processor.prepareMetrosData(metrosData));
      }).finally(() => this.loader.setNotLoading());
    },

    actions: {
      changeYear() {
        this.send('openModal', 'popups/select-year', {
          title: 'Cambiar año',
          message: null,
          currentYear: this.currentYear,
          onSubmit: this.changeYear.bind(this)
        });
      }

    }
  });

  _exports.default = _default;
});