define("sirvo-web/templates/inventario/movimientos/ingresos/existencia-inicial/detalles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BQzOmqdE",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"tables/paginated-table\",null,[[\"model\",\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"includedRelationships\",\"pageSize\",\"tableClassNames\"],[[21,0,[\"detalles\"]],[21,0,[\"columns\"]],\"kar-movdet\",[21,0,[\"modelClass\"]],[21,0,[\"modelFilters\"]],[21,0,[\"includedRelationships\"]],10,\"ember-table thead-gray\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/ingresos/existencia-inicial/detalles.hbs"
    }
  });

  _exports.default = _default;
});