define("sirvo-web/templates/components/confirmation-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0TTfGG7p",
    "block": "{\"symbols\":[\"Modal\",\"&default\",\"@type\",\"@descriptionText\",\"@onCancel\",\"@onConfirm\"],\"statements\":[[4,\"bs-button\",null,[[\"type\",\"onClick\",\"__ANGLE_ATTRS__\"],[[21,3,[]],[26,\"toggle-action\",[\"open\",[21,0,[]]],null],[26,\"hash\",null,[[\"data-test-button-open-modal\"],[\"\"]]]]],{\"statements\":[[0,\"\\n  \"],[13,2],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"bs-modal\",null,[[\"onSubmit\",\"onHidden\",\"open\",\"renderInPlace\"],[[26,\"action\",[[21,0,[]],[26,\"pipe\",[[21,6,[]],[26,\"toggle-action\",[\"open\",[21,0,[]]],null]],null]],null],[21,5,[]],[21,0,[\"open\"]],true]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],[[\"__ANGLE_ATTRS__\"],[[26,\"hash\",null,[[\"data-test-description\"],[\"\"]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[21,4,[]]],null,{\"statements\":[[0,\"      \"],[1,[21,4,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Estas seguro que desea continuar con la siguiente acción.\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"size\",\"outline\",\"type\",\"__ANGLE_ATTRS__\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"sm\",true,\"secondary\",[26,\"hash\",null,[[\"data-test-button-cancel\"],[\"\"]]]]],{\"statements\":[[0,\"\\n      Cancelar\\n    \"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"size\",\"type\",\"__ANGLE_ATTRS__\"],[[26,\"action\",[[21,0,[]],[21,1,[\"submit\"]]],null],\"sm\",[21,3,[]],[26,\"hash\",null,[[\"data-test-button-confirmation\"],[\"\"]]]]],{\"statements\":[[0,\"\\n      \"],[13,2],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/confirmation-button.hbs"
    }
  });

  _exports.default = _default;
});