define("sirvo-web/templates/components/tables/cell/accounting-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CM0sNoB+",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"style\",[26,\"html-safe\",[[26,\"concat\",[\"text-align: right;\"],null]],null],null],[8],[0,\"\\n\"],[4,\"if\",[[21,0,[\"value\"]]],null,{\"statements\":[[4,\"if\",[[21,0,[\"currency\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"format-number\",[[21,0,[\"value\"]]],[[\"style\",\"currency\"],[\"currency\",[21,0,[\"currency\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[26,\"format-number\",[[21,0,[\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/tables/cell/accounting-amount.hbs"
    }
  });

  _exports.default = _default;
});