define("sirvo-web/controllers/inventario/movimientos/ingresos/registro/movimiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parameters: Ember.computed.readOnly('currentCompany.parameters'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    })
  });

  _exports.default = _default;
});