define("sirvo-web/routes/dashboard/parqueos", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    loader: Ember.inject.service(),
    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    model() {
      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        empresas: this.currentUser.fetchUserEmpresas()
      }).finally(() => this.loader.setLoading(false));
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        currentYear: new Date().getFullYear(),
        selectedEmpresa: null,
        parqueosData: [],
        parqueosTotales: null,
        parqueosTotalIngresos: 0,
        selectedRow: null
      });
      let empresaActualId = this.empresaActual.get('content.id');

      if (empresaActualId) {
        let empresaObject = model.empresas.findBy('id', String(empresaActualId));

        if (empresaObject) {
          controller.set('selectedEmpresa', empresaObject);
          controller.empresaUpdated();
        }
      }
    }

  });

  _exports.default = _default;
});