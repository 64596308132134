define("sirvo-web/routes/inventario/movimientos/ingresos/record/poliza", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    request: Ember.inject.service(),

    model() {
      let parentModel = this.modelFor(this.parentRoute);

      if (parentModel.record.get('isNew')) {
        return parentModel;
      }

      this.loader.setLoading(true); // WARNING: Refrescamos la póliza cada vez que se entra a la ruta porque el
      // backend modifica la póliza cuando se agrega/quita una línea.

      return this.request.get(`kar-movs/${parentModel.record.id}/poliza`).then(poliza => {
        // WARNING: Es una mala práctica que la ruta hija modifique el model de
        // la ruta papá pero ni modos...
        Ember.setProperties(parentModel, {
          poliza
        });
        return parentModel;
      }).finally(() => this.loader.setLoading(false));
    }

  });

  _exports.default = _default;
});