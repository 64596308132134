define("sirvo-web/controllers/inventario/procesos/generar-polizas-contables", ["exports", "sirvo-web/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    empParameters: Ember.computed.readOnly('currentCompany.empParameters'),
    years: (0, _date.lastYears)(5),
    months: Object.keys(_date.monthNamesByIndex),
    monthNamesByIndex: _date.monthNamesByIndex,
    days: Ember.computed('changeset.month', function () {
      return (0, _date.daysOfMonth)(this.changeset.get('month'));
    }),
    actions: {
      onMonthUpdated() {
        this.changeset.set('day', 1);
      },

      submit() {
        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Hay errores en el formulario.');
            return;
          }

          this.loader.setLoading(true);
          return this.request.post('kar/generar-polizas-contables', {
            empresa: this.changeset.get('empresa.id'),
            year: this.changeset.get('year'),
            month: parseInt(this.changeset.get('month')) + 1,
            day: parseInt(this.changeset.get('day'))
          }).then(() => {
            this.toast.success('Proceso realizado correctamente.');
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});