define("sirvo-web/initializers/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    const inflector = _emberInflector.default.inflector;
    inflector.irregular('kar-bodega-cuenta', 'kar-bodega-cuentas');
    inflector.irregular('kar-doc-cuenta', 'kar-doc-cuentas');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});