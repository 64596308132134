define("sirvo-web/routes/inventario/tablas/articulos/record/datos-generales/ubicaciones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'inventario.tablas.articulos.record.datos-generales',

    model() {
      let modelData = this._super(...arguments);

      let promises = {
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        }),
        tiposUbicaciones: this.store.query('kar-articulo-tipo-ubicacion', {
          filter: {
            empresa: this.company.id
          }
        }),
        localizaciones: this.kardex.queryArticuloLocalizaciones(modelData.record)
      };
      return Ember.RSVP.hash(promises).then(routeData => Ember.merge(modelData, routeData));
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.setProperties(model);
      controller.setProperties({
        changeset: this.controllerFor(this.indexRoute).changeset,
        ubicacionesChangesets: Ember.A([]),
        ubicacionesByTipo: {},
        addingUbicacion: false
      });
      model.tiposUbicaciones.forEach(tipoUbicacion => {
        controller.ubicacionesByTipo[tipoUbicacion.id] = {
          tipoUbicacion,
          ubicaciones: Ember.A([])
        };
      });
      controller.groupLocalizaciones();
    }

  });

  _exports.default = _default;
});