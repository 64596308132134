define("sirvo-web/routes/inventario/movimientos/salidas/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.salidas',
    validations: Ember.computed(function () {
      return {
        fecha: (0, _validators.validatePresence)(true),
        tipoMovimiento: (0, _validators.validatePresence)(true),
        tipoDocumento: (0, _validators.validatePresence)(true),
        numero: (0, _validators.validatePresence)(true)
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'tipoMovSalida',
        modelName: 'kar-tipomov',
        queryParams: {
          filter: {
            tipomov: 'SALIDAS',
            habilitar: 1
          }
        }
      }, {
        name: 'documentosSalida',
        modelName: 'kar-doc',
        queryParams: {
          filter: {
            empresa: this.company.get('id'),
            codsistema: 'KAR',
            deha: 'H',
            'tipoMovimiento.tipomov': 'SALIDAS'
          }
        }
      }];
    }),

    createRecordInstance() {
      let now = new Date();
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        serie: now.getFullYear(),
        fecha: now
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let movSalida = model.tipoMovSalida.findBy('tipomov', 'SALIDAS');
      let docSalida = model.documentosSalida.get('firstObject');

      if (!movSalida) {
        this.toast.error('No se encontró el tipo de movimiento "SALIDAS".');
        return this.transitionTo(this.indexRoute);
      }

      if (!docSalida) {
        this.toast.error('No se encontró un tipo de documento para la salida.');
        return this.transitionTo(this.indexRoute);
      }

      Ember.setProperties(model.record, {
        tipoMovimiento: movSalida,
        tipoDocumento: docSalida,
        deha: docSalida.get('deha'),
        usacnt: docSalida.get('usaCnt')
      });

      if (this.fechaCierre >= model.record.get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});