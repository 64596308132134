define("sirvo-web/routes/dashboard/propiedades", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    loader: Ember.inject.service(),
    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    model() {
      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        empresas: this.currentUser.fetchUserEmpresas(),
        propiedades: this.store.query('ayr-propiedad', {
          filter: {
            codigoEmpresa: this.empresaActual.get('content.codempresa')
          }
        })
      }).finally(() => this.loader.setLoading(false));
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        selectedEmpresa: null,
        selectedPropiedad: null,
        propiedades: [],
        documentos: []
      });
      let empresaActualId = this.empresaActual.get('content.id');

      if (empresaActualId) {
        let empresaObject = model.empresas.findBy('id', String(empresaActualId));

        if (empresaObject) {
          controller.set('selectedEmpresa', empresaObject);
          controller.empresaUpdated();
        }
      }
    }

  });

  _exports.default = _default;
});