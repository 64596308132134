define("sirvo-web/routes/inventario/procesos/inventario-fisico/index", ["exports", "servir-ember-utilities/routes/abstract-module-index"], function (_exports, _abstractModuleIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    setupController(controller
    /*, model*/
    ) {
      this._super(...arguments);

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});