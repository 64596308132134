define("sirvo-web/controllers/oca/ordenes-traslado/procesar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexCtrl: Ember.inject.controller('oca.ordenes-traslado'),
    request: Ember.inject.service(),
    kardex: Ember.inject.service(),
    indexRoute: 'oca.ordenes-traslado',
    bodegas: null,

    discardChanges() {
      this.lineasData.mapBy('linea').forEach(linea => linea.rollbackAttributes());
      this.record.rollbackAttributes();
    },

    validate() {
      let allUbicacionesChangesets = [].concat(...this.lineasData.mapBy('ubicacionesChangesets'), ...this.lineasData.mapBy('ubicacionesDestinoChangesets'));
      return Ember.RSVP.all([this.changeset.validate(), ...this.lineasChangesets.map(changeset => changeset.validate()), ...allUbicacionesChangesets.map(changeset => changeset.validate())]).then(() => {
        let lineasChangesetsValid = this.lineasChangesets.reduce((prevIsValid, lineaChangeset) => prevIsValid && lineaChangeset.get('isValid'), true);
        let ubicacionesChangesetsValid = allUbicacionesChangesets.reduce((prevIsValid, changeset) => prevIsValid && changeset.get('isValid'), true);

        if (!this.changeset.get('isValid') || !lineasChangesetsValid || !ubicacionesChangesetsValid) {
          this.toast.error('Hay errores en el formulario.');
          return Ember.RSVP.resolve(false);
        }

        let lastTipoUbicacion = this.kardex.findLastTipoUbicacion(this.tiposUbicaciones);

        for (var i = 0; i < this.lineasData.length; i++) {
          let lineaData = this.lineasData[i];
          let lineaChangeset = lineaData.lineaChangeset;

          if (lastTipoUbicacion) {
            let ubicacion = lineaData.ubicacionesChangesets.findBy('tipoUbicacion.id', lastTipoUbicacion.get('id'));
            let ubicacionDestino = lineaData.ubicacionesDestinoChangesets.findBy('tipoUbicacion.id', lastTipoUbicacion.get('id'));

            if (ubicacion.get('articuloUbicacion.id') === ubicacionDestino.get('articuloUbicacion.id')) {
              this.toast.error('Debe seleccionar una ubicación de destino diferente al origen.');
              return Ember.RSVP.resolve(false);
            }
          } else {
            if (lineaChangeset.get('bodega.id') === lineaChangeset.get('bodega.id')) {
              this.toast.error('Debe seleccionar una bodega de destino diferente al origen.');
              return Ember.RSVP.resolve(false);
            }
          }
        }

        return Ember.RSVP.resolve(true);
      });
    },

    actions: {
      onClose() {
        this.discardChanges();
        this.transitionToRoute(this.indexRoute);
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeUnidadMedida(unidadMedida) {
        return unidadMedida ? unidadMedida.get('codigo') : null;
      },

      bodegaUpdated(lineaData) {
        // Resetear el valor seleccionado de cada ubicación.
        lineaData.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('articuloUbicacion.id')) {
            ubicacionChangeset.set('articuloUbicacion', null);
          }
        });
        this.kardex.updateBodegaUbicaciones(lineaData.lineaChangeset.get('bodega'), lineaData.ubicacionesMeta);
      },

      bodegaDestinoUpdated(lineaData) {
        // Resetear el valor seleccionado de cada ubicación.
        lineaData.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('articuloUbicacionDestino.id')) {
            ubicacionChangeset.set('articuloUbicacionDestino', null);
          }
        });
        this.kardex.updateBodegaUbicaciones(lineaData.lineaChangeset.get('bodegaDestino'), lineaData.ubicacionesDestinoMeta);
      },

      ubicacionUpdated(lineaData, ubicacionChangeset, articuloUbicacion) {
        let tipoUbicacion = ubicacionChangeset.get('tipoUbicacion'); // Resetear el valor seleccionado de las ubicaciones dependientes.

        lineaData.ubicacionesChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            if (ubicacionChangeset.get('articuloUbicacion.id')) {
              ubicacionChangeset.set('articuloUbicacion', null);
            }
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, lineaData.ubicacionesMeta);
      },

      ubicacionDestinoUpdated(lineaData, ubicacionChangeset, articuloUbicacion) {
        let tipoUbicacion = ubicacionChangeset.get('tipoUbicacion'); // Resetear el valor seleccionado de las ubicaciones dependientes.

        lineaData.ubicacionesDestinoChangesets.forEach(ubicacionChangeset => {
          if (ubicacionChangeset.get('tipoUbicacion').get('tipoUbicacionSuperior.id') === tipoUbicacion.get('id')) {
            if (ubicacionChangeset.get('articuloUbicacionDestino.id')) {
              ubicacionChangeset.set('articuloUbicacionDestino', null);
            }
          }
        });
        this.kardex.updateUbicacionSubDivisiones(tipoUbicacion, articuloUbicacion, lineaData.ubicacionesDestinoMeta);
      },

      submit() {
        return this.validate().then(isValid => {
          if (!isValid) {
            return;
          }

          this.changeset.execute();
          this.lineasChangesets.forEach(changeset => changeset.execute());
          let fecha = (0, _moment.default)(this.record.get('fecha'));
          let lastTipoUbicacion = this.kardex.findLastTipoUbicacion(this.tiposUbicaciones);
          let lineasDataSerialized = this.lineasData.map(lineaData => {
            let {
              linea
            } = lineaData;
            let ubicacion = null;
            let ubicacionDestino = null;

            if (lastTipoUbicacion) {
              ubicacion = lineaData.ubicacionesChangesets.findBy('tipoUbicacion.id', lastTipoUbicacion.get('id'));
              ubicacionDestino = lineaData.ubicacionesDestinoChangesets.findBy('tipoUbicacion.id', lastTipoUbicacion.get('id'));
            }

            return {
              articulo: linea.get('articulo.id'),
              bodega: linea.get('bodega.id'),
              ubicacion: ubicacion ? ubicacion.get('articuloUbicacion.id') : null,
              bodegaDestino: linea.get('bodegaDestino.id'),
              ubicacionDestino: ubicacionDestino ? ubicacionDestino.get('articuloUbicacion.id') : null,
              unidadMedida: linea.get('unidadMedida.id'),
              cantidad: linea.get('cantidad'),
              cantidadOrden: linea.get('cantidadOrden')
            };
          });
          let data = {
            empresa: this.record.get('empresa.id'),
            codigo: this.record.get('codigo'),
            ordenDespachoId: this.record.get('ordenDespachoId'),
            fecha: fecha.isValid() ? fecha.format('YYYY-MM-DD HH:mm:ss') : null,
            serie: this.record.get('serie'),
            numero: this.record.get('numero'),
            justificacion: this.record.get('justificacion'),
            lineas: lineasDataSerialized
          };
          this.loader.setLoading(true);
          return this.request.post('kar/procesar-orden-traslado', data).then(res => {
            this.toast.success(res.message || 'Proceso realizado correctamente.');
            this.indexCtrl.reloadTable();
            this.transitionToRoute(this.indexRoute);
          }).catch(error => {
            this.toast.error('Ocurrió un error al procesar la orden de traslado.');
            throw error;
          }).finally(() => this.loader.setLoading(false));
        });
      }

    }
  });

  _exports.default = _default;
});