define("sirvo-web/templates/inventario/tablas/tipos-movimientos/tipo-documento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qhbUuJFy",
    "block": "{\"symbols\":[\"modal\",\"nav\"],\"statements\":[[4,\"bs-modal\",null,[[\"size\",\"onHide\"],[\"lg\",[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Tipo de documento\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-nav\",null,[[\"type\"],[\"tabs\"]],{\"statements\":[[0,\"\\n      \"],[4,\"component\",[[21,2,[\"item\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,2,[\"link-to\"]],\"inventario.tablas.tipos-movimientos.tipo-documento.datos-generales\"],null,{\"statements\":[[0,\"          Datos generales\\n\"]],\"parameters\":[]},null],[0,\"      \"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"component\",[[21,2,[\"item\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,2,[\"link-to\"]],\"inventario.tablas.tipos-movimientos.tipo-documento.cuentas-contables\"],null,{\"statements\":[[0,\"          Cuentas contables\\n\"]],\"parameters\":[]},null],[0,\"      \"]],\"parameters\":[]},null],[0,\"\\n    \"]],\"parameters\":[2]},null],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"tab-content\"],[8],[0,\"\\n      \"],[1,[20,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n      Cancelar\\n    \"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"submit\"],null]]]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/tipos-movimientos/tipo-documento.hbs"
    }
  });

  _exports.default = _default;
});