define("sirvo-web/controllers/inventario/movimientos/salidas/ajuste/movimiento/autorizacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    directorio: Ember.inject.service(),
    empleadosQuery: null,
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      searchEmpleado(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.empleadosQuery) {
          Ember.run.cancel(this.empleadosQuery);
        }

        this.empleadosQuery = Ember.run.later(() => {
          this.set('empleados', this.directorio.searchEmpleados(text));
        }, 500);
      }

    }
  });

  _exports.default = _default;
});