define("sirvo-web/routes/alquileres-renta/maestros/propiedades/record/documentos/agregar-documento", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _isTabSubroute, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {
        codigoEmpresa: (0, _validators.validatePresence)({
          presence: true
        }),
        codigoPropiedad: (0, _validators.validatePresence)({
          presence: true
        }),
        archivo: (0, _validators.validatePresence)({
          presence: true
        }),
        descripcion: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model() {
      let changeset = this.controllerFor(this.parentRoute).get('changeset');

      if (!changeset) {
        return this.transitionTo(this.parentRoute);
      }

      let documentoModel = this.store.createRecord('documento-propiedad', {
        codigoEmpresa: this.get('company.codempresa'),
        codigoPropiedad: changeset.get('codigoPropiedad')
      });
      return documentoModel;
    },

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('documentoChangeset', changeset);
    }

  });

  _exports.default = _default;
});