define("sirvo-web/controllers/cambiar-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexRoute: 'index',
    currentCompany: Ember.inject.service(),
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    selectedEmpresa: null,
    actions: {
      onClose() {
        this.transitionToRoute(this.get('indexRoute'));
      },

      cambiarEmpresa() {
        if (!this.selectedEmpresa) {
          return this.toast.error('Seleccione una empresa');
        }

        this.currentCompany.setCompany(this.selectedEmpresa);
        this.toast.success('Empresa actualizada correctamente');
        this.transitionToRoute(this.get('indexRoute'));
      }

    }
  });

  _exports.default = _default;
});