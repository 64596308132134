define("sirvo-web/templates/alquileres-renta/maestros/propiedades/record/datos-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwZaiYex",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codigoPropiedad\",\"Código\"],[[\"placeholder\",\"disabled\"],[\"Código\",[26,\"not\",[[21,0,[\"record\",\"isNew\"]]],null]]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"descripcion\",\"Descripción\"],[[\"placeholder\"],[\"Descripción\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"cantidadM2\",\"Cantidad metros cuadrados\"],[[\"placeholder\"],[\"Cantidad metros cuadrados\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"valorM2\",\"Valor metros cuadrados\"],[[\"placeholder\"],[\"Valor metros cuadrados\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"folio\",\"Folio\"],[[\"placeholder\"],[\"Folio\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"libro\",\"Libro\"],[[\"placeholder\"],[\"Libro\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"registro\",\"Registro\"],[[\"placeholder\"],[\"Registro\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"valorMantenimientoExterno\",\"Valor mantenimiento externo\"],[[\"placeholder\"],[\"Valor mantenimiento externo\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"observaciones\",\"Observaciones\"],[[\"placeholder\"],[\"Observaciones\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/alquileres-renta/maestros/propiedades/record/datos-generales.hbs"
    }
  });

  _exports.default = _default;
});