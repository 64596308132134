define("sirvo-web/routes/inventario/movimientos/salidas/traslado/registro/bodega-destino", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    kardex: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),

    model() {
      let superData = this._super(...arguments);

      if (!superData.record.get('isNew')) {
        this.toast.error('No puede realizar cambios sobre este campo.');
        return this.transitionTo(this.parentRoute);
      }

      if (this.fechaCierre >= superData.record.get('movimiento').get('fecha')) {
        this.toast.error('Registro solo de lectura por cierre de inventario.');
        return this.transitionTo(this.parentRoute);
      }

      let queries = {
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        })
      }; // WARNING: Using the Ember merge() function gives error.

      return Ember.RSVP.hash(queries).then(routeData => ({ ...superData,
        ...routeData
      }));
    },

    setupController(controller, model) {
      this._super(...arguments);

      let changeset = controller.changeset;

      if (!changeset.get('bodega.id')) {
        this.toast.error('Seleccione primero la bodega de origen.');
        return this.transitionTo(this.parentRoute);
      }

      let parenController = this.controllerFor(this.parentRoute);
      controller.set('ubicacionesDestinoChangesets', parenController.get('ubicacionesDestinoChangesets'));
      controller.set('ubicacionesMeta', {});
      model.tiposUbicaciones.forEach(tipoUbicacion => {
        controller.ubicacionesMeta[tipoUbicacion.id] = {
          tipoUbicacion,
          ubicaciones: Ember.A([])
        };
      });

      if (controller.changeset.get('bodegaDestino.id')) {
        this.kardex.updateBodegaUbicaciones(controller.changeset.get('bodegaDestino'), controller.ubicacionesMeta);
        controller.ubicacionesDestinoChangesets.forEach(ubicacionChangeset => {
          this.kardex.updateUbicacionSubDivisiones(ubicacionChangeset.get('tipoUbicacion'), ubicacionChangeset.get('articuloUbicacion'), controller.ubicacionesMeta);
        });
      }
    }

  });

  _exports.default = _default;
});