define("sirvo-web/routes/inventario/movimientos/salidas/registro-detalle/lote", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    request: Ember.inject.service(),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),

    model() {
      let superData = this._super(...arguments);

      if (!superData.record.get('isNew')) {
        this.toast.error('No puede realizar cambios sobre este campo.');
        return this.transitionTo(this.parentRoute);
      }

      if (this.fechaCierre >= superData.movimiento.get('fecha')) {
        this.toast.error('Registro solo de lectura por cierre de inventario.');
        return this.transitionTo(this.parentRoute);
      }

      let detalleCtrl = this.controllerFor(this.parentRoute);

      if (!detalleCtrl.changeset || !detalleCtrl.changeset.get('articulo.id')) {
        this.toast.error('Debe seleccionar primero un artículo para asignar un lote.');
        return this.transitionTo(this.parentRoute);
      }

      if (!detalleCtrl.changeset.get('articulo').get('usaLote')) {
        this.toast.error('El artículo seleccionado no usa control por lote.');
        return;
      }

      let articuloId = detalleCtrl.changeset.get('articulo.id');
      return this.request.get(`kar-articulos/${articuloId}/existencia-por-lote`, {
        bodegaId: detalleCtrl.changeset.get('bodega.id')
      }) // WARNING: Using the Ember merge() function gives error the second time
      // the route is visited.
      .then(existenciaPorLotes => ({ ...superData,
        existenciaPorLotes
      }));
    },

    setupController(controller, model) {
      this._super(...arguments);

      let changeset = controller.changeset;
      let loteRecord = model.existenciaPorLotes.findBy('lote', changeset.get('lote'));
      controller.set('selectedLote', loteRecord || null);
    }

  });

  _exports.default = _default;
});