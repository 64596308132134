define("sirvo-web/controllers/inventario/tablas/articulos-ubicaciones/index", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-articulo-tipo-ubicacion"], function (_exports, _abstractModuleIndex, _karArticuloTipoUbicacion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    editRoute: 'inventario.tablas.articulos-ubicaciones.record',
    modelClass: _karArticuloTipoUbicacion.default,
    modelFilters: Ember.computed(function () {
      return [{
        selectedFilter: {
          filterName: 'empresa'
        },
        value: this.company.get('id')
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Código',
        valuePath: 'codigo',
        filtrable: true,
        filterName: 'codigo',
        width: '100px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        width: '150px'
      }, {
        label: 'Superior',
        valuePath: 'tipoUbicacionSuperior.nombre',
        width: '150px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          goToDetails: {
            title: 'Detalles',
            buttonClass: 'btn-warning',
            icon: 'list',
            action: this.actions.goToDetails.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, 'new');
      },

      goToDetails(tipoUbicacion) {
        this.transitionToRoute('inventario.tablas.articulos-ubicaciones.registros', tipoUbicacion.id, {
          queryParams: {
            ubicacionSuperior: null
          }
        });
      }

    }
  });

  _exports.default = _default;
});