define("sirvo-web/controllers/inventario/movimientos/ingresos/ajuste", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.ingresos'),
    indexRoute: null,
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),

    postSave()
    /*record*/
    {
      if (this.indexCtrl) {
        this.indexCtrl.set('reload', true);
      }
    },

    actions: {
      onClose() {
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});