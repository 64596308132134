define("sirvo-web/templates/inventario/tablas/parametros/record/importaciones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MVj/3hR2",
    "block": "{\"symbols\":[\"f\",\"periodo\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n\"],[4,\"component\",[[21,1,[\"ace-power-select\"]],\"periodo\",\"Período\"],[[\"selected\",\"options\",\"renderInPlace\",\"placeholder\",\"searchField\",\"onchange\"],[[21,0,[\"changeset\",\"periodo\"]],[21,0,[\"periodos\"]],true,\"Seleccionar...\",\"codperiodo\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"periodo\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,2,[\"codperiodo\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"cuentaGasto\",\"Cuenta gasto\"],[[\"placeholder\"],[\"Cuenta\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"cuentaDai\",\"Cuenta DAI\"],[[\"placeholder\"],[\"Cuenta\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/parametros/record/importaciones.hbs"
    }
  });

  _exports.default = _default;
});