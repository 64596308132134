define("sirvo-web/models/post-oca-orden-compra", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    ordenEnvio: (0, _model.belongsTo)('post-oca-orden-envio'),
    codigo: (0, _model.attr)('string'),
    ordenCompraId: (0, _model.attr)('number'),
    fecha: (0, _model.attr)('date'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number'),
    proveedor: (0, _model.attr)('string'),
    proveedorNombre: (0, _model.attr)('string'),
    justificacion: (0, _model.attr)('string'),
    cuentaContable: (0, _model.attr)('string'),
    centroCosto: (0, _model.attr)('string'),
    centroIntegracion: (0, _model.attr)('string'),
    centroActividad: (0, _model.attr)('string'),
    lineas: (0, _model.hasMany)('post-oca-orden-compra-linea')
  });

  _exports.default = _default;
});