define("sirvo-web/controllers/inventario/movimientos/salidas/ajuste/registro/cuentas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.salidas.ajuste.registro'),
    request: Ember.inject.service(),
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      cuentaUpdated() {
        this.changeset.set('centroCosto', null);
        this.changeset.set('centroIntegracion', null);
        let cuentaId = this.changeset.get('cuenta.id');

        if (!cuentaId) {
          this.indexCtrl.set('tipoCuenta', null);
          return;
        }

        let nomenclaturaId = this.indexCtrl.periodo.belongsTo('nomenclatura').id();
        return Ember.RSVP.hash({
          tipoCuentaData: this.request.get(`emp-nomenclaturas/${cuentaId}/tipo`, {
            nomenclaturaId
          })
        }).then(({
          tipoCuentaData
        }) => {
          Ember.set(this.model, 'tipoCuenta', tipoCuentaData.data.tipo); // Se debe actualizar la propiedad en el modelo de la ruta index también
          // porque el model hook de esta ruta lo obtiene de allí.

          Ember.set(this.indexCtrl.model, 'tipoCuenta', tipoCuentaData.data.tipo); // Esto es para actualizar los campos de lectura en la vista index.

          this.indexCtrl.set('tipoCuenta', tipoCuentaData.data.tipo);
        });
      }

    }
  });

  _exports.default = _default;
});