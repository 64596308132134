define("sirvo-web/routes/inventario/tablas/bodegas/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-bodega',
    indexRoute: 'inventario.tablas.bodegas',
    defaultTabSubRoute: 'datos-generales',
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({
          presence: true
        }),
        codbodega: (0, _validators.validatePresence)({
          presence: true
        }),
        descripcion: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),
    relatedModelsToQuery: Ember.computed('company', function () {
      return [{
        name: 'ubicaciones',
        modelName: 'kar-bodega-ubicacion',
        queryParams: {
          filter: {
            empresa: this.company.get('id')
          }
        }
      }];
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});