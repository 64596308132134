define("sirvo-web/controllers/admon/directorio/contactos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/directorio-contacto"], function (_exports, _abstractModuleIndex, _directorioContacto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    loader: Ember.inject.service(),
    modelClass: _directorioContacto.default,
    selectedDirectorioContacto: null,
    directorio: null,
    contacto: null,
    columns: Ember.computed(function () {
      return [{
        label: 'Código directorio',
        valuePath: 'coddirectorio',
        filtrable: true,
        filterName: 'coddirectorio',
        isShown: true
      }, {
        label: 'Nombre directorio',
        valuePath: 'nombredirectorio',
        isShown: true
      }, {
        label: 'Razón social',
        valuePath: 'razonsocial',
        isShown: true
      }, {
        label: 'Nombre contacto',
        valuePath: 'nombre',
        isShown: true
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        filtrable: true,
        filterName: 'nombre',
        hidden: true
      }];
    }),
    actions: {
      onSelectedDirectorioContacto(directorioContacto) {
        this.loader.setLoading(true);
        let promises = {
          directorio: this.store.findRecord('gru-directorio', directorioContacto.get('iddirectorio')),
          contacto: directorioContacto.get('idcontacto') ? this.store.findRecord('gru-directorio-contacto', directorioContacto.get('idcontacto')) : Ember.A()
        };
        return Ember.RSVP.hash(promises).then(promises => {
          this.setProperties({
            directorio: promises.directorio,
            contacto: promises.contacto,
            selectedDirectorioContacto: directorioContacto
          });
        }).catch(error => {
          this.toast.error('No se pudo completar el proceso.');
          throw error;
        }).finally(() => this.loader.setLoading(false));
      },

      close() {
        this.set('selectedDirectorioContacto', null);
      }

    }
  });

  _exports.default = _default;
});