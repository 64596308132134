define("sirvo-web/controllers/facturacion/movimientos/emision-recibo", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/recibo", "sirvo-web/utils/date"], function (_exports, _abstractModuleIndex, _recibo, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    router: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    user: Ember.computed.readOnly('currentUser.user'),
    editRoute: 'facturacion.movimientos.emision-recibo.record',
    modelName: 'recibo',
    modelClass: _recibo.default,
    reload: true,
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    currentMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    currentYearMonth: Ember.computed('currentYear', 'currentMonth', function () {
      return `${this.currentYear}${this.currentMonth}`;
    }),
    years: (0, _date.lastYears)(),
    months: _date.monthIndexes,
    routeName: Ember.computed(function () {
      return this.router.currentRouteName.split('.').slice(0, -1).join('.');
    }),
    modelFilters: Ember.computed('currentYearMonth', function () {
      return [{
        selectedFilter: {
          filterName: 'codempresa'
        },
        value: this.company.get('codempresa')
      }, {
        selectedFilter: {
          filterName: 'codtipo'
        },
        value: {
          exact: 'RDON'
        }
      }, {
        selectedFilter: {
          filterName: 'yearmes'
        },
        value: {
          exact: this.currentYearMonth
        }
      }, {
        selectedFilter: {
          filterName: 'codusr'
        },
        value: {
          exact: this.user.get('coduser')
        }
      }];
    }),
    modelSortingProperties: Ember.computed(function () {
      return [{
        propertyName: 'fecmov'
      }, {
        propertyName: 'serie'
      }, {
        propertyName: 'numero'
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Tipo',
        valuePath: 'codtipo',
        width: '100px'
      }, {
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        width: '100px'
      }, {
        label: 'Serie',
        valuePath: 'serie',
        width: '100px'
      }, {
        label: 'Numero',
        valuePath: 'numero',
        width: '100px'
      }, {
        label: 'Directorio',
        valuePath: 'coddirectorio',
        width: '100px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      }];
    }),
    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, 'new');
      },

      delete(item, row, lightTable) {
        if (!item || !item.id) {
          return;
        }

        const superRef = this._super.bind(this);

        this.loader.setLoading(true);
        return this.store.findRecord('recibo', item.id).then(recibo => {
          if (recibo.firmaElectronica !== 1) {
            return superRef(item, row, lightTable);
          }

          this.toast.error('El recibo se encuentra firmado electronicamente.');
        }).finally(() => this.loader.setLoading(false));
      },

      reloadTable() {
        this.set('reload', true);
      }

    }
  });

  _exports.default = _default;
});