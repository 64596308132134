define("sirvo-web/templates/inventario/movimientos/ingresos/record/ingreso/proveedor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ge9P0VnA",
    "block": "{\"symbols\":[\"modal\",\"f\",\"directorio\"],\"statements\":[[4,\"bs-modal\",null,[[\"size\",\"onHide\"],[\"lg\",[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[4,\"component\",[[21,2,[\"ace-power-select\"]],\"proveedor\",\"Proveedor\"],[[\"selected\",\"options\",\"renderInPlace\",\"placeholder\",\"searchPlaceholder\",\"noMatchesMessage\",\"loadingMessage\",\"searchField\",\"oninput\",\"onchange\"],[[21,0,[\"changeset\",\"proveedor\"]],[21,0,[\"proveedores\"]],true,\"Click aquí para buscar\",\"Escribe al menos 3 caracteres\",\"No hay resultados\",\"Buscando...\",\"codigoNombreDirectorio\",[26,\"action\",[[21,0,[]],\"searchProveedor\"],null],[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"proveedor\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,3,[\"codigoNombreDirectorio\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n      Cerrar\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/ingresos/record/ingreso/proveedor.hbs"
    }
  });

  _exports.default = _default;
});