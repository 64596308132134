define("sirvo-web/models/kar-bodega-cuenta", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    tipoArticulo: (0, _model.belongsTo)('kar-tipo-articulo'),
    periodo: (0, _model.belongsTo)('emp-periodo'),
    cuenta: (0, _model.belongsTo)('emp-nomenclatura'),
    centroAutorizacion: (0, _model.belongsTo)('emp-ca'),
    centroCosto: (0, _model.belongsTo)('emp-cc'),
    centroIntegracion: (0, _model.belongsTo)('emp-ci'),
    inventarioEnProcesoCuenta: (0, _model.belongsTo)('emp-nomenclatura'),
    inventarioEnProcesoCentroCosto: (0, _model.belongsTo)('emp-cc'),
    inventarioEnProcesoCentroIntegracion: (0, _model.belongsTo)('emp-ci') // codcuentaIng: attr('string'),
    // codccIng: attr('string'),
    // codciIng: attr('string'),
    // codcuentaDev: attr('string'),
    // codccDev: attr('string'),
    // codciDev: attr('string'),
    // codcuentaProExportacion: attr('string'),
    // codccProExportacion: attr('string'),
    // codciProExportacion: attr('string'),
    // codcuentaIngExportacion: attr('string'),
    // codccIngExportacion: attr('string'),
    // codciIngExportacion: attr('string'),
    // codcuentaDevExportacion: attr('string'),
    // codccDevExportacion: attr('string'),
    // codciDevExportacion: attr('string'),
    // codcuentaExento: attr('string'),
    // codccExento: attr('string'),
    // codciExento: attr('string'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    // stausr: attr('string'),

  });

  _exports.default = _default;
});