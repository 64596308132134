define("sirvo-web/templates/dashboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fRAlIETG",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 py-4\"],[8],[0,\"\\n      \"],[6,\"h3\"],[8],[0,\"Dashboard\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"container-fluid px-4 py-4\"],[8],[0,\"\\n  \"],[4,\"link-to\",[\"dashboard.contratos\"],[[\"class\"],[\"btn btn-lg btn-outline-success\"]],{\"statements\":[[0,\"\\n    Contratos\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"link-to\",[\"dashboard.arrendamiento\"],[[\"class\"],[\"btn btn-lg btn-outline-success\"]],{\"statements\":[[0,\"\\n    Arrendamiento - Facturación\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"link-to\",[\"dashboard.locales\"],[[\"class\"],[\"btn btn-lg btn-outline-success\"]],{\"statements\":[[0,\"\\n    Arrendamiento - Locales\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"link-to\",[\"dashboard.metros\"],[[\"class\"],[\"btn btn-lg btn-outline-success\"]],{\"statements\":[[0,\"\\n    Arrendamiento - Metraje\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"link-to\",[\"dashboard.propiedades\"],[[\"class\"],[\"btn btn-lg btn-outline-success\"]],{\"statements\":[[0,\"\\n    Propiedades\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[0,\"\\n  \"],[4,\"link-to\",[\"dashboard.resultados\"],[[\"class\"],[\"btn btn-lg btn-outline-success\"]],{\"statements\":[[0,\"\\n    Resultados\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/dashboard/index.hbs"
    }
  });

  _exports.default = _default;
});