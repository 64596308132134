define("sirvo-web/components/app-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BreadcrumbsComponent = Ember.Component.extend({
    breadcrumbs: Ember.A([])
  });
  BreadcrumbsComponent.reopenClass({
    positionalParams: ['breadcrumbs']
  });
  var _default = BreadcrumbsComponent;
  _exports.default = _default;
});