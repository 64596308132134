define("sirvo-web/routes/inventario/movimientos/salidas/ajuste/movimiento/autorizacion", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),

    model() {
      let modelData = this._super(...arguments);

      if (this.fechaCierre >= modelData.record.get('fecha')) {
        this.toast.error('Registro solo de lectura por cierre de inventario.');
        return this.transitionTo(this.parentRoute);
      }

      return Ember.merge(modelData, {
        empleados: Ember.A([])
      });
    }

  });

  _exports.default = _default;
});