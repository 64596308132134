define("sirvo-web/routes/cambiar-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        empresas: this.currentUser.fetchUserEmpresas()
      }).finally(() => this.loader.setLoading(false));
    }

  });

  _exports.default = _default;
});