define("sirvo-web/templates/inventario/tablas/parametros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QqmtSnDZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 py-4\"],[8],[0,\"\\n      \"],[6,\"h3\"],[8],[0,\"Parámetros\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"pageSize\",\"load\",\"tableClassNames\"],[[21,0,[\"columns\"]],\"kar-parametro\",[21,0,[\"modelClass\"]],[21,0,[\"modelFilters\"]],10,[21,0,[\"reload\"]],\"ember-table thead-gray\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/parametros.hbs"
    }
  });

  _exports.default = _default;
});