define("sirvo-web/routes/inventario/procesos/inventario-fisico/articulos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "sirvo-web/utils/common"], function (_exports, _abstractModuleRecord, _validators, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-inventario-fisico-articulo',
    indexRoute: 'inventario.procesos.inventario-fisico.articulos',
    routeRecordIdentifierDynamicSegment: 'articulo_id',
    validations: Ember.computed(function () {
      return {
        inventarioFisico: (0, _validators.validatePresence)({
          presence: true
        }),
        articulo: (0, _validators.validatePresence)({
          presence: true
        }),
        existenciaFisica: (0, _validators.validateNumber)({
          presence: true,
          gte: 0
        })
      };
    }),

    model(params)
    /*transition*/
    {
      if (params.id === 'new') {
        this.toast.error('Solo el sistema puede crear estos registros.');
        return this.transitionTo(this.indexRoute);
      }

      return this._super(...arguments).then(modelData => {
        let record = modelData.record;
        return Ember.RSVP.all([record.get('articulo'), record.get('bodega'), record.get('inventarioFisicoConteo'), record.get('articuloUbicacion')]).then(() => this.getUbicacionParent(record.get('articuloUbicacion'))).then(() => {
          // Solo por si acaso, pero no debería pasar.
          if (!record.get('bodega.id') && !record.get('articuloUbicacion.id')) {
            return modelData;
          }

          return this.store.query('kar-inventario-fisico-articulo', {
            filter: {
              inventarioFisico: record.get('inventarioFisico.id'),
              articulo: record.get('articulo.id'),
              bodega: record.get('bodega.id'),
              articuloUbicacion: record.get('articuloUbicacion.id')
            }
          }).then(otrosConteos => Ember.merge(modelData, {
            otrosConteos
          }));
        }).then(() => modelData);
      });
    },

    /**
     * Obtiene las ubicaciones superiores de una ubicación hasta el último nivel.
     *
     * @param  KarArticuloUbicacion
     * @return Promise
     */
    getUbicacionParent(ubicacion) {
      if (!ubicacion || !ubicacion.get('id')) {
        return Ember.RSVP.resolve(null);
      }

      if (!(0, _common.getPromiseContent)(ubicacion).belongsTo('ubicacionSuperior').id()) {
        return Ember.RSVP.resolve(null);
      }

      return ubicacion.get('ubicacionSuperior').then(ubicacionSuperior => this.getUbicacionParent(ubicacionSuperior));
    },

    setupController(controller, model) {
      this._super(...arguments); // Ordenar las ubicaciones desde la ubicación root hasta el último nivel
      // para mostrarlos al usuario.


      let ubicaciones = Ember.A([]);

      if (model.record.get('articuloUbicacion.id')) {
        let current = model.record.get('articuloUbicacion');
        ubicaciones.unshiftObject(current);

        while (current.get('ubicacionSuperior.id')) {
          current = current.get('ubicacionSuperior');
          ubicaciones.unshiftObject(current);
        }
      }

      controller.set('ubicaciones', ubicaciones);
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});