define("sirvo-web/templates/inventario/tablas/tipos-movimientos/tipo-documento/datos-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ERSy9qti",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"tipoMovimiento.tipomov\",\"Código\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codtipo\",\"Tipo de documento\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"deha\",\"Naturaleza contable\"],[[\"disabled\"],[true]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"numero\",\"Número actual\"],[[\"placeholder\"],[\"0\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"anchoInches\",\"Ancho en pulgadas\"],[[\"placeholder\"],[\"0\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"largoInches\",\"Largo en pulgadas\"],[[\"placeholder\"],[\"0\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"usaCnt\",\"Afecta la contabilidad general\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"correlativoAutomatico\",\"Correlativo automático\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"correlativoAnioMes\",\"Asignar formato de correlativo Año-Mes\"],[[\"labelSide\"],[\"right\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/tipos-movimientos/tipo-documento/datos-generales.hbs"
    }
  });

  _exports.default = _default;
});