define("sirvo-web/models/gru-clasificacion", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codgrupo: attr('string'),
    codclasifica: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    observa: (0, _model.attr)('string'),
    codsistema: (0, _model.attr)('string'),
    tipo: (0, _model.attr)('string'),
    usaInventario: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esCliente: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esProveedor: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esVendedor: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaConversionMonedaExtranjera: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // codigoMonedaExtranjera: attr('string'),
    // fechacreacion: attr('date'),
    // fechabaja: attr('date'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    // Relationships
    grupo: (0, _model.belongsTo)('grupo'),
    stringify: Ember.computed.alias('codclasifica')
  });

  _exports.default = _default;
});