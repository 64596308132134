define("sirvo-web/templates/oca/ordenes-envio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m8AnfQjH",
    "block": "{\"symbols\":[\"button\"],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 py-4\"],[8],[0,\"\\n      \"],[6,\"h3\"],[8],[0,\"\\n        \"],[6,\"span\"],[10,\"class\",\"title\"],[8],[0,\"Ordenes de envío\"],[9],[0,\"\\n\\n        \"],[4,\"bs-button\",null,[[\"type\",\"classNames\",\"onClick\"],[\"warning\",\"pull-right\",[26,\"action\",[[21,0,[]],\"reloadTable\"],null]]],{\"statements\":[[0,\"\\n          \"],[6,\"i\"],[10,\"class\",\"fa fa-refresh\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[21,1,[\"isPending\"]]],null,{\"statements\":[[0,\"            \"],[6,\"span\"],[8],[0,\"Recargando...\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            Recargar\\n\"]],\"parameters\":[]}],[0,\"        \"]],\"parameters\":[1]},null],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"container-fluid px-4 py-4 table-container\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[1,[26,\"tables/simple-table\",null,[[\"columns\",\"model\",\"tableClassNames\"],[[21,0,[\"columns\"]],[21,0,[\"model\",\"ordenesEnvio\"]],\"ember-table thead-gray\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/oca/ordenes-envio.hbs"
    }
  });

  _exports.default = _default;
});