define("sirvo-web/models/empresa", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codempresa: (0, _model.attr)('string'),
    // codgrupo: attr('string'),
    // codpais: attr('string'),
    pais: (0, _model.belongsTo)('com-pais'),
    // coddepto: attr('string'),
    // codmuni: attr('string'),
    // codzona: attr('string'),
    codmoneda: (0, _model.attr)('string'),
    // codmonedaMe: attr('string'),
    nombreemp: (0, _model.attr)('string'),
    razonsocial: (0, _model.attr)('string'),
    obsemp: (0, _model.attr)('string'),
    direccionemp: (0, _model.attr)('string'),
    // direccionemp2: attr('string'),
    // direccionemp3: attr('string'),
    telefonoemp: (0, _model.attr)('string'),
    // telefonoemp2: attr('string'),
    // telefonoemp3: attr('string'),
    // faxemp: attr('string'),
    emailemp: (0, _model.attr)('string'),
    webpageemp: (0, _model.attr)('string'),
    nitemp: (0, _model.attr)('string'),
    // noregistroiva: attr('string'),
    // giroempresa: attr('string'),
    // tipocontri: attr('string'),
    // catecontri: attr('string'),
    // esagenteretiva: attr('string'),
    // codretivaNormal: attr('string'),
    // codretivaPc: attr('string'),
    // pagaretiva: attr('string'),
    // codretiva: attr('string'),
    // usaexencioniva: attr('string'),
    // esagenteretisr: attr('string'),
    // pagaretisr: attr('string'),
    // codretisr: attr('string'),
    // regimenIsrPorcentaje: attr('string'),
    // usaimptimbreprensa: attr('string'),
    // mascaraNumericaDec: attr('string'),
    // mascaraNumericaInt: attr('string'),
    // logo: attr('string'),
    // anchologemp: attr('string'),
    // altologemp: attr('string'),
    // codadmon: attr('string'),
    // pathadmon: attr('string'),
    // pathcanon: attr('string'),
    // codigoEmpresaFactorh: attr('string'),
    // fechacreacion: attr('string'),
    // fechabaja: attr('string'),
    // fechafiscal: attr('string'),
    // coddirectorio: attr('string'),
    // tiporetisr: attr('string'),
    // nombreEstablecimiento: attr('string'),
    // codigoEstablecimiento: attr('string'),
    // intmoraFacturacion: attr('string'),
    // patenteComercio: attr('string'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    codigoNombre: Ember.computed('codempresa', 'nombreemp', function () {
      return [this.codempresa, this.nombreemp].filter(Boolean).join(' - ');
    }),
    // Relationships
    grupo: (0, _model.belongsTo)('grupo')
  });

  _exports.default = _default;
});