define("sirvo-web/routes/inventario/movimientos/ingresos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      return Ember.RSVP.hash({
        tiposDocumentosD: this.store.query('kar-doc', {
          filter: {
            deha: 'D'
          },
          include: 'tipoMovimiento'
        })
      }).then(({
        tiposDocumentosD
      }) => ({
        tiposMovimientos: tiposDocumentosD.mapBy('tipoMovimiento').uniqBy('id')
      }));
    },

    setupController(controller, model) {
      this._super(controller, model); // Mostrar por defecto los movimientos de INGRESOS.


      let tipoMovIngreso = model.tiposMovimientos.findBy('tipomov', 'INGRESOS');

      if (tipoMovIngreso) {
        controller.set('tipoMovimiento', tipoMovIngreso);
      } else {
        controller.set('tipoMovimiento', model.tiposMovimientos.get('firstObject'));
      }

      controller.set('reload', true);
    }

  });

  _exports.default = _default;
});