define("sirvo-web/controllers/dashboard/propiedades", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    loader: Ember.inject.service(),
    selectedEmpresa: null,
    selectedPropiedad: null,
    documentos: null,

    empresaUpdated() {
      this.setProperties({
        selectedPropiedad: null,
        documentos: []
      });

      if (!this.selectedEmpresa || !this.selectedEmpresa.get('id')) {
        return;
      }

      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        propiedades: this.store.query('ayr-propiedad', {
          filter: {
            codigoEmpresa: this.selectedEmpresa.get('codempresa')
          }
        })
      }).then(({
        propiedades
      }) => {
        Ember.set(this.model, 'propiedades', propiedades);
      }).finally(() => this.loader.setLoading(false));
    },

    propiedadUpdated() {
      if (!this.selectedPropiedad || !this.selectedPropiedad.get('id')) {
        return;
      }

      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        documentos: this.store.query('documento-propiedad', {
          filter: {
            codigoEmpresa: this.selectedEmpresa.get('codempresa'),
            codigoPropiedad: this.selectedPropiedad.get('codigoPropiedad')
          }
        })
      }).then(({
        documentos
      }) => {
        this.setProperties({
          documentos: documentos
        });
      }).finally(() => this.loader.setLoading(false));
    },

    actions: {
      empresaUpdated() {
        this.empresaUpdated();
      },

      propiedadUpdated() {
        this.propiedadUpdated();
      },

      descargarDocumento(id) {
        this.loader.setIsLoading();
        return this.request.get(`documento-propiedads/${id}`).then(() => {
          this.toast.success('Documento descargado correctamente');
        }).finally(() => this.loader.setLoading(false));
      }

    }
  });

  _exports.default = _default;
});