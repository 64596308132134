define("sirvo-web/controllers/admon/grupos/directorios-clasificaciones", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/gru-directorio-clasificacion"], function (_exports, _abstractModuleIndex, _gruDirectorioClasificacion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    loader: Ember.inject.service(),
    modelClass: _gruDirectorioClasificacion.default,
    selectedClasificacion: null,
    directorios: null,
    columns: Ember.computed(function () {
      return [{
        label: 'Código',
        valuePath: 'directorio.coddirectorio',
        filtrable: true,
        filterName: 'directorio.coddirectorio',
        width: '75px'
      }, {
        label: 'Clasificación',
        valuePath: 'clasificacion.codclasifica',
        filtrable: true,
        filterName: 'clasificacion',
        width: '120px'
      }, {
        label: 'Nit',
        valuePath: 'directorio.nit',
        filtrable: true,
        filterName: 'directorio.nit',
        width: '75px'
      }, {
        label: 'Nombre directorio',
        valuePath: 'directorio.nombredirectorio',
        filtrable: true,
        filterName: 'directorio.nombredirectorio',
        width: '150px'
      }, {
        label: 'Razón social',
        valuePath: 'directorio.razonsocial',
        filtrable: true,
        filterName: 'directorio.razonsocial',
        width: '150px'
      }, {
        label: 'Teléfono oficina',
        valuePath: 'directorio.telefonooficina',
        width: '75px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    })
  });

  _exports.default = _default;
});