define("sirvo-web/templates/components/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UL1dmdva",
    "block": "{\"symbols\":[\"menu\",\"@menu\"],\"statements\":[[4,\"if\",[[21,2,[\"children\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[6,\"a\"],[10,\"href\",\"#\"],[8],[0,\"\\n\"],[4,\"if\",[[21,2,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[6,\"i\"],[11,\"class\",[27,[\"menu-icon \",[21,2,[\"icon\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[6,\"span\"],[10,\"class\",\"menu-text\"],[8],[1,[26,\"or\",[[21,2,[\"title\"]],\"Undefined\"],null],false],[9],[0,\"\\n\\n    \"],[6,\"span\"],[10,\"class\",\"arrow fa fa-angle-right\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"ul\"],[10,\"class\",\"submenu\"],[10,\"type\",\"1\"],[8],[0,\"\\n\"],[4,\"each\",[[21,2,[\"children\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"menu-item\",null,[[\"menu\",\"nav\"],[[21,1,[]],[21,0,[\"nav\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[21,2,[\"route\"]]],null,{\"statements\":[[0,\"    \"],[4,\"link-to\",[[21,2,[\"route\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[21,2,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[11,\"class\",[27,[\"menu-icon \",[21,2,[\"icon\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[6,\"span\"],[10,\"class\",\"menu-text\"],[8],[1,[26,\"or\",[[21,2,[\"title\"]],\"Undefined\"],null],false],[9],[0,\"\\n    \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"a\"],[10,\"href\",\"#\"],[8],[0,\"\\n\"],[4,\"if\",[[21,2,[\"icon\"]]],null,{\"statements\":[[0,\"        \"],[6,\"i\"],[11,\"class\",[27,[\"menu-icon \",[21,2,[\"icon\"]]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[6,\"span\"],[10,\"class\",\"menu-text\"],[8],[1,[26,\"or\",[[21,2,[\"title\"]],\"Undefined\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/menu-item.hbs"
    }
  });

  _exports.default = _default;
});