define("sirvo-web/routes/inventario/tablas/tipos-movimientos/tipo-documento", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _hasTabs, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-doc',
    indexRoute: 'inventario.tablas.tipos-movimientos.record.tipo-documentos',
    routeRecordIdentifierDynamicSegment: 'id_documento',
    defaultTabSubRoute: 'datos-generales',
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)({
          presence: true
        }),
        sistema: (0, _validators.validatePresence)({
          presence: true
        }),
        tipoMovimiento: (0, _validators.validatePresence)({
          presence: true
        }),
        codtipo: (0, _validators.validatePresence)({
          presence: true
        }),
        deha: (0, _validators.validatePresence)({
          presence: true
        }),
        anchoInches: (0, _validators.validateNumber)({
          gte: 0
        }),
        largoInches: (0, _validators.validateNumber)({
          gte: 0
        }),
        numero: (0, _validators.validateNumber)({
          gte: 0
        })
      };
    }),

    model(params
    /*, transition*/
    ) {
      return this._super(...arguments).then(modelData => {
        return Ember.RSVP.hash({
          tipoMovimiento: this.store.findRecord('kar-tipomov', params.id)
        }).then(routeData => {
          if (modelData.record.get('isNew')) {
            modelData.record.set('tipoMovimiento', routeData.tipoMovimiento);
          }

          return Ember.merge(modelData, routeData);
        });
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});