define("sirvo-web/routes/inventario/tablas/tipos-movimientos/tipo-documento/cuentas-contables/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-doc-cuenta',
    indexRoute: 'inventario.tablas.tipos-movimientos.tipo-documento.cuentas-contables',
    routeRecordIdentifierDynamicSegment: 'cuenta_id',
    validations: Ember.computed(function () {
      return {
        tipoDocumento: (0, _validators.validatePresence)(true),
        periodo: (0, _validators.validatePresence)(true),
        cuenta: (0, _validators.validatePresence)(true)
      };
    }),
    relatedModelsToQuery: Ember.computed(function () {
      return [{
        name: 'periodos',
        modelName: 'emp-periodo',
        queryParams: {
          filter: {
            empresa: this.company.id,
            page_size: 5
          },
          sort: '-id'
        }
      }, {
        name: 'cuentasContables',
        presetValue: Ember.A([])
      }, {
        name: 'centrosAutorizacion',
        presetValue: Ember.A([])
      }, {
        name: 'centrosCosto',
        presetValue: Ember.A([])
      }, {
        name: 'centrosIntegracion',
        presetValue: Ember.A([])
      }];
    }),

    setupController(controller, model) {
      this._super(...arguments);

      if (model.record.get('isNew')) {
        let parentModel = this.modelFor('inventario.tablas.tipos-movimientos.tipo-documento');
        model.record.set('tipoDocumento', parentModel.record);
      }

      this.controller.updateCuentasContables();
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});