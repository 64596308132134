define("sirvo-web/controllers/facturacion/movimientos/emision-recibo/record/sucursal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      selectedSucursal(sucursal) {
        Ember.set(this.changeset, 'sucursal', sucursal.get('sucursal'));
      }

    }
  });

  _exports.default = _default;
});