define("sirvo-web/models/com-def-nomenclatura", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codnomencla: (0, _model.attr)('string') // nivelesnomencla: attr('number', { defaultValue: 0 }),
    // digitosnivelesnomencla: attr('string'),
    // mascaranomencla: attr('string'),
    // obsnomencla: attr('string'),
    // activo: attr('string'),
    // activoNiveles: attr('number', { defaultValue: 1 }),
    // pasivo: attr('string'),
    // pasivoNiveles: attr('number', { defaultValue: 1 }),
    // capital: attr('string'),
    // capitalNiveles: attr('number', { defaultValue: 1 }),
    // ingresos: attr('string'),
    // ingresosNiveles: attr('number', { defaultValue: 1 }),
    // egresos: attr('string'),
    // egresosNiveles: attr('number', { defaultValue: 1 }),
    // costos: attr('string'),
    // costosNiveles: attr('number', { defaultValue: 1 }),
    // gastos: attr('string'),
    // gastosNiveles: attr('number', { defaultValue: 1 }),
    // otrosingresos: attr('string'),
    // otrosingresosNiveles: attr('number', { defaultValue: 1 }),
    // otrosegresos: attr('string'),
    // otrosegresosNiveles: attr('number', { defaultValue: 1 }),
    // regularizacionactivo: attr('string'),
    // regularizacionactivoNiveles: attr('number', { defaultValue: 1 }),
    // regularizacionpasivo: attr('string'),
    // regularizacionpasivoNiveles: attr('number', { defaultValue: 1 }),
    // ordendeudoras: attr('string'),
    // ordendeudorasNiveles: attr('number', { defaultValue: 1 }),
    // ordenacreedoras: attr('string'),
    // ordenacreedorasNiveles: attr('number', { defaultValue: 1 }),
    // noUsarCtasParaMovs: attr('number', { defaultValue: 0 }),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});