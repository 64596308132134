define("sirvo-web/controllers/facturacion/movimientos/emision-recibo/record/directorio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      selectedDirectorio(directorio) {
        this.store.query('gru-directorio-clasificacion', {
          filter: {
            directorio: directorio.get('id'),
            grupo: this.company.get('grupo.id')
          }
        }).then(clasificaciones => {
          Ember.set(this, 'clasificaciones', clasificaciones);
        });
        this.store.findRecord('com-pais', directorio.get('pais.id')).then(pais => {
          Ember.set(this.changeset, 'codpais', pais.get('codpais'));
        });
        Ember.set(this.changeset, 'coddirectorio', directorio.get('coddirectorio'));
        Ember.set(this.changeset, 'nombre', directorio.get('nombredirectorio'));
        Ember.set(this.changeset, 'nit', directorio.get('nit'));
        Ember.set(this.changeset, 'direccion', directorio.get('direccion'));
        Ember.set(this.changeset, 'envioCorreos', directorio.get('email'));
      },

      selectedClasificacion(clasificacionDirectorio) {
        Ember.set(this.changeset, 'codclasifica', clasificacionDirectorio.get('clasificacion.codclasifica'));
      }

    }
  });

  _exports.default = _default;
});