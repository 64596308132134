define("sirvo-web/routes/facturacion/movimientos/emision-recibo/record/banco", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let modelData = this._super(...arguments);

      let changeset = this.controllerFor(this.parentRoute).get('changeset');

      if (!changeset) {
        return this.transitionTo(this.parentRoute);
      }

      return Ember.RSVP.hash({
        bancos: this.store.query('banco', {
          filter: {
            empresa: this.company.get('id'),
            fecinactivo: 'NULL',
            codtipocta: ['DM', 'AH'],
            page_enabled: false
          }
        }),
        tasaCambio: this.request.get('tasa-cambio')
      }).then(routeData => Ember.merge(modelData, routeData));
    }

  });

  _exports.default = _default;
});