define("sirvo-web/models/kar-costo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    correlativo: (0, _model.attr)('string'),
    keytra: (0, _model.attr)('string'),
    fecha: (0, _model.attr)('date'),
    // codbodega: attr('string'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    // codarticulo: attr('string'),
    articulo: (0, _model.belongsTo)('kar-articulo'),
    existencia: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorPromedio: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    valorTotal: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    existenciaInicial: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    existenciaFisico: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    numeroInicioNotasIngreso: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    numeroFinNotasIngreso: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    numeroInicioNotasDespacho: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    numeroFinNotasDespacho: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    temperatura: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    nivelMedidoEnCentimetros: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tipoDeMedicionPorFondo: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    tipoDeControl: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    densidad: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('string'),
    codusr: (0, _model.attr)('string')
  });

  _exports.default = _default;
});