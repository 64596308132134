define("sirvo-web/routes/inventario/procesos/generar-polizas-contables", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)(true),
        year: (0, _validators.validatePresence)(true),
        month: (0, _validators.validatePresence)(true)
      };
    }),

    model() {
      return {};
    },

    setupController(controller
    /*, model*/
    ) {
      let now = new Date();
      const parametersModel = new Ember.Object({
        empresa: this.company,
        year: now.getFullYear(),
        month: String(now.getMonth()),
        day: now.getDate()
      });
      controller.set('changeset', new _emberChangeset.default(parametersModel, (0, _emberChangesetValidations.default)(this.validations), this.validations));
    }

  });

  _exports.default = _default;
});