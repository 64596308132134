define("sirvo-web/components/edit-field-shortcut", ["exports", "ember-keyboard"], function (_exports, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    propertyNameActionMap: null,

    init() {
      this._super(...arguments);

      this.set('keyboardActivated', true);
    },

    onAltKeyQ: Ember.on((0, _emberKeyboard.keyUp)('F2'), function () {
      if (!Array.isArray(this.propertyNameActionMap)) {
        return;
      }

      let data = document.activeElement.dataset;
      let propertyName = data ? data.propertyName : null;

      if (!propertyName) {
        return;
      }

      let editAction = this.propertyNameActionMap.find(map => {
        if (map.propertyName && map.propertyName === propertyName) {
          return true;
        }

        if (Array.isArray(map.propertyNames) && map.propertyNames.includes(propertyName)) {
          return true;
        }

        return false;
      });

      if (editAction && editAction.action) {
        editAction.action();
      }
    })
  });

  _exports.default = _default;
});