define("sirvo-web/models/kar-parametro", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    codigoArticulosGen: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // usaCodificacionArticulos: attr('number', { defaultValue: 0 }),
    usaAutorizacionOrdenesC: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // usaOrdendTrabajo: attr('number', { defaultValue: 0 }),
    usaPermisosReq: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // usaCtaCostoPor: attr('number', { defaultValue: 0 }),
    usaRequisiciones: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    usaInventarioProcesoNocontable: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    validarNombreArticulo: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    validarExistenciasSalidas: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    // codigoprocedimiento: attr('string'),
    nombreLote: (0, _model.attr)('string'),
    caracteristica1: (0, _model.attr)('string'),
    caracteristica2: (0, _model.attr)('string'),
    caracteristica3: (0, _model.attr)('string'),
    caracteristica4: (0, _model.attr)('string'),
    caracteristica5: (0, _model.attr)('string'),
    obligatorio1: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    obligatorio2: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    obligatorio3: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    obligatorio4: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    obligatorio5: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // usaLote: attr('number', { defaultValue: 0 }),
    usaProcesoImportaciones: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaInventarioProceso: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    fechaOperaciones: (0, _model.attr)('date'),
    // porcentajeMinGanancia: attr('number'),
    // porcentajeMaxGanancia: attr('number'),
    // porcentajeMaxDescuento: attr('number'),
    decimalesCostos: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    decimalesCantidades: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // nomformatoOrdenCompra: attr('string'),
    // nomformatoRequisicion: attr('string'),
    // nomformatoEnvio: attr('string'),
    // nomformatoImportacion: attr('string'),
    // nombreBaseDatosOca: attr('string'),
    // codperiodo: attr('string'),
    periodo: (0, _model.belongsTo)('emp-periodo'),
    cuentaDai: (0, _model.attr)('string'),
    cuentaGasto: (0, _model.attr)('string'),
    // fechaNotificacionCorreo: attr('date'),
    notificacionCorreoMinimos: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    activarCasillaCuentaContableCadaKarMovdet: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaControlPorUbicaciones: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa')
  });

  _exports.default = _default;
});