define("sirvo-web/models/iva-sucursal", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    sucursal: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    nombre: (0, _model.attr)('string'),
    razonsocial: (0, _model.attr)('string'),
    direccion: (0, _model.attr)('string'),
    telefono: (0, _model.attr)('string'),
    telefonoFax: (0, _model.attr)('string'),
    emailemp: (0, _model.attr)('string'),
    patenteComercio: (0, _model.attr)('string'),
    observa: (0, _model.attr)('string'),
    usaCompras: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaVentas: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    mbcatalogo: (0, _model.attr)('string'),
    fechaBaja: (0, _model.attr)('string') // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});