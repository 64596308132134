define("sirvo-web/models/emp-parametros-generales", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    fechaCierreCnt: (0, _model.attr)('custom-date'),
    fechaCierreLogico: (0, _model.attr)('custom-date'),
    kardexTipoDeCierre: (0, _model.attr)('number'),
    kardexTipoDeContabilizacion: (0, _model.attr)('number'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa'),
    usaCierreMensual: Ember.computed('kardexTipoDeCierre', function () {
      return this.kardexTipoDeCierre === 1;
    }),
    usaCierrePorFecha: Ember.computed('kardexTipoDeCierre', function () {
      return this.kardexTipoDeCierre === 2;
    })
  });

  _exports.default = _default;
});