define("sirvo-web/templates/alquileres-renta/maestros/propiedades/record/documentos/agregar-documento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S/k/cacW",
    "block": "{\"symbols\":[\"modal\",\"f\"],\"statements\":[[4,\"bs-modal\",null,[[\"size\",\"onHide\"],[\"md\",[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Agregar documento\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"extended-form-for\",[[21,0,[\"documentoChangeset\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"component\",[[21,2,[\"ace-text-field\"]],\"descripcion\",\"Descripción\"],[[\"placeholder\"],[\"Descripción\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"forms/fields/ace-file-uploader\",null,[[\"label\",\"files\",\"filesModel\",\"divClasses\",\"inputClasses\",\"labelClasses\"],[\"Archivo:\",[21,0,[\"documentoChangeset\",\"archivo\"]],[21,0,[\"documentoChangeset\"]],\"w-100 mr-2\",\"form-control\",\"w-100\"]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"Cancelar\"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"cargarDocumento\"],null]]]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/alquileres-renta/maestros/propiedades/record/documentos/agregar-documento.hbs"
    }
  });

  _exports.default = _default;
});