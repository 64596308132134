define("sirvo-web/routes/inventario/tablas/articulos/record/datos-generales/asignar-bodega", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'inventario.tablas.articulos.record.datos-generales',

    model() {
      let modelData = this._super(...arguments);

      let promises = {
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        }),
        articuloBodegas: this.store.query('kar-bodega-det', {
          filter: {
            articulo: modelData.record.id
          }
        })
      };
      return Ember.RSVP.hash(promises).then(routeData => Ember.merge(modelData, routeData));
    },

    setupController(controller, model) {
      this._super(...arguments); // Update in case there were changes in Sirvo.


      model.record.set('bodegas', model.articuloBodegas);
      controller.setProperties(Ember.merge(model, {
        changeset: this.controllerFor(this.indexRoute).changeset,
        bodegasProxy: new Ember.Object({
          bodegas: model.articuloBodegas.mapBy('bodega.content')
        })
      }));
    }

  });

  _exports.default = _default;
});