define("sirvo-web/routes/oca/ordenes-compra/procesar", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kardex: Ember.inject.service(),
    ocaHelper: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    indexRoute: 'oca.ordenes-compra',
    validations: Ember.computed(function () {
      return {
        codigo: (0, _validators.validatePresence)(true),
        ordenCompraId: (0, _validators.validatePresence)(true),
        empresa: (0, _validators.validatePresence)(true),
        fecha: (0, _validators.validatePresence)(true),
        serie: (0, _validators.validatePresence)(true),
        numero: (0, _validators.validatePresence)(true)
      };
    }),
    lineaValidations: Ember.computed(function () {
      return {
        ordenCompra: (0, _validators.validatePresence)(true),
        articulo: (0, _validators.validatePresence)(true),
        bodega: (0, _validators.validatePresence)(true),
        unidadMedida: (0, _validators.validatePresence)(true),
        cantidad: [(0, _validators.validatePresence)(true), // WARNING: This validation doesn't work
        (0, _validators.validateLength)({
          min: 1
        })],
        subtotal: (0, _validators.validatePresence)(true),
        iva: (0, _validators.validatePresence)(true),
        total: (0, _validators.validatePresence)(true)
      };
    }),

    model()
    /*params, transition*/
    {
      // Not allowed to pass just an ID, an object should be passed.
      return this.transitionTo(this.indexRoute);
    },

    setupController(controller, model) {
      this._super(...arguments);

      if (!model.get('lineas.length')) {
        this.toast.error('Orden de compra sin líneas.');
        return this.transitionTo(this.indexRoute);
      }

      let record = this.store.createRecord('post-oca-orden-compra', {
        codigo: model.get('orden_compra_codigo'),
        ordenCompraId: model.get('orden_compra_id'),
        empresa: this.company,
        fecha: new Date(),
        // Ignoramos la fecha de la orden
        serie: model.get('serie'),
        numero: model.get('numero'),
        proveedor: model.get('proveedor_codigo'),
        proveedorNombre: model.get('proveedor_nombre'),
        justificacion: model.get('justificacion'),
        cuentaContable: model.get('proyecto_renglon_cuenta_contable'),
        centroCosto: model.get('centro_prespuesto_codigo'),
        centroIntegracion: model.get('centro_integracion_codigo'),
        centroActividad: model.get('centro_actividad_codigo')
      });
      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(this.validations), this.validations);
      controller.setProperties({
        record,
        changeset,
        lineasChangesets: Ember.A([]),
        lineasData: Ember.A([])
      }); // WARNING: Se dejaron los queries aquí intencionalmente, ya que los hooks del
      // model nunca se llaman porque a la ruta se le pasa un objeto completo (no ID).

      let codArticuloQuery = model.get('lineas').mapBy('producto_codigo'); // WARNING: El backend truena cuando se le manda un array de un solo item.

      if (codArticuloQuery.length === 1) {
        codArticuloQuery = {
          exact: codArticuloQuery[0]
        };
      }

      this.loader.setLoading(true);
      Ember.RSVP.hash({
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        }),
        articulos: this.store.query('kar-articulo', {
          filter: {
            empresa: this.company.id,
            codarticulo: codArticuloQuery
          }
        }),
        tiposUbicaciones: this.store.query('kar-articulo-tipo-ubicacion', {
          filter: {
            empresa: this.company.id
          }
        })
      }).then(({
        bodegas,
        articulos,
        tiposUbicaciones
      }) => {
        controller.set('bodegas', bodegas);
        controller.set('tiposUbicaciones', tiposUbicaciones);
        let ordenLinea = null; // WARNING: Se usa un for nativo para hacer break cuando el artículo no existe.

        for (let i = 0; i < model.get('lineas').length; i++) {
          ordenLinea = model.get('lineas')[i];
          let articulo = articulos.findBy('codarticulo', ordenLinea.get('producto_codigo'));

          if (!articulo) {
            this.toast.error(`No se encontró el artículo "${ordenLinea.get('producto_codigo')}".`);
            controller.discardChanges();
            return this.transitionTo(this.indexRoute);
          }

          let subtotal = ordenLinea.get('subtotal') || 0;
          let iva = ordenLinea.get('iva') || 0;
          let linea = this.store.createRecord('post-oca-orden-compra-linea', {
            ordenCompra: record,
            articulo: articulo,
            cantidad: ordenLinea.get('cantidad'),
            subtotal,
            iva,
            total: ordenLinea.get('total') || subtotal + iva,
            // Mockear el movimiento, solo para armar el query de unidades de medida.
            movimiento: {
              deha: 'D'
            }
          });
          let lineaChangeset = new _emberChangeset.default(linea, (0, _emberChangesetValidations.default)(this.lineaValidations), this.lineaValidations);
          controller.lineasChangesets.pushObject(lineaChangeset);
          let lineaData = {
            linea,
            lineaChangeset,
            unidadesMedida: Ember.A([]),
            ubicacionesChangesets: this.ocaHelper.createUbicacionesChangesets(tiposUbicaciones),
            ubicacionesMeta: {}
          };
          tiposUbicaciones.forEach(tipoUbicacion => {
            lineaData.ubicacionesMeta[tipoUbicacion.id] = {
              tipoUbicacion,
              ubicaciones: Ember.A([])
            };
          });
          controller.lineasData.pushObject(lineaData);
          this.kardex.onChangesetArticuloUpdated(lineaChangeset, {
            codigoUnidadMedida: ordenLinea.get('unidad_medida_codigo')
          }).then(articuloData => {
            Ember.set(lineaData, 'unidadesMedida', articuloData.uMedidaBaseEquivalencias); // Procesar las ubicaciones por defecto del artículo.

            let localizacionesByBodega = this.kardex.groupArticuloUbicacionesByBodega(articuloData.localizaciones);
            let firstLocalizacion = localizacionesByBodega.get('firstObject');

            if (!firstLocalizacion) {
              // Setear la bodega a null solo si ya tiene valor.
              if (lineaChangeset.get('bodega.id')) {
                lineaChangeset.set('bodega', null);
                this.controller.actions.bodegaUpdated.apply(this.controller, [lineaData]);
              }

              return;
            } // Setear la bodega por defecto.


            lineaChangeset.set('bodega', firstLocalizacion.ubicacionBodega.get('bodega'));
            this.controller.actions.bodegaUpdated.apply(this.controller, [lineaData]); // Setear las ubicaciones por defecto.

            lineaData.ubicacionesChangesets.forEach(ubicacionChangeset => {
              let localizacion = firstLocalizacion.ubicaciones.findBy('tipoUbicacion.id', ubicacionChangeset.get('tipoUbicacion.id'));

              if (localizacion) {
                ubicacionChangeset.set('articuloUbicacion', localizacion.get('articuloUbicacion'));
                this.controller.actions.ubicacionUpdated.apply(this.controller, [lineaData, ubicacionChangeset, localizacion.get('articuloUbicacion')]);
              }
            });
          });
        }
      }).finally(() => this.loader.setLoading(false));
    }

  });

  _exports.default = _default;
});