define("sirvo-web/templates/cambiar-empresa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pshriItf",
    "block": "{\"symbols\":[\"modal\",\"empresa\"],\"statements\":[[4,\"bs-modal\",null,[[\"onSubmit\",\"onHide\"],[[26,\"action\",[[21,0,[]],\"cambiarEmpresa\"],null],[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Cambiar empresa\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"form-group row\"],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"col-sm-12\"],[8],[0,\"\\n        \"],[4,\"power-select\",null,[[\"selected\",\"options\",\"onchange\"],[[21,0,[\"selectedEmpresa\"]],[21,0,[\"model\",\"empresas\"]],[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"selectedEmpresa\"]]],null]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[21,2,[\"codempresa\"]],false],[0,\" - \"],[1,[21,2,[\"nombreemp\"]],false],[0,\"\\n        \"]],\"parameters\":[2]},null],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"outline\",\"type\",\"onClick\"],[\"sm\",true,\"secondary\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n      Cancelar\\n    \"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"type\",\"onClick\"],[\"sm\",\"primary\",[26,\"action\",[[21,0,[]],[21,1,[\"submit\"]]],null]]],{\"statements\":[[0,\"\\n      Aceptar\\n    \"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/cambiar-empresa.hbs"
    }
  });

  _exports.default = _default;
});