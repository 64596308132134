define("sirvo-web/components/kar/mov-det-ubicacion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ubicacionesValores: null,
    ubicacionesMeta: null,
    formClassNames: '',
    onUpdate: () => true,
    actions: {
      onUpdate(ubicacionChangeset, articuloUbicacion) {
        this.onUpdate(ubicacionChangeset, articuloUbicacion);
      }

    }
  });

  _exports.default = _default;
});