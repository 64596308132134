define("sirvo-web/components/form-fields/select-field", ["exports", "ember-form-for/components/form-fields/select-field"], function (_exports, _selectField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _selectField.default;
    }
  });
});