define("sirvo-web/controllers/inventario/movimientos/ingresos/record/carga-detalles", ["exports", "sirvo-web/mixins/uploader", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _uploader, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_uploader.default, {
    request: Ember.inject.service(),
    parentCtrl: Ember.inject.controller('inventario.movimientos.ingresos.record'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),
    data: null,
    fileChangeset: null,
    fileValidations: Ember.computed(function () {
      return {
        archivo: (0, _validators.validatePresence)({
          presence: true,
          message: 'Ningún archivo seleccionado'
        }),
        type: (0, _validators.validateInclusion)({
          list: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
          message: 'Archivo inválido'
        })
      };
    }),

    newFileChangeset() {
      let changeset = new _emberChangeset.default(new Ember.Object({
        archivo: null
      }), (0, _emberChangesetValidations.default)(this.get('fileValidations')), this.get('fileValidations'));
      this.set('fileChangeset', changeset);
    },

    actions: {
      filesUpdated(file) {
        // File input cleared?
        if (!file) {
          this.set('data', []);
        }
      },

      descargarPlantilla() {
        if (this.record.get('isNew')) {
          return;
        }

        this.loader.setLoading(true);
        return this.request.get(`kar-movs/${this.record.id}/carga-detalles?getTemplate=1`).finally(() => this.loader.setLoading(false));
      },

      cargarArchivo(save = false) {
        if (this.registroCerrado) {
          this.toast.error('Registro solo de lectura por cierre de inventario.');
          return;
        }

        this.set('uploadUrl', `kar-movs/${this.record.id}/carga-detalles`);
        return this.fileChangeset.validate().then(() => {
          if (!this.fileChangeset.get('isValid')) {
            this.toast.error('Debe adjuntar un archivo válido.');
            return;
          }

          let uploader = this._getNewUploader();

          this.loader.setLoading(true);
          return uploader.upload(this.fileChangeset.get('archivo'), {
            save: save ? 1 : 0
          }).then(data => {
            this.set('data', data);

            if (data.processed) {
              this.toast.success('Datos guardados correctamente');
              this.newFileChangeset();
              this.set('data', []);
              this.parentCtrl.reloadItems();
            } else {
              this.toast.success('Archivo validado correctamente');
            }
          }).catch(error => {
            this.toast.error('Ocurrió un error durante la carga. Revise los errores.');

            if (error.responseJSON && error.responseJSON.data) {
              this.set('data', error.responseJSON);
            }

            throw error;
          }).finally(() => {
            // Because in each upload a new one is instantiated.
            uploader.destroy();
            this.loader.setLoading(false);
          });
        });
      }

    }
  });

  _exports.default = _default;
});