define("sirvo-web/models/kar-tabla-medida", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codmedida: (0, _model.attr)('string'),
    cantidad: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    compra: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    venta: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa'),
    articulo: (0, _model.belongsTo)('kar-articulo', {
      inverse: 'unidadesMedida'
    }),
    unidadMedida: (0, _model.belongsTo)('kar-unidad-medida')
  });

  _exports.default = _default;
});