define("sirvo-web/controllers/admon/maestros/empresas", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/empresa"], function (_exports, _abstractModuleIndex, _empresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _empresa.default,
    columns: Ember.computed(function () {
      return [{
        label: 'Código',
        valuePath: 'codempresa',
        filtrable: true,
        filterName: 'codempresa',
        width: '50px'
      }, {
        label: 'Grupo',
        valuePath: 'grupo.codgrupo',
        filtrable: true,
        filterName: 'grupo.codgrupo',
        width: '50px'
      }, {
        label: 'Nombre empresa',
        valuePath: 'nombreemp',
        filtrable: true,
        filterName: 'nombreemp',
        width: '100px'
      }, {
        label: 'Razón social',
        valuePath: 'razonsocial',
        filtrable: true,
        filterName: 'razonsocial',
        width: '150px'
      }, {
        label: 'Dirección',
        valuePath: 'direccionemp',
        width: '200px',
        sortable: false
      }, {
        label: 'Teléfono',
        valuePath: 'telefonoemp',
        width: '75px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    })
  });

  _exports.default = _default;
});