define("sirvo-web/controllers/inventario/tablas/tipos-movimientos/record/tipo-documentos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-doc"], function (_exports, _abstractModuleIndex, _karDoc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    editRoute: 'inventario.tablas.tipos-movimientos.tipo-documento',
    modelClass: _karDoc.default,
    reload: true,
    tipoMovimiento: null,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'empresa'
        },
        value: this.company.get('id')
      }, {
        selectedFilter: {
          filterName: 'tipoMovimiento'
        },
        value: this.record.get('isNew') ? -1 : this.record.id
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Tipo',
        valuePath: 'codtipo',
        width: '100px',
        sortable: false
      }, {
        label: 'Naturaleza del movimiento',
        valuePath: 'deha',
        width: '100px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      }];
    }),

    reloadTable() {
      this.set('reload', true);
    },

    actions: {
      edit(tipoDocumento) {
        if (!this.record.id || !tipoDocumento.id) {
          return;
        }

        this.transitionToRoute(this.editRoute, this.record.id, tipoDocumento.id);
      }

    }
  });

  _exports.default = _default;
});