define("sirvo-web/services/oca-helper", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "sirvo-web/models/empresa"], function (_exports, _emberChangeset, _emberChangesetValidations, _validators, _empresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    request: Ember.inject.service(),
    store: Ember.inject.service(),
    loader: Ember.inject.service(),

    getOrdenesCompra(empresa) {
      (true && !(empresa instanceof _empresa.default) && Ember.assert('Expected an instance of Empresa.', empresa instanceof _empresa.default));
      this.loader.setLoading(true);
      return this.request.get('oca/ordenes-compra', {
        codigoEmpresa: empresa.get('codempresa')
      }).then(ordenesCompra => {
        let result = {}; // Agrupar por orden de compra ya que pueden haber varias líneas para una
        // misma orden.

        ordenesCompra.forEach(ordenLinea => {
          if (!result[ordenLinea.orden_compra_id]) {
            result[ordenLinea.orden_compra_id] = new Ember.Object({ ...ordenLinea,
              lineas: Ember.A([])
            });
          }

          result[ordenLinea.orden_compra_id].lineas.push(new Ember.Object({
            producto_codigo: ordenLinea.producto_codigo,
            nombre_producto: ordenLinea.nombre_producto,
            cantidad: ordenLinea.cantidad,
            unidad_medida_codigo: ordenLinea.unidad_medida_codigo,
            subtotal: ordenLinea.subtotal,
            iva: ordenLinea.iva,
            total: ordenLinea.total
          }));
        });
        return Object.values(result);
      }).finally(() => this.loader.setLoading(false));
    },

    getOrdenesDespacho(empresa, tipoProceso) {
      (true && !(empresa instanceof _empresa.default) && Ember.assert('Expected an instance of Empresa.', empresa instanceof _empresa.default));
      this.loader.setLoading(true);
      return this.request.get('oca/ordenes-despacho', {
        codigoEmpresa: empresa.get('codempresa'),
        tipoProceso: tipoProceso || null
      }).then(ordenesDespacho => {
        let result = {}; // Agrupar por orden de despacho ya que pueden haber varias líneas para una
        // misma orden.

        ordenesDespacho.forEach(ordenLinea => {
          if (!result[ordenLinea.orden_despacho_id]) {
            result[ordenLinea.orden_despacho_id] = new Ember.Object({ ...ordenLinea,
              lineas: Ember.A([])
            });
          }

          result[ordenLinea.orden_despacho_id].lineas.push(new Ember.Object({
            cantidad: ordenLinea.cantidad,
            nombre_unidad_medida: ordenLinea.nombre_unidad_medida,
            requisicion_linea: ordenLinea.requisicion_linea,
            producto_codigo_bodega: ordenLinea.producto_codigo_bodega,
            producto_codigo: ordenLinea.producto_codigo,
            unidad_medida_codigo: ordenLinea.unidad_medida_codigo
          }));
        });
        return Object.values(result);
      }).finally(() => this.loader.setLoading(false));
    },

    getOrdenesEnvio(empresa) {
      (true && !(empresa instanceof _empresa.default) && Ember.assert('Expected an instance of Empresa.', empresa instanceof _empresa.default));
      this.loader.setLoading(true);
      return this.request.get('oca/ordenes-envio', {
        codigoEmpresa: empresa.get('codempresa')
      }).then(ordenesEnvio => {
        let result = {}; // Un envío puede tener múltiples líneas y cada línea puede pertencer a una
        // orden de compra diferente. Así que, agrupar por envío y por orden de compra.

        ordenesEnvio.forEach(ordenLinea => {
          if (!result[ordenLinea.envio_codigo]) {
            result[ordenLinea.envio_codigo] = new Ember.Object({
              id: ordenLinea.envio_id,
              envio_codigo: ordenLinea.envio_codigo,
              ordenes: new Ember.Object({})
            });
          }

          if (!result[ordenLinea.envio_codigo].ordenes[ordenLinea.orden_compra_id]) {
            result[ordenLinea.envio_codigo].ordenes[ordenLinea.orden_compra_id] = new Ember.Object({ ...ordenLinea,
              lineas: Ember.A([])
            });
          }

          result[ordenLinea.envio_codigo].ordenes[ordenLinea.orden_compra_id].lineas.push(new Ember.Object({
            producto_codigo: ordenLinea.producto_codigo,
            nombre_producto: ordenLinea.nombre_producto,
            cantidad: ordenLinea.cantidad,
            unidad_medida_codigo: ordenLinea.unidad_medida_codigo,
            subtotal: ordenLinea.subtotal
          }));
        });
        result = Object.values(result);
        result.forEach(envio => envio.ordenes = Object.values(envio.ordenes));
        return result;
      }).finally(() => this.loader.setLoading(false));
    },

    createUbicacionChangeset(ubicacionObj) {
      let validations = {
        articuloUbicacion: (0, _validators.validatePresence)(true),
        tipoUbicacion: (0, _validators.validatePresence)(true)
      };
      return new _emberChangeset.default(ubicacionObj, (0, _emberChangesetValidations.default)(validations), validations);
    },

    createUbicacionesChangesets(tiposUbicaciones) {
      return tiposUbicaciones.map(tipoUbicacion => this.createUbicacionChangeset(new Ember.Object({
        tipoUbicacion,
        articuloUbicacion: null
      })));
    }

  });

  _exports.default = _default;
});