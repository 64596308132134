define("sirvo-web/routes/inventario/movimientos/ingresos/registro", ["exports", "servir-ember-utilities/routes/abstract-module-record", "servir-ember-utilities/mixins/route/has-tabs", "sirvo-web/validations/kar-mov"], function (_exports, _abstractModuleRecord, _hasTabs, _karMov) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend(_hasTabs.default, {
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.ingresos',
    defaultTabSubRoute: 'movimiento',
    validations: _karMov.default,
    relatedModelsToQuery: Ember.computed('isNew', function () {
      let relatedModels = [];

      if (this.isNew) {
        relatedModels.push({
          name: 'detalles',
          presetValue: Ember.A([])
        });
      } else {
        relatedModels.push({
          name: 'detalles',
          modelName: 'kar-movdet',
          queryParams: {
            filter: {
              movimiento: this.recordId
            },
            include: 'bodega,articulo,unidadMedida'
          }
        });
      }

      return relatedModels;
    }),

    model(params
    /*, transition*/
    ) {
      if (params.id === 'new') {
        this.toast.error('Esta ruta solo es para visualizar movimientos.');
        return this.transitionTo(this.indexRoute);
      }

      return this._super(...arguments).then(modelData => {
        if (modelData.record.get('isNew')) {
          return modelData;
        }

        return this.request.get(`kar-movs/${modelData.record.id}/poliza`).then(poliza => {
          return { ...modelData,
            poliza
          };
        });
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      if (this.fechaCierre >= model.record.get('fecha')) {
        this.toast.warning('Registro solo de lectura por cierre de inventario.');
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});