define("sirvo-web/controllers/dashboard/resultados", ["exports", "sirvo-web/utils/format"], function (_exports, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    request: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),
    currentYear: null,
    selectedEmpresa: null,
    resultadosData: null,
    resultados: null,
    selectedRow: null,
    chartData: Ember.computed('selectedEmpresa', 'resultados', 'selectedRow', function () {
      let saldos = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      if (this.selectedEmpresa && this.resultados && this.resultados.length) {
        saldos = this.resultados.map(res => res.saldo || 0);
      }

      if (this.selectedRow) {
        saldos = this.selectedRow.detalles.map(det => det.saldo || 0);
      }

      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [{
          label: 'Saldos',
          backgroundColor: '#628bd4',
          data: saldos.slice(0, 12)
        }]
      };
    }),
    chartOptions: Ember.computed('selectedEmpresa', 'resultados', 'selectedRow', function () {
      let title = 'Ningún empresa seleccionada';

      if (this.selectedEmpresa) {
        // Computed property cannot be used because it is not an `empresa` model.
        title = `${this.selectedEmpresa.codempresa} - ${this.selectedEmpresa.nombreemp}`;
      }

      if (this.selectedRow) {
        title = this.selectedRow.nombreCuenta;
      }

      return {
        responsive: true,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          text: title,
          fontSize: 16
        }
      };
    }),

    changeYear(selectedYear) {
      if (selectedYear === this.currentYear) {
        return;
      }

      this.set('currentYear', selectedYear);
      this.empresaUpdated();
    },

    empresaUpdated() {
      this.setProperties({
        resultados: [],
        resultadosData: [],
        selectedRow: null
      });

      if (!this.selectedEmpresa || !this.selectedEmpresa.get('id')) {
        return;
      }

      let empresaId = this.selectedEmpresa.get('id');
      this.request.get('dashboard/resultados', {
        empresaId,
        anio: this.currentYear
      }).then(resultadosData => {
        let transformedData = this.processor.transformParqueosData(resultadosData);
        let resultados = this.processor.getInitializedObjectsByMonth({
          ingresos: 0,
          egresos: 0,
          saldo: 0
        });
        transformedData.forEach(resumen => {
          resumen.detalles.forEach((detalle, index) => {
            if (resumen.codcuenta.charAt(0) === '4') {
              resultados[index].ingresos += detalle.saldo;
            } else {
              resultados[index].egresos += detalle.saldo;
            }
          });
        });
        resultados = Object.values(resultados);
        resultados.forEach(res => res.saldo = (0, _format.round)(res.ingresos - res.egresos));
        this.setProperties({
          resultados,
          resultadosData: transformedData
        });
      });
    },

    actions: {
      changeYear() {
        this.send('openModal', 'popups/select-year', {
          title: 'Cambiar año',
          message: null,
          currentYear: this.currentYear,
          onSubmit: this.changeYear.bind(this)
        });
      },

      empresaUpdated() {
        this.empresaUpdated();
      },

      onSelectRow(selectedRow) {
        this.set('selectedRow', selectedRow);
      }

    }
  });

  _exports.default = _default;
});