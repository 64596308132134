define("sirvo-web/services/data-processor", ["exports", "moment", "sirvo-web/utils/format", "sirvo-web/utils/date"], function (_exports, _moment, _format, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getInitializedObjectsByMonth(initialValues = {}) {
      return Object.keys(_date.monthNamesByIndex).reduce((result, mesIndex) => {
        result[mesIndex] = {
          mesIndex
        };
        Object.assign(result[mesIndex], initialValues);
        return result;
      }, {});
    },

    transformArrendamientosData(data) {
      let transformedData = data.map(({
        razonsocial,
        detalles
      }) => {
        let detallesByMonth = this.getInitializedObjectsByMonth({
          facturado: 0,
          cobrado: 0,
          pendiente: 0
        });
        detalles.forEach(detalle => {
          let fecha = detalle.fecha ? (0, _moment.default)(detalle.fecha) : null;
          let facturado = parseFloat(detalle.facturado);
          let cobrado = parseFloat(detalle.cobrado);

          if (!fecha || !fecha.isValid()) {
            return;
          }

          detallesByMonth[fecha.month()] = {
            mesIndex: fecha.month(),
            facturado: isNaN(facturado) ? 0 : facturado,
            cobrado: isNaN(cobrado) ? 0 : cobrado,
            pendiente: !isNaN(cobrado) && !isNaN(facturado) ? (0, _format.round)(facturado - cobrado) : 0
          };
        });
        let detallesTrans = Object.values(detallesByMonth);
        let totalFacturado = detallesTrans.reduce((sum, det) => sum + det.facturado, 0);
        let totalCobrado = detallesTrans.reduce((sum, det) => sum + det.cobrado, 0);
        return {
          razonsocial,
          totalFacturado,
          totalCobrado,
          totalPendiente: (0, _format.round)(totalFacturado - totalCobrado),
          detalles: detallesTrans
        };
      });
      return transformedData;
    },

    transformLocalesData(data) {
      let transformedData = data.map(({
        razonsocial,
        detalles
      }) => {
        let detallesByMonth = this.getInitializedObjectsByMonth({
          locales: 0,
          ocupados: 0,
          disponibles: 0,
          facturados: 0,
          esUltimoMesFacturado: false
        });
        detalles.forEach(detalle => {
          let locales = parseInt(detalle.locales);
          let ocupados = parseInt(detalle.ocupados);
          let mesIndex = parseInt(detalle.mes);
          let facturados = parseInt(detalle.facturados);

          if (isNaN(mesIndex) || mesIndex < 0 || mesIndex > 11) {
            return;
          }

          detallesByMonth[mesIndex] = {
            mesIndex,
            locales: isNaN(locales) ? 0 : locales,
            ocupados: isNaN(ocupados) ? 0 : ocupados,
            disponibles: !isNaN(ocupados) && !isNaN(locales) ? (0, _format.round)(locales - ocupados) : 0,
            facturados: isNaN(facturados) ? 0 : ocupados,
            esUltimoMesFacturado: detalle.es_ultimo_mes_facturado
          };
        });
        let detallesTrans = Object.values(detallesByMonth);
        let ultimoMesFacturado = detallesTrans.find(det => det.esUltimoMesFacturado);
        let totalLocales = ultimoMesFacturado.locales;
        let totalOcupados = ultimoMesFacturado.ocupados;
        return {
          razonsocial,
          totalLocales,
          totalOcupados,
          totalDisponibles: (0, _format.round)(totalLocales - totalOcupados),
          detalles: detallesTrans,
          ultimoMesFacturado
        };
      });
      return transformedData;
    },

    transformMetrosData(data) {
      let transformedData = data.map(({
        razonsocial,
        detalles
      }) => {
        let detallesByMonth = this.getInitializedObjectsByMonth({
          metros: 0,
          ocupados: 0,
          disponibles: 0,
          facturados: 0,
          esUltimoMesFacturado: false
        });
        detalles.forEach(detalle => {
          let metros = parseFloat(detalle.metros);
          let ocupados = parseFloat(detalle.ocupados);
          let mesIndex = parseInt(detalle.mes);
          let facturados = parseFloat(detalle.facturados);

          if (isNaN(mesIndex) || mesIndex < 0 || mesIndex > 11) {
            return;
          }

          detallesByMonth[mesIndex] = {
            mesIndex,
            metros: isNaN(metros) ? 0 : metros,
            ocupados: isNaN(ocupados) ? 0 : ocupados,
            disponibles: !isNaN(ocupados) && !isNaN(metros) ? (0, _format.round)(metros - ocupados, 4) : 0,
            facturados: isNaN(facturados) ? 0 : ocupados,
            esUltimoMesFacturado: detalle.es_ultimo_mes_facturado
          };
        });
        let detallesTrans = Object.values(detallesByMonth);
        let ultimoMesFacturado = detallesTrans.find(det => det.esUltimoMesFacturado);
        let totalMetros = ultimoMesFacturado.metros;
        let totalOcupados = ultimoMesFacturado.ocupados;
        return {
          razonsocial,
          totalMetros,
          totalOcupados,
          totalDisponibles: (0, _format.round)(totalMetros - totalOcupados, 4),
          detalles: detallesTrans,
          ultimoMesFacturado
        };
      });
      return transformedData;
    },

    prepareArrendamientosData(data) {
      let arrendamientosTransData = this.transformArrendamientosData(data);
      let arrendamientosTotals = this.computeGrandTotalsByMonth(arrendamientosTransData, ['facturado', 'cobrado', 'pendiente']);
      let arrendamientosTotalsData = Object.values(arrendamientosTotals);
      let arrendamientosTotalFacturado = arrendamientosTotalsData.reduce((sum, monthTotal) => sum + monthTotal.totalFacturado, 0);
      let arrendamientosTotalCobrado = arrendamientosTotalsData.reduce((sum, monthTotal) => sum + monthTotal.totalCobrado, 0);
      return {
        arrendamientosData: arrendamientosTransData,
        arrendamientosTotalFacturado,
        arrendamientosTotalCobrado,
        arrendamientosTotalPendiente: arrendamientosTotalFacturado - arrendamientosTotalCobrado,
        arrendamientosTotals: arrendamientosTotalsData
      };
    },

    prepareLocalesData(data) {
      let localesTransData = this.transformLocalesData(data);
      let localesTotals = this.computeGrandTotalsByMonth(localesTransData, ['locales', 'ocupados', 'disponibles']);
      let localesTotalsData = Object.values(localesTotals);
      let localesTotalLocales = localesTransData.reduce((sum, monthTotal) => sum + monthTotal.ultimoMesFacturado.locales, 0);
      let localesTotalOcupados = localesTransData.reduce((sum, monthTotal) => sum + monthTotal.ultimoMesFacturado.ocupados, 0);
      return {
        localesData: localesTransData,
        localesTotalLocales,
        localesTotalOcupados,
        localesTotalDisponibles: localesTotalLocales - localesTotalOcupados,
        localesTotals: localesTotalsData
      };
    },

    prepareMetrosData(data) {
      let metrosTransData = this.transformMetrosData(data);
      let metrosTotals = this.computeGrandTotalsByMonth(metrosTransData, ['metros', 'ocupados', 'disponibles'], 4);
      let metrosTotalsData = Object.values(metrosTotals);
      let metrosTotalMetros = (0, _format.round)(metrosTransData.reduce((sum, monthTotal) => sum + monthTotal.ultimoMesFacturado.metros, 0), 4);
      let metrosTotalOcupados = (0, _format.round)(metrosTransData.reduce((sum, monthTotal) => sum + monthTotal.ultimoMesFacturado.ocupados, 0), 4);
      return {
        metrosData: metrosTransData,
        metrosTotalMetros,
        metrosTotalOcupados,
        metrosTotalDisponibles: (0, _format.round)(metrosTotalMetros - metrosTotalOcupados, 4),
        metrosTotals: metrosTotalsData
      };
    },

    transformParqueosData(data) {
      let transformedData = data.map(({
        codcuenta,
        nombre_cuenta,
        detalles
      }) => {
        let detallesByMonth = this.getInitializedObjectsByMonth({
          saldo: 0
        });
        detalles.forEach(detalle => {
          let saldo = parseFloat(detalle.saldo);
          let mesIndex = parseInt(detalle.mes);

          if (isNaN(mesIndex) || mesIndex < 0 || mesIndex > 11) {
            return;
          }

          detallesByMonth[mesIndex] = {
            mesIndex,
            saldo: isNaN(saldo) ? 0 : saldo
          };
        });
        let detallesTrans = Object.values(detallesByMonth);
        let totalSaldo = detallesTrans.reduce((sum, det) => sum + det.saldo, 0);
        return {
          codcuenta,
          nombreCuenta: nombre_cuenta,
          totalSaldo,
          detalles: detallesTrans
        };
      });
      return transformedData;
    },

    computeGrandTotalsByMonth(data, properties = [], decimals = 2) {
      let grandTotalProperties = properties.reduce((result, prop) => {
        result[`total${Ember.String.capitalize(prop)}`] = 0;
        return result;
      }, {});
      let grandTotals = this.getInitializedObjectsByMonth(grandTotalProperties);
      data.forEach(resumen => {
        resumen.detalles.forEach(detalle => {
          properties.forEach(prop => {
            grandTotals[detalle.mesIndex][`total${Ember.String.capitalize(prop)}`] = (0, _format.round)(grandTotals[detalle.mesIndex][`total${Ember.String.capitalize(prop)}`] + detalle[prop], decimals) || 0;
          });
        });
      });
      return grandTotals;
    }

  });

  _exports.default = _default;
});