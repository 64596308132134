define("sirvo-web/models/kar-inventario-fisico-articulo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    inventarioFisico: (0, _model.belongsTo)('kar-inventario-fisico'),
    articulo: (0, _model.belongsTo)('kar-articulo'),
    bodega: (0, _model.belongsTo)('kar-bodega'),
    articuloUbicacion: (0, _model.belongsTo)('kar-articulo-ubicacion'),
    conteo: (0, _model.attr)('number'),
    valorPromedio: (0, _model.attr)('number'),
    existenciaTeorica: (0, _model.attr)('number'),
    existenciaFisica: (0, _model.attr)('number'),
    observaciones: (0, _model.attr)('string'),
    esConteoActual: (0, _model.attr)('boolean'),
    ubicacionAndTipo: Ember.computed('articuloUbicacion', function () {
      return this.get('articuloUbicacion').then(articuloUbicacion => {
        if (!articuloUbicacion || !articuloUbicacion.get('id')) {
          return Ember.RSVP.resolve(null);
        }

        return articuloUbicacion.get('tipoUbicacion');
      }).then(tipoUbicacion => {
        if (!tipoUbicacion) {
          return null;
        }

        return `${tipoUbicacion.get('nombre')} - ${this.get('articuloUbicacion.codigo')}`;
      });
    })
  });

  _exports.default = _default;
});