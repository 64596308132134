define("sirvo-web/routes/inventario/procesos/inventario-fisico/index/reporte", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params
    /*, transition*/
    ) {
      return Ember.RSVP.hash({
        inventarioFisico: this.store.findRecord('kar-inventario-fisico', params.id)
      });
    },

    setupController()
    /*controller, model*/
    {
      this._super(...arguments);

      this.controller.set('parameters', new Ember.Object({
        articulosFilter: 'TODOS_LOS_ARTICULOS',
        imprimirValores: true
      }));
    }

  });

  _exports.default = _default;
});