define("sirvo-web/validations/kar-movdet", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/validators/presence-if-field-is-equal-to"], function (_exports, _validators, _presenceIfFieldIsEqualTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applyValidationsOnChangesetSalida = _exports.applyValidationsOnChangesetIngreso = _exports.trasladoValidations = _exports.ajusteValidations = _exports.defaultValidations = _exports.default = void 0;
  const defaultValidations = {
    empresa: (0, _validators.validatePresence)(true),
    movimiento: (0, _validators.validatePresence)(true),
    articulo: (0, _validators.validatePresence)(true),
    bodega: (0, _validators.validatePresence)(true),
    unidadMedida: (0, _validators.validatePresence)(true),
    cantidad: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gt: 0
    })],
    precioUnitario: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gt: 0
    })],
    total: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gt: 0
    })]
  };
  _exports.defaultValidations = defaultValidations;
  const ajusteValidations = { ...defaultValidations,
    cantidad: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gte: 0
    })],
    precioUnitario: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gte: 0
    })],
    total: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gte: 0
    })]
  };
  _exports.ajusteValidations = ajusteValidations;
  const trasladoValidations = { ...defaultValidations,
    bodegaDestino: (0, _validators.validatePresence)(true)
  };
  _exports.trasladoValidations = trasladoValidations;

  const applyValidationsOnChangesetIngreso = (changeset, validations) => {
    validations['lote'] = (0, _presenceIfFieldIsEqualTo.default)({
      presence: true,
      changeset,
      dependsOn: 'articulo.usaLote',
      comparisonValue: 1
    });
    validations['fechaVencimiento'] = (0, _presenceIfFieldIsEqualTo.default)({
      presence: true,
      changeset,
      dependsOn: 'articulo.usaFechaVencimiento',
      comparisonValue: 1
    });
    return validations;
  };

  _exports.applyValidationsOnChangesetIngreso = applyValidationsOnChangesetIngreso;

  const applyValidationsOnChangesetSalida = (changeset, validations, karParameters) => {
    validations['lote'] = (0, _presenceIfFieldIsEqualTo.default)({
      presence: true,
      changeset,
      dependsOn: 'articulo.usaLote',
      comparisonValue: 1
    });

    if (karParameters && karParameters.get('activarCasillaCuentaContableCadaKarMovdet') && changeset.get('movimiento.deha') === 'H') {
      validations['cuenta'] = (0, _validators.validatePresence)(true);
    }

    return validations;
  };

  _exports.applyValidationsOnChangesetSalida = applyValidationsOnChangesetSalida;
  var _default = defaultValidations;
  _exports.default = _default;
});