define("sirvo-web/components/table-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Table head
   *
   * Este componente se utiliza en la cabeceras de ember-table.
   *
   * Agrega un icono correspondiente a el estado del ordenamiento
   * de la columna: sin orden, ascendente y descendente. Utilizando los propiedad de @columnMeta `isSorted` y `isSortedAsc`.
   *
   * Agrega un icono sin la columna es fijada utilizando la propiedad de @columnMeta `isFixed`.
   *
   * @TODO En versiones superiores de ember este es un only template component.
   */
  var _default = Ember.Component.extend({});

  _exports.default = _default;
});