define("sirvo-web/controllers/inventario/tablas/articulos-ubicaciones/registros", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-articulo-ubicacion"], function (_exports, _abstractModuleIndex, _karArticuloUbicacion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    editRoute: 'inventario.tablas.articulos-ubicaciones.registros.record',
    modelClass: _karArticuloUbicacion.default,
    queryParams: ['ubicacionSuperior'],
    reload: true,
    pageNumber: 1,
    modelFilters: Ember.computed('model.tipoUbicacion', 'ubicacionSuperior', function () {
      let filters = [{
        selectedFilter: {
          filterName: 'tipoUbicacion'
        },
        value: this.model.tipoUbicacion.get('id')
      }];

      if (this.ubicacionSuperior) {
        filters.push({
          selectedFilter: {
            filterName: 'ubicacionSuperior'
          },
          value: this.ubicacionSuperior
        });
      }

      return filters;
    }),
    columns: Ember.computed('model.tipoUbicacion', function () {
      let columns = Ember.A([{
        label: 'Código',
        valuePath: 'codigo',
        filtrable: true,
        filterName: 'codigo',
        width: '100px'
      }, {
        label: 'Nombre',
        valuePath: 'nombre',
        width: '150px'
      }]);

      if (this.model.tipoUbicacion.get('esPrincipal')) {
        columns.pushObject({
          label: 'Bodega',
          valuePath: 'bodega.codbodega',
          filtrable: true,
          filterName: 'bodega.codbodega',
          width: '150px'
        });
      } else {
        columns.pushObject({
          label: 'Ubicación superior',
          valuePath: 'ubicacionSuperior.nombre',
          filtrable: true,
          filterName: 'ubicacionSuperior.nombre',
          width: '150px'
        });
      }

      columns.pushObject({
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editRecord.bind(this)
          },
          viewChildren: {
            title: 'Detalles',
            buttonClass: 'btn-warning',
            icon: 'list',
            action: this.actions.viewChildren.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      });
      return columns;
    }),

    reloadTable() {
      this.set('reload', true);
    },

    actions: {
      addRecord() {
        this.transitionToRoute(this.editRoute, this.model.tipoUbicacion.id, 'new');
      },

      editRecord(ubicacion) {
        this.transitionToRoute(this.editRoute, this.model.tipoUbicacion.id, ubicacion.id);
      },

      /**
       * Permite recargar la ruta actual mostrando solo los registros hijos de una
       * ubicación (Ej. Las casillas de un estante). Esto depende de que el tipo
       * de ubicación actual tenga una clasificación hija.
       */
      viewChildren(ubicacion) {
        if (!ubicacion.id) {
          return;
        }

        this.store.query('kar-articulo-tipo-ubicacion', {
          filter: {
            tipoUbicacionSuperior: this.model.tipoUbicacion.id
          }
        }).then(tipoUbicacionHija => {
          if (!tipoUbicacionHija.length) {
            this.toast.error(String.format('El tipo de ubicación {0} no tiene sub clasificaciones.', this.model.tipoUbicacion.get('nombre')));
            return;
          }

          if (tipoUbicacionHija.length !== 1) {
            this.toast.error(String.format('El tipo de ubicación {0} tiene más de una sub clasificación.', this.model.tipoUbicacion.get('nombre')));
            return;
          }

          this.transitionToRoute(this.routeName, tipoUbicacionHija.get('firstObject').id, {
            queryParams: {
              ubicacionSuperior: ubicacion.id
            }
          });
        });
      }

    }
  });

  _exports.default = _default;
});