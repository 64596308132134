define("sirvo-web/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    loader: Ember.inject.service(),
    breadcrumbs: null,
    isLoading: Ember.computed.readOnly('loader.loading'),
    isAuthenticated: Ember.computed('session.isAuthenticated', function () {
      return this.session.get('isAuthenticated');
    }),

    init() {
      this._super(...arguments); // String formatter


      if (!String.format) {
        String.format = function (format) {
          var args = Array.prototype.slice.call(arguments, 1);
          return format.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ? args[number] : match;
          });
        };
      }

      if (!String.join) {
        String.join = function (pieces, glue = ' ') {
          return Array.isArray(pieces) ? pieces.join(glue) : pieces;
        };
      }
    },

    actions: {
      refreshBreadCrumbs(newBreadcrumbs) {
        this.set('breadcrumbs', newBreadcrumbs);
      }

    }
  });

  _exports.default = _default;
});