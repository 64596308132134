define("sirvo-web/routes/facturacion/movimientos/emision-recibo/record/documento", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let modelData = this._super(...arguments);

      let changeset = this.controllerFor(this.parentRoute).get('changeset');

      if (!changeset) {
        return this.transitionTo(this.parentRoute);
      }

      return Ember.RSVP.hash({
        documentos: this.store.query('emp-docto', {
          filter: {
            empresa: this.company.get('id'),
            codsistema: 'CHQ',
            codmoneda: changeset.get('moneda.codmoneda'),
            tipoctachq: changeset.get('banco.codtipocta'),
            fechaBaja: 'NULL',
            'tipoDocumento.deha': 'D',
            page_enabled: false
          }
        })
      }).then(routeData => Ember.merge(modelData, routeData));
    }

  });

  _exports.default = _default;
});