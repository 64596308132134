define("sirvo-web/routes/dashboard/index", ["exports", "sirvo-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    toast: Ember.inject.service(),

    beforeModel() {
      if (!_environment.default.APP.MODULES.DASHBOARD) {
        this.toast.error('No tiene permisos para acceder esta ruta.');
        return this.transitionTo('index');
      }
    }

  });

  _exports.default = _default;
});