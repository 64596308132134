define("sirvo-web/routes/inventario/movimientos/ingresos/record/detalles/record", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    kardex: Ember.inject.service(),

    beforeModel(transition) {
      let params = transition.params[this.routeName]; // For new records, the whole object must be passed (not the ID).

      if (params.kar_movdet_id === 'new') {
        return this.transitionTo(this.parentRoute);
      }
    },

    model() {
      return this._super(...arguments).then(model => {
        return Ember.RSVP.all([model.get('articulo'), model.get('bodega'), model.get('unidadMedida'), this.kardex.queryMovDetUbicaciones(model)]).then(() => model);
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let parenController = this.controllerFor(this.parentRoute);
      controller.set('ubicacionesChangesets', this.kardex.createMovDetUbicacionesChangesets(parenController.tiposUbicaciones, model));
    }

  });

  _exports.default = _default;
});