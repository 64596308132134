define("sirvo-web/routes/dashboard/resultados", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    request: Ember.inject.service(),
    loader: Ember.inject.service(),
    empresaActual: (0, _emberLocalStorage.storageFor)('empresa'),

    model() {
      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        empresas: this.request.get('accesos/directorio/empresas', {
          directorio: 'CONTA',
          codsys: 'CNT',
          codigo: '2010'
        })
      }).then(({
        empresas
      }) => ({
        empresas: empresas.data.map(empresaData => new Ember.Object(empresaData))
      })).finally(() => this.loader.setLoading(false));
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        currentYear: new Date().getFullYear(),
        selectedEmpresa: null,
        resultados: [],
        resultadosData: [],
        selectedRow: null
      });
      let empresaActualId = this.empresaActual.get('content.id');

      if (empresaActualId) {
        let empresaObject = model.empresas.findBy('id', String(empresaActualId));

        if (empresaObject) {
          controller.set('selectedEmpresa', empresaObject);
          controller.empresaUpdated();
        }
      }
    }

  });

  _exports.default = _default;
});