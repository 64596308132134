define("sirvo-web/templates/inventario/tablas/articulos-ubicaciones/registros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ytvDHH8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 py-4\"],[8],[0,\"\\n      \"],[6,\"h3\"],[8],[0,\"\\n        \"],[4,\"link-to\",[\"inventario.tablas.articulos-ubicaciones\"],[[\"class\"],[\"btn\"]],{\"statements\":[[0,\"\\n          \"],[1,[26,\"fa-icon\",null,[[\"icon\"],[\"arrow-left\"]]],false],[0,\"\\n        \"]],\"parameters\":[]},null],[0,\"\\n\\n        \"],[6,\"span\"],[10,\"class\",\"title\"],[8],[0,\"\\n          Artículos ubicaciones\\n        \"],[9],[0,\"\\n\\n        \"],[6,\"div\"],[10,\"class\",\"pull-right\"],[8],[0,\"\\n          \"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"success\",[26,\"action\",[[21,0,[]],\"addRecord\"],null]]],{\"statements\":[[0,\"\\n            Agregar ubicación\\n          \"]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12\"],[8],[0,\"\\n      \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"load\",\"page\",\"tableClassNames\"],[[21,0,[\"columns\"]],\"kar-articulo-ubicacion\",[21,0,[\"modelClass\"]],[21,0,[\"modelFilters\"]],[21,0,[\"reload\"]],[21,0,[\"pageNumber\"]],\"ember-table thead-gray\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/articulos-ubicaciones/registros.hbs"
    }
  });

  _exports.default = _default;
});