define("sirvo-web/controllers/inventario/movimientos/salidas/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.salidas'),
    parameters: Ember.computed.readOnly('currentCompany.parameters'),
    fechaCierre: Ember.computed.readOnly('currentCompany.lastCierre.fecha'),
    indexRoute: null,
    usaCorrelativoAutomatico: Ember.computed('changeset.tipoDocumento', function () {
      if (!this.changeset.get('tipoDocumento')) {
        return false;
      }

      return this.changeset.get('tipoDocumento').get('correlativoAutomatico');
    }),
    registroCerrado: Ember.computed('changeset.fecha', 'fechaCierre', function () {
      if (!this.fechaCierre || !this.changeset.get('fecha')) {
        return false;
      }

      return this.fechaCierre >= this.changeset.get('fecha');
    }),
    tipoDocumentoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editTipoDocumento.bind(this)
      }];
    }),
    proveedorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editProveedor.bind(this)
      }];
    }),
    autorizadoPorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editAutorizacion.bind(this)
      }];
    }),
    propertyNameActionMap: Ember.computed(function () {
      return [{
        propertyName: 'tipoDocumento.codtipo',
        action: this.actions.editTipoDocumento.bind(this)
      }, {
        propertyName: 'proveedor.coddirectorio',
        action: this.actions.editProveedor.bind(this)
      }, {
        propertyNames: ['autorizadoPor.coddirectorio', 'recibidoPor.coddirectorio'],
        action: this.actions.editAutorizacion.bind(this)
      }];
    }),

    postSave(record) {
      if (this.indexCtrl) {
        this.indexCtrl.set('reload', true);
      }

      if (this.isNew) {
        return this.transitionToRoute(`${this.indexRoute}.registro-detalle`, record.id, 'new');
      }
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      editTipoDocumento() {
        this.transitionToRoute(`${this.routeName}.tipo-doc`);
      },

      editProveedor() {
        this.transitionToRoute(`${this.routeName}.proveedor`);
      },

      editAutorizacion() {
        this.transitionToRoute(`${this.routeName}.autorizacion`);
      },

      goToDetails() {
        this.transitionToRoute(`${this.indexRoute}.detalles`, this.record.id);
      }

    }
  });

  _exports.default = _default;
});