define("sirvo-web/templates/admon/grupos/directorios/record/es-cliente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K9Qs571H",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-4\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"dias_cr_cxc\",\"Días de crédito\"],[[\"placeholder\"],[\"Días de crédito\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-4\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"tipo_iva_directorio\",\"Tipo de IVA\"],[[\"placeholder\"],[\"Tipo de IVA\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-4\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"cxc_envio_correos_masivos\",\"Envia correos masivos\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"tiene_credito\",\"¿Tiene crédito?\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-checkbox-field\"]],\"enletras_en_ingles\",\"Mostrar valor en letras en inglés - FACTURACIÓN\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/admon/grupos/directorios/record/es-cliente.hbs"
    }
  });

  _exports.default = _default;
});