define("sirvo-web/controllers/inventario/tablas/articulos/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.tablas.articulos'),
    indexRoute: null,

    postSave()
    /*record*/
    {
      this.indexCtrl.reloadTable();

      if (this.isNew) {
        // WARNING: This is a bit weird because the route is reloaded with the new ID
        // while this popup is open. However the screen is blocked while the route is
        // loading, so let's move on.
        this.send('openModal', 'popups/simple-confirm', {
          title: 'Asignar ubicaciones',
          message: '¿Quisiera asignar las ubicaciones del nuevo artículo?',
          onSubmit: () => {
            this.transitionToRoute(`${this.routeName}.datos-generales.ubicaciones`);
          }
        });
      }
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      editarUbicaciones() {
        if (this.record.get('isNew')) {
          this.toast.error('Guarde el artículo para poder asignar ubicaciones.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.datos-generales.ubicaciones`);
      }

    }
  });

  _exports.default = _default;
});