define("sirvo-web/models/kar-unidad-medida", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    codigo: (0, _model.attr)('string'),
    simbolo: (0, _model.attr)('string'),
    nombre: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    unidadMedidaBase: (0, _model.belongsTo)('kar-unidad-medida', {
      inverse: null
    }),
    factor: (0, _model.attr)('number'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    codigoNombre: Ember.computed('codigo', 'nombre', function () {
      return [this.codigo, this.nombre].filter(Boolean).join(' - ');
    })
  });

  _exports.default = _default;
});