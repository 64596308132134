define("sirvo-web/models/emp-ci", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    periodo: (0, _model.belongsTo)('emp-periodo'),
    empresa: (0, _model.belongsTo)('empresa'),
    codci: (0, _model.attr)('string'),
    codciAcumula: (0, _model.attr)('string'),
    codbd: (0, _model.attr)('string'),
    codempresaConsolida: (0, _model.attr)('string'),
    descripcion: (0, _model.attr)('string'),
    obs: (0, _model.attr)('string'),
    ultimoNivel: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    centroNivel: (0, _model.attr)('boolean', {
      defaultValue: 1
    }),
    centroMayor: (0, _model.attr)('string'),
    cuentaacumulacion: (0, _model.attr)('string'),
    esdefault: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    codigoDescripcion: Ember.computed('codci', 'descripcion', function () {
      return `${this.codci} - ${this.descripcion}`;
    })
  });

  _exports.default = _default;
});