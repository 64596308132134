define("sirvo-web/initializers/constants", ["exports", "sirvo-web/models/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('service:constants', Ember.Object.extend(_constants.default));
    application.inject('route', 'constants', 'service:constants');
    application.inject('controller', 'constants', 'service:constants');
    application.inject('model', 'constants', 'service:constants');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});